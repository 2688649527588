import * as React from 'react';
import { SVGProps } from 'react';

const SvgCardIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22 10v10a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V10h20Zm0-2H2V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v4Zm-7 8v2h4v-2h-4Z"
        fill="#fff"
      />
    </svg>
  );
};
export default SvgCardIcon;
