import * as React from 'react';
import { SVGProps } from 'react';

const SvgPercent = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m19.28 5.779-13.5 13.5a.75.75 0 1 1-1.06-1.061l13.5-13.5a.75.75 0 0 1 1.06 1.06ZM4.739 9.51a3.375 3.375 0 1 1 4.774-4.773A3.375 3.375 0 0 1 4.738 9.51Zm.512-2.385A1.875 1.875 0 1 0 9 7.122a1.875 1.875 0 0 0-3.75.003Zm15 9.75a3.376 3.376 0 1 1-6.751-.002 3.376 3.376 0 0 1 6.751.002Zm-1.5 0a1.875 1.875 0 1 0-3.75 0 1.875 1.875 0 0 0 3.75 0Z"
        fill="#9CA3AF"
      />
    </svg>
  );
};
export default SvgPercent;
