import * as React from 'react';
import { SVGProps } from 'react';

const SvgCrystals = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={95}
      height={77}
      viewBox="0 0 95 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m87.38 7.034-.06-.9A3.653 3.653 0 0 0 84.675 5H65.326c-1 0-1.956.41-2.645 1.133v.901l4.998 8.224h15.44l4.26-8.224Z"
        fill="#FFD04E"
      />
      <path
        d="M65.326 5c-1 0-1.956.41-2.645 1.133v.901l2.512 4.134L69.522 5h-4.196ZM75 5l-6.953 10.258.433.423h12.772l.702-.423L75 5Z"
        fill="#FFD04E"
      />
      <path
        d="m62.68 6.134-6.946 7.29A2.66 2.66 0 0 0 55 15.26l.46.422h11.862l.724-.422-5.366-9.125Z"
        fill="#FFD04E"
      />
      <path
        d="m62.323 15.258 3.114-4.438-2.757-4.687-6.946 7.291A2.659 2.659 0 0 0 55 15.258l.46.422h7.082l-.22-.422Zm32.677 0c0-.683-.263-1.34-.734-1.834l-6.947-7.29-5.366 9.124.534.422h12.162l.35-.422Z"
        fill="#FFD04E"
      />
      <path
        d="M55 15.258c0 .767.273 1.508.771 2.091L72.15 36.54a3.747 3.747 0 0 0 5.702 0L94.23 17.35a3.22 3.22 0 0 0 .77-2.09H55Z"
        fill="#FFD04E"
      />
      <path
        d="M62.323 15.258H55c0 .767.273 1.508.771 2.091L72.15 36.54c.484.566 1.12.969 1.822 1.17L62.324 15.26ZM75 37.853c.352 0 .698-.05 1.03-.145l5.924-22.45H68.047l5.924 22.45a3.73 3.73 0 0 0 1.03.145ZM53.412 24.509l-.525-1.543a6.597 6.597 0 0 0-5.143-.741L14 31.267a6.596 6.596 0 0 0-4.084 3.213l.42 1.57 12.561 12.008 26.929-7.216 3.586-16.333Z"
        fill="#FFD04E"
      />
      <path
        d="M14 31.267a6.596 6.596 0 0 0-4.084 3.213l.42 1.57 6.315 6.037 4.666-12.78L14 31.266Zm16.872-4.521-7.333 21.14.953.534 22.275-5.969 1.025-1.064-16.92-14.64Z"
        fill="#FFD04E"
      />
      <path
        d="M9.916 34.48 1.208 50.44a4.8 4.8 0 0 0-.422 3.542l.999.521 20.687-5.543 1.067-1.075L9.916 34.48Z"
        fill="#FFD04E"
      />
      <path
        d="m13.556 50.56 3.357-9.194-6.997-6.886-8.708 15.96a4.8 4.8 0 0 0-.422 3.542l.999.522 12.35-3.31-.579-.634Zm56.989-15.27a4.8 4.8 0 0 0-2.137-2.855l-15.521-9.47-5.095 18.422 1.128.487 21.21-5.684.414-.9Z"
        fill="#FFD04E"
      />
      <path
        d="M.785 53.982a5.816 5.816 0 0 0 2.322 3.287L40.638 83.08a6.766 6.766 0 0 0 9.943-2.664l19.596-41.12c.596-1.25.726-2.67.368-4.007L.785 53.982Z"
        fill="#FFD04E"
      />
      <path
        d="M13.556 50.56.786 53.983a5.817 5.817 0 0 0 2.32 3.287l37.532 25.812a6.767 6.767 0 0 0 3.723 1.19L13.556 50.56Zm32.668 33.482a6.758 6.758 0 0 0 1.728-.734l-.16-41.921-24.253 6.499L44.362 84.27a6.775 6.775 0 0 0 1.862-.228Z"
        fill="#FFD04E"
      />
      <path
        d="m85.45 8.303-.05-.773a3.14 3.14 0 0 0-2.274-.974h-16.63a3.14 3.14 0 0 0-2.273.974v.774l4.296 7.069h13.27l3.662-7.069Z"
        fill="#C3DDFF"
      />
      <path
        d="M66.495 6.555a3.14 3.14 0 0 0-2.273.974v.774l2.16 3.553 3.72-5.301h-3.607Z"
        fill="#A4CCFF"
      />
      <path
        d="m74.811 6.555-5.976 8.817.372.363h10.978l.602-.363-5.976-8.817Zm-10.589.975-5.97 6.266c-.405.425-.63.989-.63 1.576l.394.363h10.195l.623-.363-4.612-7.843Z"
        fill="#DBEAFF"
      />
      <path
        d="m63.915 15.372 2.676-3.815-2.369-4.028-5.97 6.266c-.405.425-.63.99-.63 1.577l.394.363h6.087l-.188-.363Z"
        fill="#C3DDFF"
      />
      <path
        d="M92 15.372c0-.587-.225-1.152-.63-1.577L85.4 7.53l-4.613 7.843.459.363h10.453l.301-.363Z"
        fill="#DBEAFF"
      />
      <path
        d="M57.621 15.372c0 .659.235 1.296.663 1.797L72.36 33.662a3.221 3.221 0 0 0 4.9 0l14.077-16.493A2.768 2.768 0 0 0 92 15.372H57.62Z"
        fill="#A4CCFF"
      />
      <path
        d="M63.915 15.372H57.62c0 .659.235 1.296.663 1.797L72.36 33.662a3.22 3.22 0 0 0 1.565 1.005l-10.01-19.295Z"
        fill="#8BB3EA"
      />
      <path
        d="M74.81 34.792c.303 0 .6-.043.885-.125l5.092-19.295H68.834l5.092 19.295c.285.082.582.125.884.125ZM51.704 27.44l-.474-1.395a5.962 5.962 0 0 0-4.648-.67l-30.493 8.171a5.96 5.96 0 0 0-3.69 2.904l.38 1.42 11.35 10.85 24.334-6.52 3.24-14.76Z"
        fill="#C3DDFF"
      />
      <path
        d="M16.089 33.546a5.96 5.96 0 0 0-3.69 2.904l.38 1.42 5.705 5.454 4.217-11.55-6.612 1.772Z"
        fill="#A4CCFF"
      />
      <path
        d="M31.335 29.46 24.71 48.565l.861.482L45.7 43.653l.927-.962-15.29-13.23Zm-18.937 6.99L4.53 50.873a4.338 4.338 0 0 0-.382 3.2l.903.471 18.694-5.009.964-.971-12.31-12.114Z"
        fill="#DBEAFF"
      />
      <path
        d="m15.688 50.981 3.034-8.309-6.324-6.222L4.53 50.873a4.338 4.338 0 0 0-.382 3.2l.903.472 11.16-2.99-.523-.574Z"
        fill="#C3DDFF"
      />
      <path
        d="M67.187 37.182c-.288-1.076-.98-2-1.931-2.58L51.23 26.044 46.626 42.69l1.019.44 19.168-5.135.374-.814Z"
        fill="#DBEAFF"
      />
      <path
        d="M4.147 54.073a5.256 5.256 0 0 0 2.099 2.97L40.16 80.368a6.114 6.114 0 0 0 8.984-2.407l17.709-37.158a5.254 5.254 0 0 0 .332-3.621L4.147 54.073Z"
        fill="#A4CCFF"
      />
      <path
        d="m15.688 50.981-11.54 3.092a5.256 5.256 0 0 0 2.098 2.97L40.16 80.368a6.114 6.114 0 0 0 3.364 1.075L15.688 50.981Z"
        fill="#8BB3EA"
      />
      <path
        d="M45.209 81.237a6.107 6.107 0 0 0 1.56-.663l-.143-37.883-21.917 5.873 18.817 32.88a6.122 6.122 0 0 0 1.683-.207Z"
        fill="#C3DDFF"
      />
    </svg>
  );
};
export default SvgCrystals;
