import * as React from 'react';
import { SVGProps } from 'react';

const SvgCoinsIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={64}
      height={65}
      viewBox="0 0 64 65"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path d="M46 22.892V21.5c0-6.27-9.458-11-22-11-12.543 0-22 4.73-22 11v10c0 5.222 6.562 9.373 16 10.615V43.5c0 6.27 9.457 11 22 11 12.542 0 22-4.73 22-11v-10c0-5.175-6.355-9.33-16-10.608ZM14 37.218c-4.898-1.368-8-3.62-8-5.718v-3.517c2.04 1.444 4.772 2.61 8 3.392v5.843Zm20-5.843c3.227-.782 5.96-1.948 8-3.392V31.5c0 2.097-3.103 4.35-8 5.718v-5.843Zm-4 17.843c-4.898-1.368-8-3.62-8-5.718v-1.042a52.48 52.48 0 0 0 2 .042c.97 0 1.917-.032 2.847-.087 1.034.37 2.086.683 3.153.94v5.864Zm0-11.156c-1.987.294-3.992.44-6 .438a40.745 40.745 0 0 1-6-.438v-5.947c1.99.26 3.994.388 6 .385 2.006.003 4.01-.126 6-.385v5.947Zm16 12a41.364 41.364 0 0 1-12 0V44.1c1.989.267 3.993.401 6 .4 2.006.003 4.01-.126 6-.385v5.947ZM58 43.5c0 2.097-3.103 4.35-8 5.718v-5.843c3.227-.782 5.96-1.947 8-3.392V43.5Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path transform="translate(0 .5)" d="M0 0h64v64H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgCoinsIcon;
