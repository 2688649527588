import * as React from 'react';
import { SVGProps } from 'react';

const SvgCheckIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={94}
      height={94}
      viewBox="0 0 94 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={47} cy={47} r={47} fill="#22C55E" />
      <circle cx={47} cy={47} r={42} fill="#4ADE80" />
      <path
        d="m41.022 59.1-10.51-10.364L26 53.186 41.022 68 72 37.45 67.487 33 41.022 59.1Z"
        fill="#2D3344"
      />
    </svg>
  );
};
export default SvgCheckIcon;
