import * as React from 'react';
import { SVGProps } from 'react';

const SvgWarningIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.25 12.75V7.5a.75.75 0 1 1 1.5 0v5.25a.75.75 0 1 1-1.5 0Zm10.5-4.167v6.834a1.487 1.487 0 0 1-.44 1.06l-4.832 4.833a1.487 1.487 0 0 1-1.06.44H8.582a1.487 1.487 0 0 1-1.06-.44L2.69 16.478a1.487 1.487 0 0 1-.44-1.06V8.582a1.487 1.487 0 0 1 .44-1.06L7.523 2.69a1.487 1.487 0 0 1 1.06-.44h6.834a1.487 1.487 0 0 1 1.06.44l4.833 4.833a1.487 1.487 0 0 1 .44 1.06Zm-1.5 0L15.417 3.75H8.583L3.75 8.583v6.834l4.833 4.833h6.834l4.833-4.833V8.583ZM12 15a1.125 1.125 0 1 0 0 2.25A1.125 1.125 0 0 0 12 15Z"
        fill="#EA580C"
      />
    </svg>
  );
};
export default SvgWarningIcon;
