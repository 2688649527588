import * as React from 'react';
import { SVGProps } from 'react';

const SvgNetworkTrcIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={47}
      height={47}
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={23.5} cy={23.5} r={23.5} fill="#fff" />
      <path
        d="M23.5 0C36.48 0 47 10.52 47 23.5S36.48 47 23.5 47 0 36.48 0 23.5 10.52 0 23.5 0ZM11.016 10.659l11.155 28.07 15.544-18.938-5.502-5.231-21.197-3.901Zm23.87 10.247L23.62 34.63l1.444-11.945 9.822-1.777v-.002Zm-20.974-6.97 9.742 8.08-1.524 12.602-8.218-20.684v.002Zm17.96 2.342 3.243 3.083-8.868 1.605 5.625-4.688Zm-16.9-3.385 15.278 2.81-5.93 4.942-9.348-7.752Z"
        fill="#FF0113"
      />
    </svg>
  );
};
export default SvgNetworkTrcIcon;
