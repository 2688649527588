import styled from 'styled-components/macro';

interface ScNotificationPopupProps {
  type?: string;
}

export const ScNotificationPopup = styled.div<ScNotificationPopupProps>`
  position: fixed;
  z-index: 4;
  bottom: 24px;
  left: 24px;
  width: 100%;
  min-height: 220px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  font-size: 14px;
  ${({ theme, type }) =>
    type === 'isSystem'
      ? ` max-width: 472px;
        background: ${theme.colors.background.foreground};
        box-shadow: 0 4px 48px rgba(0, 0, 0, 0.4);
        color: ${theme.colors.text.primary};
       `
      : `
         max-width: 522px;
        background: ${theme.colors.background.foreground};
        color: ${theme.colors.text.primary};
      `};
  @media (max-width: ${({ theme }) => theme.mobileSize}) {
    left: 50%;
    transform: translateX(-50%);
    width: 95%;
    bottom: 20px;
    border: 1px solid ${({ theme }) => theme.colors.border.system};
  }
  .popup_content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    padding: ${({ type }) =>
      type === 'isSystem' ? '29px 24px 24px 171px' : '29px 24px 24px 201px'};
    @media (max-width: ${({ theme }) => theme.mobileSize}) {
      padding: 20px;
    }
    h3 {
      color: ${({ theme }) => theme.colors.text.primary};
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      margin: 0 0 18px 0;
    }
    p {
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.text.primary};
      margin: 0 0 20px 0;
      a {
        text-decoration: underline;
      }
    }
    .powered-by {
      color: rgba(255, 255, 255, 0.4);
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      margin: -12px 0 8px 0;
      svg {
        vertical-align: middle;
        margin-left: 5px;
        width: 74px;
        path {
          fill: rgba(255, 255, 255, 0.4);
        }
      }
    }
    .popup_ctrl {
      display: flex;
      align-items: center;
      ${({ theme, type }) =>
        type === 'isSystem'
          ? `
         & > button {
           width: 100%;
           background: ${theme.colors.layer.layer01};
           border: 1px solid ${theme.colors.border.system};
           & > span {
             color: ${theme.colors.text.primary};
           }}`
          : `& > button {
           background: ${theme.colors.layer.layer01};
             & > span {
               color: ${theme.colors.text.primary};
             }
           }`}
      .more {
        padding: 14px 28px;
        margin-left: 6px;
      }
    }
  }
  .popup_image {
    position: absolute;
    bottom: 0;
    top: ${({ type }) => (type === 'promoBonuses' ? '0' : '-14px')};
    left: ${({ type }) => (type === 'promoGiveaway' ? '-5px' : '0')};
    display: flex;
    ${({ type }) =>
      type === 'isSystem'
        ? `
        width: 147px;
        justify-content: center;
        align-items: center;
        padding: 15px;
        top: 0;
        left: 0;
        border-radius: 8px 0 0 8px;`
        : `align-items: flex-end;`};
    & > svg {
      margin: ${({ type }) => (type === 'promoBonuses' ? 'auto' : '0')};
    }
    @media (max-width: ${({ theme }) => theme.mobileSize}) {
      display: none;
    }
  }
  .popup_close {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
    path {
      stroke: ${({ theme }) => theme.colors.icons.primary};
    }
  }
  .crystal-icon {
    vertical-align: middle;
    width: 20px;
    height: 20px;
    path {
      fill: ${({ theme }) => theme.basicWhite};
    }
  }
`;
