import * as React from 'react';
import { SVGProps } from 'react';

const SvgRobotIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 3c.482 0 .945.19 1.286.527.34.338.532.796.532 1.273 0 .666-.364 1.251-.909 1.557V7.5h.91c1.687 0 3.306.664 4.499 1.845a6.268 6.268 0 0 1 1.864 4.455h.909c.241 0 .472.095.643.264.17.168.266.397.266.636v2.7a.895.895 0 0 1-.266.636.914.914 0 0 1-.643.264h-.91v.9c0 .477-.19.935-.532 1.273a1.828 1.828 0 0 1-1.285.527H5.636c-.482 0-.944-.19-1.285-.527a1.791 1.791 0 0 1-.533-1.273v-.9H2.91a.914.914 0 0 1-.643-.264A.895.895 0 0 1 2 17.4v-2.7c0-.239.096-.468.266-.636a.914.914 0 0 1 .643-.264h.91c0-1.67.67-3.273 1.863-4.455a6.396 6.396 0 0 1 4.5-1.845h.909V6.357a1.783 1.783 0 0 1-.91-1.557c0-.477.192-.935.533-1.273C11.055 3.19 11.518 3 12 3Zm-4.09 9.9c-.604 0-1.182.237-1.608.659a2.239 2.239 0 0 0-.666 1.591c0 .597.24 1.169.666 1.591a2.285 2.285 0 0 0 1.607.659c.603 0 1.18-.237 1.607-.659.426-.422.666-.994.666-1.591s-.24-1.169-.666-1.591A2.285 2.285 0 0 0 7.91 12.9Zm8.18 0c-.602 0-1.18.237-1.606.659a2.239 2.239 0 0 0-.666 1.591c0 .597.24 1.169.666 1.591a2.285 2.285 0 0 0 3.214 0c.426-.422.666-.994.666-1.591s-.24-1.169-.666-1.591a2.285 2.285 0 0 0-1.607-.659Z"
        fill="#8B44E5"
      />
    </svg>
  );
};
export default SvgRobotIcon;
