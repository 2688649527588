import * as React from 'react';
import { SVGProps } from 'react';

const SvgCrystalIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={83}
      height={20}
      viewBox="0 0 83 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#a)"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#64748B"
      >
        <path d="M15.19 1.981H3.724L.3 6.568l9.157 9.228 9.157-9.228-3.424-4.587ZM23.635 10.248V7.026c0-3.768 1.712-5.542 5.244-5.542 3.396 0 5.054 1.638 5.162 5.023h-2.39c-.082-1.965-.897-2.839-2.745-2.839-1.983 0-2.799 1.01-2.799 3.413v3.14c0 2.402.816 3.412 2.8 3.412 1.847 0 2.662-.874 2.743-2.812h2.392c-.11 3.386-1.767 4.996-5.163 4.996-3.532-.027-5.244-1.829-5.244-5.57ZM35.917 5.006h2.065l.108 1.938c.462-1.474 1.359-2.13 2.826-2.13h.761v2.322h-.543c-1.63 0-2.446.682-2.772 2.375v6.06h-2.418V5.007h-.027ZM42.954 17.264h1.032c.68 0 .979-.19 1.223-.928l.353-1.065-3.668-10.265h2.582l2.336 7.18 2.174-7.18h2.446l-3.913 11.63c-.68 2.02-1.386 2.621-3.26 2.621h-1.305v-1.993ZM51.758 12.377h2.31c0 1.038.678 1.557 2.091 1.557 1.305 0 1.93-.437 1.93-1.229 0-.874-.625-1.229-2.419-1.474-2.663-.328-3.695-1.174-3.695-3.14 0-2.184 1.494-3.33 4.184-3.33 2.69 0 4.076 1.146 4.076 3.303h-2.337c0-.983-.57-1.447-1.766-1.447-1.141 0-1.766.437-1.766 1.201 0 .846.625 1.174 2.337 1.365 2.717.3 3.75 1.256 3.75 3.276 0 2.212-1.44 3.331-4.32 3.331-2.963 0-4.375-1.147-4.375-3.413ZM62.572 12.16V6.89h-1.658V5.006h1.712l.163-2.703h2.228v2.703h2.364V6.89h-2.364v5.215c0 1.146.272 1.474 1.305 1.474h1.168v1.965h-1.93c-2.092 0-2.988-.982-2.988-3.385ZM72.353 9.156c-2.852 0-4.239 1.201-4.239 3.385 0 2.075 1.304 3.25 3.37 3.25 1.494 0 2.581-.574 3.369-1.639l.081 1.392H77V8.72C77 6.016 75.64 4.76 72.76 4.76c-2.772 0-4.184 1.174-4.348 3.495h2.337c.082-1.01.707-1.529 1.929-1.529 1.304 0 1.902.628 1.902 1.966v.464h-2.228Zm2.228 1.747v1.31c-.516 1.065-1.358 1.611-2.472 1.584-1.06 0-1.603-.519-1.603-1.42 0-.955.598-1.474 2.173-1.474h1.902ZM79.31.72h2.417v14.824H79.31V.72Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path
            fill="#fff"
            transform="translate(.3 .72)"
            d="M0 0h81.987v18.92H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgCrystalIcon;
