import * as React from 'react';
import { SVGProps } from 'react';

const SvgLoginIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.74 1.391a3.483 3.483 0 0 1 3.477 3.479v1.39H6.261V4.87A3.483 3.483 0 0 1 9.739 1.39Zm-4.87 4.87H3.478v2.782H4.87v-1.39h9.739v6.26h-1.392v1.391H16V6.261h-1.391V4.87A4.875 4.875 0 0 0 9.739 0a4.875 4.875 0 0 0-4.87 4.87v1.39Z"
        fill="#372D6C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.391 11.826c0-.383.313-.695.696-.695h.696c.383 0 .695.312.695.695v2.087a.696.696 0 0 1-.695.696h-.696a.696.696 0 0 1-.696-.696v-2.087ZM2.087 16h.696a2.09 2.09 0 0 0 2.087-2.087v-.696h4.173v1.392h1.392v-1.392h.695v2.088h1.392v-3.479H4.87A2.09 2.09 0 0 0 2.783 9.74h-.696A2.09 2.09 0 0 0 0 11.826v2.087A2.09 2.09 0 0 0 2.087 16Z"
        fill="#372D6C"
      />
    </svg>
  );
};
export default SvgLoginIcon;
