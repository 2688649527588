import * as React from 'react';
import { SVGProps } from 'react';

const SvgPassportIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.874 15.5a.624.624 0 0 0 .876-.125 4.062 4.062 0 0 1 6.5 0 .623.623 0 0 0 .875.125.623.623 0 0 0 .125-.875 5.302 5.302 0 0 0-2.14-1.694 3.125 3.125 0 1 0-4.215 0c-.85.367-1.59.951-2.145 1.694a.625.625 0 0 0 .124.875ZM10 8.75a1.875 1.875 0 1 1 0 3.75 1.875 1.875 0 0 1 0-3.75Zm5.625-6.875H4.375a1.25 1.25 0 0 0-1.25 1.25v13.75a1.25 1.25 0 0 0 1.25 1.25h11.25a1.25 1.25 0 0 0 1.25-1.25V3.125a1.25 1.25 0 0 0-1.25-1.25Zm0 15H4.375V3.125h11.25v13.75ZM6.875 5a.625.625 0 0 1 .625-.625h5a.625.625 0 1 1 0 1.25h-5A.625.625 0 0 1 6.875 5Z"
        fill="#64748B"
      />
    </svg>
  );
};
export default SvgPassportIcon;
