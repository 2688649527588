import * as React from 'react';
import { SVGProps } from 'react';

const SvgSettingsIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 7.5a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9Zm0 7.5a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm10.307-4.95a.75.75 0 0 0-.365-.505L19.145 7.95l-.01-3.152a.751.751 0 0 0-.266-.57 10.493 10.493 0 0 0-3.443-1.938.75.75 0 0 0-.605.056L12 3.923l-2.824-1.58a.75.75 0 0 0-.607-.056 10.51 10.51 0 0 0-3.438 1.946.75.75 0 0 0-.266.569l-.014 3.155L2.055 9.55a.75.75 0 0 0-.365.506 9.982 9.982 0 0 0 0 3.897.75.75 0 0 0 .365.506l2.796 1.594.011 3.152a.75.75 0 0 0 .266.57 10.491 10.491 0 0 0 3.442 1.938.75.75 0 0 0 .606-.056L12 20.077l2.823 1.58a.742.742 0 0 0 .607.054 10.51 10.51 0 0 0 3.439-1.944.75.75 0 0 0 .265-.569l.014-3.155 2.797-1.593a.751.751 0 0 0 .364-.507 9.983 9.983 0 0 0-.002-3.892ZM20.9 13.325l-2.678 1.523a.75.75 0 0 0-.281.281c-.055.094-.112.194-.17.287a.744.744 0 0 0-.114.395l-.015 3.023a8.986 8.986 0 0 1-2.378 1.341l-2.702-1.506a.75.75 0 0 0-.366-.093H11.837a.757.757 0 0 0-.385.093l-2.704 1.51a9 9 0 0 1-2.385-1.334l-.01-3.019a.75.75 0 0 0-.114-.396 5.374 5.374 0 0 1-.169-.287.758.758 0 0 0-.281-.286l-2.681-1.528a8.484 8.484 0 0 1 0-2.649L5.78 9.153a.75.75 0 0 0 .282-.281c.054-.094.111-.194.17-.287a.744.744 0 0 0 .114-.395l.014-3.023a8.99 8.99 0 0 1 2.378-1.341l2.698 1.506a.75.75 0 0 0 .385.093h.34a.758.758 0 0 0 .385-.093l2.704-1.51c.858.32 1.662.77 2.385 1.334l.01 3.019c0 .14.04.277.115.396.058.093.115.187.168.286.067.12.164.219.282.287l2.68 1.527c.142.879.143 1.774.006 2.653h.003Z"
        fill="#000"
      />
    </svg>
  );
};
export default SvgSettingsIcon;
