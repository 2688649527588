import React from 'react';

import { Link } from 'react-navi';

import { useTheme } from 'hooks/theme';

import { AuthorizationButtons } from 'components/authorizationButtons';
import { MobileMenu } from 'components/mobileMenu';
import { NavMenu } from 'components/navMenu';

import { ScHeader } from './styled';

export const Header = () => {
  const { images } = useTheme();

  return (
    <ScHeader>
      <div className="container">
        <MobileMenu />

        <div className="wrap">
          <div className="logo">
            <Link href="/">
              <images.logo.component />
            </Link>

            <Link href="/" className="mobile">
              <images.logoMobile.component />
            </Link>
          </div>

          <NavMenu />
        </div>

        <AuthorizationButtons />
      </div>
    </ScHeader>
  );
};
