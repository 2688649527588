import React from 'react';

import { useTranslation } from 'react-i18next';

import { getLocaleBestchangeUrl } from 'helpers/i18n';

import { LangToggle } from 'components/LangToggle';
import { Icon } from 'components/UI/Icon';

import { ENV } from 'env/env.config';

import { Languages } from 'lib/i18n';

import * as ScFooter from './styled';

const Footer = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <ScFooter.Container>
      <div className="container">
        <ScFooter.Copyright>
          {t(`common:footer.${ENV.APP_THEME}.copyright`, {
            value: ENV.APP_TITLE,
            defaultValue: t('common:footer.copyright'),
          })}
        </ScFooter.Copyright>

        {getLocaleBestchangeUrl(language as Languages) && (
          <ScFooter.Bestchange
            target="_blank"
            rel="noreferrer noopener"
            href={getLocaleBestchangeUrl(language as Languages)}
          >
            <Icon width={77} name="BestchangeIcon" />
          </ScFooter.Bestchange>
        )}

        <LangToggle />
      </div>
    </ScFooter.Container>
  );
};

export default Footer;
