import copy from 'copy-to-clipboard';

import { capitalize } from 'helpers/base';
import { showTooltip } from 'helpers/tooltip';
import { translationFactory } from 'helpers/trans';

import { TooltipTypes } from 'modules/globalTooltip';

export const copyWithTooltip: typeof copy = (text, options) => {
  const copied = copy(text, options);

  if (copied) {
    showTooltip({
      type: TooltipTypes.SUCCESS,
      body: translationFactory('common:glossary.copied', {
        format: capitalize,
      }),
    });
  }

  return copied;
};
