import styled from 'styled-components/macro';

export const ScAuthorizationButtons = styled.div`
  @media (max-width: ${({ theme }) => theme.tabletSize}) {
    flex-direction: column-reverse;
    height: 100%;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  & > a,
  & > button {
    & > span {
      overflow: hidden;
      text-overflow: ellipsis;
      @media (max-width: ${({ theme }) => theme.mobileSize}) {
        max-width: 200px;
      }
    }
    svg {
      width: auto;
      height: auto;
    }
  }
  .signInLink {
    display: none;
    @media (max-width: ${({ theme }) => theme.tabletSize}) {
      display: block;
    }
  }
  .profileButton {
    @media (max-width: ${({ theme }) => theme.tabletSize}) {
      display: none;
    }
  }
  .loginButton {
    @media (max-width: ${({ theme }) => theme.tabletSize}) {
      display: none;
      width: 100%;
      margin-left: 0;
      order: 1;
    }
  }
`;
