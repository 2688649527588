import React, { Fragment, useCallback, useEffect, useMemo } from 'react';

import cx from 'classnames';
import { useCurrentRoute } from 'react-navi';

import { useTheme } from 'hooks/theme';

import globalVisibilityStore from './store';
import * as ScSiteAssistant from './styled';
import { SiteAssistantState } from './types';
import { matchPathname, siteAssistantPageContent } from './util';

const SiteAssistant = () => {
  const { images } = useTheme();

  const { url } = useCurrentRoute();

  const {
    title,
    content,
    notifyState,
    isAssistantPanelVisible,
    setIsAssistantPanelVisible,
    setAssistantPanelContent,
  } = globalVisibilityStore();

  const toggleComponentVisibility = useCallback(() => {
    setIsAssistantPanelVisible();
  }, [setIsAssistantPanelVisible]);

  const matchedPath = useMemo(
    () => matchPathname(url.pathname),
    [url.pathname]
  );

  useEffect(() => {
    if (matchedPath) {
      const { title, content } = siteAssistantPageContent[matchedPath];
      setAssistantPanelContent(title, content, true);
    }
  }, [matchedPath, setAssistantPanelContent]);

  return (
    <Fragment>
      <ScSiteAssistant.AssistantBadge
        className={cx({
          [SiteAssistantState.ACTIVE]: matchedPath && !isAssistantPanelVisible,
          [SiteAssistantState.NOTIFY]:
            matchedPath && !isAssistantPanelVisible && notifyState,
        })}
        onClick={toggleComponentVisibility}
      >
        {images.assistIcon.component && <images.assistIcon.component />}
      </ScSiteAssistant.AssistantBadge>

      <ScSiteAssistant.AssistantPanel
        className={cx(isAssistantPanelVisible && SiteAssistantState.ACTIVE)}
      >
        {isAssistantPanelVisible && (
          <Fragment>
            <ScSiteAssistant.AssistantPanelHeader>
              <h2>{title}</h2>

              <ScSiteAssistant.ClosePanelButton
                onClick={toggleComponentVisibility}
              >
                {images.closeIcon.component && <images.closeIcon.component />}
              </ScSiteAssistant.ClosePanelButton>
            </ScSiteAssistant.AssistantPanelHeader>

            <ScSiteAssistant.AssistantPanelContent>
              {content}
            </ScSiteAssistant.AssistantPanelContent>
          </Fragment>
        )}
      </ScSiteAssistant.AssistantPanel>
    </Fragment>
  );
};

export default SiteAssistant;
