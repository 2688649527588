import * as React from 'react';
import { SVGProps } from 'react';

const SvgVerifiedIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="m19.167 9.992-2.034-2.325.284-3.075-3.009-.684-1.575-2.658L10 2.467 7.167 1.25 5.592 3.908l-3.009.675.284 3.084L.833 9.992l2.034 2.325-.284 3.083 3.009.683 1.575 2.667L10 17.525l2.833 1.217 1.575-2.659 3.009-.683-.284-3.075 2.034-2.333Zm-3.292 1.233-.467.542.067.708.15 1.625-1.583.358-.7.159-.367.616-.825 1.4-1.483-.641-.667-.284-.658.284-1.484.641-.825-1.391-.366-.617-.7-.158-1.584-.359.15-1.633.067-.708-.467-.542L3.058 10l1.075-1.233.467-.542-.075-.717-.15-1.616 1.583-.359.7-.158.367-.617.825-1.4L9.333 4l.667.283.658-.283 1.484-.642.825 1.4.366.617.7.158 1.584.359-.15 1.625-.067.708.467.542 1.075 1.225-1.067 1.233Z"
          fill="#16A34A"
        />
        <path
          d="M17.143 10a7.143 7.143 0 1 1-14.286 0 7.143 7.143 0 0 1 14.286 0Z"
          fill="#16A34A"
        />
        <path
          d="M8.781 11.174 6.91 9.294l-1.195 1.202 3.067 3.075 5.924-5.94-1.194-1.202-4.73 4.745Z"
          fill="#F8FAFC"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgVerifiedIcon;
