import React, { FC, Fragment, ReactNode } from 'react';

import { NotFoundBoundary } from 'react-navi';

import { useTheme } from 'hooks/theme';

import { SiteAssistant } from 'components/SiteAssistant';
import { Loader, LoaderType } from 'components/UI/Loader';
import { ErrorBlock } from 'components/block';
import { ScContent } from 'components/globalStyles';
import { Header } from 'components/header';
import { ErrorBoundary } from 'components/internal/errorBoundary';
import { NotFound } from 'components/notFound';
import { NotificationPopup } from 'components/notificationPopup';

import { GlobalTooltip } from 'modules/globalTooltip';

import { SeverityLevelType } from 'lib/sentry/types';

import {
  BottomTabs,
  Footer,
  LoadingProgressBar,
  TitleSetter,
} from './components';
import { useTranslationPreLoading } from './hooks';

const Layout: FC<{ children: ReactNode }> = ({ children }) => {
  const { isLoading, isInError } = useTranslationPreLoading();
  const { isSiteAssistantAvailable } = useTheme();

  return (
    <Fragment>
      {isInError ? (
        <p>error loading</p>
      ) : (
        <main>
          <TitleSetter />
          <LoadingProgressBar />
          {isLoading ? (
            <Loader type={LoaderType.FULL_SCREEN} />
          ) : (
            <Fragment>
              <Header />

              <section className="container">
                <ScContent>
                  <ErrorBoundary
                    fallback={<ErrorBlock />}
                    severity={SeverityLevelType.Fatal}
                  >
                    <NotFoundBoundary render={NotFound}>
                      {children}
                    </NotFoundBoundary>
                  </ErrorBoundary>
                </ScContent>
              </section>

              <Footer />

              <BottomTabs />

              <GlobalTooltip />

              <NotificationPopup />

              {isSiteAssistantAvailable && <SiteAssistant />}
            </Fragment>
          )}
        </main>
      )}
    </Fragment>
  );
};

export default Layout;
