import { factory } from 'api/base';

export interface Notification {
  id: string;
  content: string;
}

export const getNotification = factory(
  (r) => () => r<Notification>('get', `/notifications/active`)
);
