import * as React from 'react';
import { SVGProps } from 'react';

const SvgQrCode = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 11h8V3H3v8Zm2-6h4v4H5V5ZM3 21h8v-8H3v8Zm2-6h4v4H5v-4Zm8-12v8h8V3h-8Zm6 6h-4V5h4v4Zm0 10h2v2h-2v-2Zm-6-6h2v2h-2v-2Zm2 2h2v2h-2v-2Zm-2 2h2v2h-2v-2Zm2 2h2v2h-2v-2Zm2-2h2v2h-2v-2Zm0-4h2v2h-2v-2Zm2 2h2v2h-2v-2Z"
        fill="#9996A7"
      />
    </svg>
  );
};
export default SvgQrCode;
