import { formatTypographyToFont } from 'helpers/typography';

import styled from 'styled-components/macro';

export const Container = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 1440px;
  width: 100%;
  padding: 16px 20px 8px 20px;
  & > div:nth-child(1) {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 24px;
    row-gap: 8px;
    @media (max-width: ${({ theme }) => theme.mobileSize}) {
      flex-direction: column-reverse !important;
      align-items: flex-start;
    }
  }
  @media (max-width: ${({ theme }) => theme.tabletSize}) {
    display: none;
  }
`;

export const Copyright = styled.span`
  color: ${({ theme }) => theme.colors.text.secondary};
  font: ${({ theme }) =>
    formatTypographyToFont(theme.typography['paragraph-medium'])};
`;

export const Bestchange = styled.a`
  height: 16px;
`;
