import { TFunction, TOptions } from 'i18next';

import { capitalize } from 'helpers/base';
import {
  TranslationMapping,
  errorHandlerFactoryWithTranslation,
} from 'helpers/error';

export function translationFactory(
  key: string | string[],
  options: {
    params?: TOptions;
    format?: (value: string) => string;
  } = {}
) {
  return (t: TFunction) => {
    const trans = t(key, options.params);

    return options.format ? options.format(trans) : trans;
  };
}

export function errorTranslationFactory(
  errorCode: number,
  mapping: TranslationMapping,
  def?: string | string[]
) {
  return (t: TFunction) => {
    const errorHandler = errorHandlerFactoryWithTranslation(
      t,
      mapping,
      def || 'common:error.unknownShort'
    );
    const error = errorHandler(errorCode);

    return typeof error === 'string'
      ? error
      : capitalize(t('common:serverError.unknown'));
  };
}
