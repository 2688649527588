import * as React from 'react';
import { SVGProps } from 'react';

const SvgCrystalsSuccess = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={195}
      height={188}
      viewBox="0 0 195 188"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={94.5}
        cy={94}
        r={92}
        fill="#2D3344"
        stroke="#4ADE80"
        strokeWidth={4}
      />
      <path
        d="m150.636 40.992-.085-1.28a5.217 5.217 0 0 0-3.771-1.61h-27.582a5.217 5.217 0 0 0-3.771 1.61v1.28l7.125 11.682h22.011l6.073-11.682Z"
        fill="url(#a)"
      />
      <path
        d="M119.198 38.102a5.217 5.217 0 0 0-3.771 1.61v1.28l3.581 5.872 6.171-8.761h-5.981Z"
        fill="url(#b)"
      />
      <path
        d="m132.989 38.102-9.912 14.572.618.6h18.207l.999-.6-9.912-14.572Z"
        fill="url(#c)"
      />
      <path
        d="m115.427 39.713-9.902 10.356a3.768 3.768 0 0 0-1.046 2.605l.655.6h16.909l1.033-.6-7.649-12.961Z"
        fill="url(#d)"
      />
      <path
        d="m114.917 52.673 4.439-6.303-3.929-6.658-9.902 10.356a3.768 3.768 0 0 0-1.046 2.605l.655.6h10.095l-.312-.6Z"
        fill="url(#e)"
      />
      <path
        d="M161.5 52.673c0-.97-.375-1.903-1.047-2.605l-9.902-10.356-7.65 12.961.761.6H161l.5-.6Z"
        fill="url(#f)"
      />
      <path
        d="M104.479 52.674c0 1.089.389 2.142 1.099 2.97L128.925 82.9a5.353 5.353 0 0 0 8.128 0l23.348-27.257a4.568 4.568 0 0 0 1.099-2.97h-57.021Z"
        fill="url(#g)"
      />
      <path
        d="M114.917 52.674h-10.438c0 1.089.389 2.142 1.099 2.97L128.926 82.9a5.342 5.342 0 0 0 2.596 1.662l-16.605-31.89Z"
        fill="url(#h)"
      />
      <path
        d="M132.99 84.769c.501 0 .994-.071 1.467-.206l8.445-31.89h-19.825l8.445 31.89a5.36 5.36 0 0 0 1.468.206Z"
        fill="url(#i)"
      />
      <path
        d="m102.215 65.814-.748-2.192a9.434 9.434 0 0 0-7.332-1.052L46.032 75.413a9.396 9.396 0 0 0-5.822 4.564l.6 2.232 17.905 17.055 38.387-10.249 5.113-23.201Z"
        fill="url(#j)"
      />
      <path
        d="M46.032 75.414a9.397 9.397 0 0 0-5.822 4.564l.6 2.231 9 8.573 6.652-18.153-10.43 2.785Z"
        fill="url(#k)"
      />
      <path
        d="M70.083 68.992 59.63 99.02l1.359.759L92.742 91.3l1.462-1.512-24.12-20.797Z"
        fill="url(#l)"
      />
      <path
        d="m40.21 79.977-12.413 22.671a6.798 6.798 0 0 0-.603 5.031l1.424.741 29.491-7.874 1.52-1.527-19.42-19.042Z"
        fill="url(#m)"
      />
      <path
        d="m45.4 102.819 4.785-13.06-9.976-9.781-12.412 22.671a6.798 6.798 0 0 0-.603 5.031l1.424.741 17.607-4.701-.826-.901Z"
        fill="url(#n)"
      />
      <path
        d="M126.639 81.129a6.821 6.821 0 0 0-3.047-4.057l-22.126-13.45-7.263 26.167 1.608.692 30.237-8.074.591-1.278Z"
        fill="url(#o)"
      />
      <path
        d="M27.193 107.68a8.266 8.266 0 0 0 3.31 4.669l53.502 36.665a9.674 9.674 0 0 0 7.962 1.364 9.635 9.635 0 0 0 6.212-5.149l27.935-58.408a8.231 8.231 0 0 0 .524-5.692L27.193 107.68Z"
        fill="url(#p)"
      />
      <path
        d="m45.398 102.82-18.205 4.861a8.264 8.264 0 0 0 3.31 4.668l53.502 36.665a9.675 9.675 0 0 0 5.307 1.69L45.398 102.82Z"
        fill="url(#q)"
      />
      <path
        d="M91.969 150.378a9.657 9.657 0 0 0 2.462-1.041l-.227-59.548L59.63 99.02l29.683 51.683a9.64 9.64 0 0 0 2.656-.325Z"
        fill="url(#r)"
      />
      <path
        d="m147.887 42.795-.073-1.1a4.483 4.483 0 0 0-3.241-1.383h-23.707c-1.225 0-2.396.5-3.241 1.383v1.1l6.124 10.04h18.918l5.22-10.04Z"
        fill="#C3DDFF"
      />
      <path
        d="M120.866 40.312c-1.225 0-2.396.5-3.241 1.383v1.1l3.078 5.047 5.304-7.53h-5.141Z"
        fill="#A4CCFF"
      />
      <path
        d="m132.72 40.312-8.52 12.523.531.516h15.649l.859-.516-8.519-12.523Z"
        fill="#DBEAFF"
      />
      <path
        d="m117.625 41.695-8.511 8.901a3.24 3.24 0 0 0-.899 2.24l.563.515h14.534l.888-.516-6.575-11.14Z"
        fill="#DBEAFF"
      />
      <path
        d="m117.187 52.835 3.815-5.418-3.377-5.722-8.511 8.901a3.24 3.24 0 0 0-.899 2.24l.563.515h8.677l-.268-.516Z"
        fill="#C3DDFF"
      />
      <path
        d="M157.223 52.835a3.24 3.24 0 0 0-.899-2.239l-8.511-8.9-6.575 11.14.654.515h14.902l.429-.516Z"
        fill="#DBEAFF"
      />
      <path
        d="M108.215 52.836c0 .936.335 1.841.945 2.553l20.067 23.427a4.596 4.596 0 0 0 3.492 1.605 4.598 4.598 0 0 0 3.493-1.605l20.067-23.427c.61-.712.945-1.617.945-2.553h-49.009Z"
        fill="#A4CCFF"
      />
      <path
        d="M117.187 52.836h-8.972c0 .936.335 1.841.945 2.553l20.067 23.427a4.593 4.593 0 0 0 2.231 1.428l-14.271-27.408Z"
        fill="#8BB3EA"
      />
      <path
        d="M132.72 80.421a4.6 4.6 0 0 0 1.261-.177l7.258-27.408H124.2l7.258 27.408c.407.116.831.177 1.262.177ZM99.78 69.977l-.676-1.98a8.525 8.525 0 0 0-6.626-.952L49.01 78.651a8.492 8.492 0 0 0-5.261 4.124l.542 2.017 16.18 15.412 34.689-9.262 4.62-20.965Z"
        fill="#C3DDFF"
      />
      <path
        d="M49.01 78.651a8.492 8.492 0 0 0-5.261 4.125l.542 2.016 8.134 7.747 6.01-16.404-9.425 2.516Z"
        fill="#A4CCFF"
      />
      <path
        d="m70.744 72.849-9.446 27.135 1.228.685 28.694-7.661 1.32-1.366L70.745 72.85ZM43.748 82.775l-11.216 20.488a6.142 6.142 0 0 0-.545 4.546l1.287.669 26.65-7.115 1.374-1.38-17.55-17.208Z"
        fill="#DBEAFF"
      />
      <path
        d="m48.438 103.417 4.325-11.803-9.014-8.839-11.217 20.488a6.142 6.142 0 0 0-.545 4.546l1.287.669 15.91-4.248-.746-.813Z"
        fill="#C3DDFF"
      />
      <path
        d="M121.851 83.816a6.168 6.168 0 0 0-2.753-3.666L99.103 67.996 92.54 91.642l1.453.625 27.324-7.296.534-1.155Z"
        fill="#DBEAFF"
      />
      <path
        d="M31.986 107.809a7.469 7.469 0 0 0 2.991 4.218l48.347 33.133a8.743 8.743 0 0 0 7.195 1.233 8.708 8.708 0 0 0 5.613-4.653l25.244-52.781a7.438 7.438 0 0 0 .474-5.144L31.986 107.81Z"
        fill="#A4CCFF"
      />
      <path
        d="m48.437 103.417-16.45 4.392a7.468 7.468 0 0 0 2.99 4.219l48.347 33.132a8.74 8.74 0 0 0 4.796 1.527l-39.683-43.27Z"
        fill="#8BB3EA"
      />
      <path
        d="M90.52 146.393a8.717 8.717 0 0 0 2.226-.941l-.205-53.81-31.243 8.341 26.823 46.704a8.75 8.75 0 0 0 2.4-.294Z"
        fill="#C3DDFF"
      />
      <circle
        cx={166.5}
        cy={156}
        r={26}
        fill="#4ADE80"
        stroke="#2D3344"
        strokeWidth={4}
      />
      <path
        d="m162.5 163.172-6.586-6.586-2.828 2.828 9.414 9.414 19.414-19.414-2.828-2.828-16.586 16.586Z"
        fill="#fff"
      />
      <defs>
        <linearGradient
          id="a"
          x1={133.031}
          y1={38.102}
          x2={133.031}
          y2={52.674}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD04E" />
          <stop offset={1} stopColor="#D8A61B" />
        </linearGradient>
        <linearGradient
          id="b"
          x1={120.303}
          y1={38.102}
          x2={120.303}
          y2={46.864}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD04E" />
          <stop offset={1} stopColor="#D8A61B" />
        </linearGradient>
        <linearGradient
          id="c"
          x1={132.989}
          y1={38.102}
          x2={132.989}
          y2={53.274}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD04E" />
          <stop offset={1} stopColor="#D8A61B" />
        </linearGradient>
        <linearGradient
          id="d"
          x1={113.777}
          y1={39.713}
          x2={113.777}
          y2={53.274}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD04E" />
          <stop offset={1} stopColor="#D8A61B" />
        </linearGradient>
        <linearGradient
          id="e"
          x1={111.917}
          y1={39.712}
          x2={111.917}
          y2={53.273}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD04E" />
          <stop offset={1} stopColor="#D8A61B" />
        </linearGradient>
        <linearGradient
          id="f"
          x1={152.2}
          y1={39.712}
          x2={152.2}
          y2={53.273}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD04E" />
          <stop offset={1} stopColor="#D8A61B" />
        </linearGradient>
        <linearGradient
          id="g"
          x1={132.989}
          y1={52.674}
          x2={132.989}
          y2={84.769}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD04E" />
          <stop offset={1} stopColor="#D8A61B" />
        </linearGradient>
        <linearGradient
          id="h"
          x1={118}
          y1={52.674}
          x2={118}
          y2={84.563}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD04E" />
          <stop offset={1} stopColor="#D8A61B" />
        </linearGradient>
        <linearGradient
          id="i"
          x1={132.989}
          y1={52.674}
          x2={132.989}
          y2={84.769}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD04E" />
          <stop offset={1} stopColor="#D8A61B" />
        </linearGradient>
        <linearGradient
          id="j"
          x1={71.212}
          y1={62.251}
          x2={71.212}
          y2={99.264}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD04E" />
          <stop offset={1} stopColor="#D8A61B" />
        </linearGradient>
        <linearGradient
          id="k"
          x1={48.336}
          y1={72.629}
          x2={48.336}
          y2={90.782}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD04E" />
          <stop offset={1} stopColor="#D8A61B" />
        </linearGradient>
        <linearGradient
          id="l"
          x1={76.917}
          y1={68.992}
          x2={76.917}
          y2={99.779}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD04E" />
          <stop offset={1} stopColor="#D8A61B" />
        </linearGradient>
        <linearGradient
          id="m"
          x1={43.295}
          y1={79.977}
          x2={43.295}
          y2={108.42}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD04E" />
          <stop offset={1} stopColor="#D8A61B" />
        </linearGradient>
        <linearGradient
          id="n"
          x1={38.573}
          y1={79.978}
          x2={38.573}
          y2={108.421}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD04E" />
          <stop offset={1} stopColor="#D8A61B" />
        </linearGradient>
        <linearGradient
          id="o"
          x1={110.421}
          y1={63.622}
          x2={110.421}
          y2={90.481}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD04E" />
          <stop offset={1} stopColor="#D8A61B" />
        </linearGradient>
        <linearGradient
          id="p"
          x1={77.057}
          y1={81.129}
          x2={77.057}
          y2={150.706}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD04E" />
          <stop offset={1} stopColor="#D8A61B" />
        </linearGradient>
        <linearGradient
          id="q"
          x1={58.253}
          y1={102.82}
          x2={58.253}
          y2={150.704}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD04E" />
          <stop offset={1} stopColor="#D8A61B" />
        </linearGradient>
        <linearGradient
          id="r"
          x1={77.031}
          y1={89.789}
          x2={77.031}
          y2={150.704}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD04E" />
          <stop offset={1} stopColor="#D8A61B" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default SvgCrystalsSuccess;
