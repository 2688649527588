import React, { FC, useEffect, useState } from 'react';

import { useApi } from 'hooks/api';

import { getNotification } from 'api/notification';

import { getFromLocalStorageList, saveToLocalStorageList } from 'helpers/base';

import { BonusesPopup } from './popups/bonuses';
import { CryptoPopup } from './popups/crypto';
import { GiveawayPopup } from './popups/giveaway';
import { SystemPopup } from './popups/system';
import { TelegramPopup } from './popups/telegram';
import { PopupNotificationProps } from './popups/type';
import { globalPopupStore } from './store';
import { ScNotificationPopup } from './styled';

interface PopupsProps {
  [propertyName: string]: (
    closePopup: PopupNotificationProps['closePopup'],
    content?: string | undefined
  ) => JSX.Element;
}

const popups: PopupsProps = {
  promoGiveaway: (closePopup) => <GiveawayPopup closePopup={closePopup} />,
  promoTelegram: (closePopup) => <TelegramPopup closePopup={closePopup} />,
  promoCrypto: (closePopup) => <CryptoPopup closePopup={closePopup} />,
  promoBonuses: (closePopup) => <BonusesPopup closePopup={closePopup} />,
  isSystem: (closePopup, content) => (
    <SystemPopup closePopup={closePopup} content={content} />
  ),
};

export const NotificationPopup: FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const popupContent = globalPopupStore((state) => state.body);

  const [requestNotification, { data: notificationData }] =
    useApi(getNotification);

  useEffect(() => {
    (async () => {
      const { error, response } = await requestNotification().request;

      if (error == null && response && response.data) {
        const listOfReadNotifications = getFromLocalStorageList(
          'blockedNotification'
        );
        !listOfReadNotifications.includes(response.data.id) &&
          setIsModalVisible(true);
      }
    })();
  }, [requestNotification]);

  useEffect(() => {
    popupContent && setIsModalVisible(true);
  }, [popupContent]);

  const closePopup = () => {
    setIsModalVisible(false);
  };

  const handleInformed = () => {
    notificationData?.id &&
      saveToLocalStorageList('blockedNotification', notificationData.id);
    closePopup();
  };

  if (
    notificationData == null ||
    notificationData.content == null ||
    !isModalVisible
  )
    return null;

  const currentPopupKey =
    Object.keys(popups).find(
      (key) =>
        notificationData &&
        notificationData.content &&
        key === notificationData.content
    ) || 'isSystem';

  return (
    <ScNotificationPopup type={currentPopupKey}>
      {popupContent
        ? popupContent
        : popups[currentPopupKey](handleInformed, notificationData.content)}
    </ScNotificationPopup>
  );
};
