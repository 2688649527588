import { WithdrawStatus } from 'api/bonus';

import { Languages, i18nInstance as i18n } from 'lib/i18n';

import {
  hasComDomain,
  hasRuDomain,
} from '../components/Layout/components/Footer/helpers';
import { ENV } from '../env/env.config';

export function loadNamespaces(
  ns: string | string[],
  cb: (value?: unknown) => void
) {
  i18n.loadNamespaces(ns, () => {
    // delay ready if not yet initialized i18n instance
    if (i18n.isInitialized) {
      cb();
    } else {
      const initialized = () => {
        // due to emitter removing issue in i18next we need to delay remove
        setTimeout(() => {
          i18n.off('initialized', initialized);
        }, 0);
        cb();
      };

      i18n.on('initialized', initialized);
    }
  });
}

export function hasLoadedNamespace(ns: string) {
  if (!i18n.languages || !i18n.languages.length) {
    return true;
  }

  const lng = i18n.languages[0];
  const fallbackLng = i18n.options ? i18n.options.fallbackLng : false;
  const lastLng = i18n.languages[i18n.languages.length - 1];

  // we're in cimode so this shall pass
  if (lng.toLowerCase() === 'cimode') return true;

  const loadNotPending = (l: string, n: string) => {
    const loadState = i18n.services.backendConnector.state[`${l}|${n}`];
    return loadState === -1 || loadState === 2;
  };

  // loaded -> SUCCESS
  if (i18n.hasResourceBundle(lng, ns)) return true;

  // were not loading at all -> SEMI SUCCESS
  if (!i18n.services.backendConnector.backend) return true;

  // failed loading ns - but at least fallback is not pending -> SEMI SUCCESS
  if (loadNotPending(lng, ns) && (!fallbackLng || loadNotPending(lastLng, ns)))
    return true;

  return false;
}

export function getBonusOperationsStatusNameI18nKey(status: WithdrawStatus) {
  return `common:bonusOperations.status.${status}`;
}

export function getLocaleBestchangeUrl(language: Languages) {
  if (!ENV.BESTCHANGE_URL) return undefined;

  if (hasRuDomain(ENV.BESTCHANGE_URL)) {
    switch (language) {
      case Languages.ENGLISH:
        return ENV.BESTCHANGE_URL.replace(/\.ru\b/, '.com');
      default:
        return ENV.BESTCHANGE_URL;
    }
  } else if (hasComDomain(ENV.BESTCHANGE_URL)) {
    switch (language) {
      case Languages.RUSSIAN:
        return ENV.BESTCHANGE_URL.replace(/\.com\b/, '.ru');
      default:
        return ENV.BESTCHANGE_URL;
    }
  }
}
