import * as React from 'react';
import { SVGProps } from 'react';

const SvgNotificationTelegram = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={185}
      height={244}
      viewBox="0 0 185 244"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M99.044 221.738h-5.3v.153h5.3v-.153Zm-64.703-3.721h-57.145v.153h57.145v-.153Zm7.229-34.959H-76.215a3.48 3.48 0 0 1-3.477-3.483V14.003a3.483 3.483 0 0 1 3.477-3.453H41.569a3.483 3.483 0 0 1 3.484 3.483v165.542a3.482 3.482 0 0 1-3.483 3.483ZM-76.215 10.672a3.33 3.33 0 0 0-3.325 3.33v165.573a3.33 3.33 0 0 0 3.325 3.331H41.569a3.336 3.336 0 0 0 3.331-3.331V14.003a3.336 3.336 0 0 0-3.33-3.33H-76.215Zm207.93 97.588h5.68V83.854h-5.68v24.406Zm-35.302-.969 5.597.964 4.146-24.051-5.597-.965-4.146 24.052Zm55.719.975h5.68V87.075h-5.68v21.191Zm-13.047 0h5.679V87.075h-5.679v21.191Zm-32.935 0h5.679V87.075h-5.679v21.191Zm41.078 0h2.836V82.652h-2.836v25.614Zm-33.77 0h2.837V82.652h-2.837v25.614Zm-42.865 9.321h-5.679v24.406h5.68v-24.406Zm27.45 0h-5.679v24.406h5.68v-24.406Zm-20.417 3.215h-5.679v21.191h5.68v-21.191Zm13.048 0h-5.679v21.191h5.68v-21.191Zm32.935 0h-5.679v21.191h5.679v-21.191Zm-18.103-.736-5.544 1.232 4.597 20.687 5.544-1.233-4.597-20.686Zm-22.976-3.687h-2.836v25.614h2.837v-25.614Zm33.77 0h-2.836v25.614h2.836v-25.614Zm35.832 25.614h5.68v-21.191h-5.68v21.191Zm-13.047 0h5.679v-21.191h-5.679v21.191Zm-37.278 33.727h5.679v-24.406h-5.679v24.406Zm-35.301-.966 5.597.965 4.145-24.052-5.596-.964-4.146 24.051Zm55.717.966h5.679v-21.191h-5.679v21.191Zm-13.047 0h5.679v-21.191h-5.679v21.191Zm-32.934 0h5.679v-21.191h-5.68v21.191Zm13.522-1.234 5.544 1.232 4.597-20.686-5.544-1.232-4.597 20.686Zm27.556 1.234h2.836v-25.614h-2.836v25.614Zm-33.764 0h2.837v-25.614h-2.837v25.614Zm57.998 0h5.679v-21.191h-5.679v21.191Z"
        fill="#EBEBEB"
      />
      <path
        d="M108.992 74.18v-.934l-3.19-.725V58.563l-2.733-3.819-2.33 2.99v6.404l-1.208-1.671-1.69-.647-1.207 2.641 2.574 8.058-2.574.726v.934h12.358Zm-21.16.427-3.91-19.862H71.947l2.837 11.26-4.197 8.175 17.245.427ZM9.087 45.01h-79.568v4.086H9.087V45.01Z"
        fill="#E0E0E0"
      />
      <path d="M1.45 33.084h-17.08v11.79H1.45v-11.79Z" fill="#EBEBEB" />
      <path d="M-70.487 79.62H9.08v-4.086h-79.568v4.087Z" fill="#E0E0E0" />
      <path
        d="M1.913 75.534h3.508V60.467H1.913v15.067Zm-4.343-.007h3.508V62.443h-3.507v13.084Z"
        fill="#EBEBEB"
      />
      <path
        d="M49.5 237.811c65.32 0 118.272-3.091 118.272-6.905 0-3.813-52.952-6.905-118.272-6.905-65.32 0-118.273 3.092-118.273 6.905 0 3.814 52.952 6.905 118.272 6.905Z"
        fill="#666673"
      />
      <path
        d="M120.925 0H7.052A7.052 7.052 0 0 0 0 7.052v216.077a7.052 7.052 0 0 0 7.052 7.052h113.873a7.052 7.052 0 0 0 7.052-7.052V7.052A7.052 7.052 0 0 0 120.925 0Z"
        fill="#263238"
      />
      <path
        d="M123.341 7.889v214.348a3.142 3.142 0 0 1-.934 2.232 3.13 3.13 0 0 1-2.246.901H6.843a2.824 2.824 0 0 1-1.1-.21 2.81 2.81 0 0 1-1.74-2.665V7.631A2.53 2.53 0 0 1 6.435 5h28.33a1.994 1.994 0 0 1 1.824 1.275l1.173 2.888a1.994 1.994 0 0 0 1.824 1.261h48.23a1.973 1.973 0 0 0 1.83-1.24l1.174-2.902A2.034 2.034 0 0 1 92.645 5h27.922a2.863 2.863 0 0 1 2.774 2.889Z"
        fill="url(#a)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.304 87.365a4.869 4.869 0 0 1 4.843.665 4.824 4.824 0 0 1 1.514 2.02 4.8 4.8 0 0 1 .283 2.505l-7.167 46.356a4.808 4.808 0 0 1-.98 2.246 4.85 4.85 0 0 1-4.4 1.807 4.853 4.853 0 0 1-2.286-.904l-21.134-15.494a3.219 3.219 0 0 1-1.308-2.392 3.202 3.202 0 0 1 1.006-2.533l19.722-18.532a.802.802 0 0 0-.425-1.375.814.814 0 0 0-.571.118L48.328 118.77a9.726 9.726 0 0 1-7.646 1.278l-12.234-3.043a3.237 3.237 0 0 1-1.689-1.048 3.207 3.207 0 0 1 1.24-5.042l57.305-23.55Z"
        fill="#fff"
      />
      <path
        opacity={0.6}
        d="M61.33 6.746a1.64 1.64 0 1 0 0-3.282 1.64 1.64 0 0 0 0 3.282Zm4.944-1.641a1.05 1.05 0 1 1-.648-.973 1.044 1.044 0 0 1 .648.973ZM17.826 13.46h1.472v-1.967h-1.471v1.966Zm-2.406 0h1.47v-2.618h-1.47v2.617Zm-2.401 0h1.471v-3.276h-1.47v3.276Zm-2.401 0h1.472V9.527h-1.472v3.933Zm99.886-2.686h-.875v1.967h.875v-1.967Zm.597 2.686h6.963v-3.398h-6.963v3.398Z"
        fill="#fff"
      />
      <path
        d="m173.088 87.325 1.488-2.703 3.831-5.569.72-4.612s3.355 1.22 3.904 2.215c.549.994.293 5.917 0 6.52-.293.605-3.752 2.977-3.752 2.977l-1.83 3.538-4.361-2.366Z"
        fill="#EBB376"
      />
      <path
        d="M156.105 67.945s12.396 19.465 13.847 21.21c1.452 1.744 3.05-4.533 3.05-4.533l6.271 2.733s-1.769 11.901-6.594 12.383c-4.825.482-15.122-12.2-15.122-12.2l-1.452-19.593Z"
        fill="#263238"
      />
      <path
        opacity={0.1}
        d="M179.279 87.355s-1.769 11.901-6.594 12.39c-2.409.237-6.191-2.813-9.363-5.93-3.172-3.117-5.759-6.295-5.759-6.295l-.5-6.71-.958-12.895s12.396 19.465 13.847 21.21c1.452 1.744 3.05-4.533 3.05-4.533l6.277 2.763Z"
        fill="#fff"
      />
      <path
        opacity={0.2}
        d="M163.322 93.815c-3.178-3.117-5.758-6.295-5.758-6.295l-.501-6.71a19.963 19.963 0 0 1 6.259 13.005Z"
        fill="#000"
      />
      <path
        d="M132.115 172.34v-6.039a.437.437 0 0 0-.342-.427l-4.373-.994a.73.73 0 0 0-.873.561.758.758 0 0 0 0 .165c0 1.952.135 3.373.122 5.825 0 1.513-.189 6.07-.195 8.156-.006 2.086 2.05 2.373 2.239 1.519.854-3.831 2.025-6.71 3.135-7.93a1.22 1.22 0 0 0 .287-.836Z"
        fill="#263238"
      />
      <path
        d="m159.071 116.739 1.952 10.229s15.414 24.949 15.408 39.352c0 17.208-44.896 7.283-44.896 7.283v-7.93s26.84 2.306 29.28-.384c2.934-3.27-15.158-28.548-15.158-28.548l13.414-20.002Z"
        fill="#263238"
      />
      <path
        opacity={0.2}
        d="M176.431 166.326c0 9.485-13.652 10.723-25.894 9.924a143.645 143.645 0 0 1-18.16-2.47l-.842-.177v-7.93l.988.079c3.05.244 12.548.934 19.734.83 4.27-.061 7.729-.409 8.54-1.293 1.439-1.605-2.196-8.54-6.265-15.153-4.215-6.85-8.894-13.389-8.894-13.389l5.893-8.772 7.552-11.236 1.952 10.229s15.402 24.949 15.396 39.358Z"
        fill="#fff"
      />
      <path
        opacity={0.2}
        d="m152.257 166.588-1.72 9.662a143.645 143.645 0 0 1-18.16-2.47l.146-8.022c3.05.244 12.566.952 19.734.83Zm-.714-27.67s2.507 5.899 2.977 11.218c-4.215-6.85-8.894-13.389-8.894-13.389l5.893-8.772 3.104-1.007s.69 4.026-3.08 11.95Z"
        fill="#000"
      />
      <path
        d="M147.529 225.52h-6.039a.452.452 0 0 0-.427.342l-.976 4.428a.744.744 0 0 0 .1.551.73.73 0 0 0 .461.316c.055.006.11.006.165 0 1.952-.031 3.373-.153 5.832-.153 1.506 0 6.069.159 8.155.159 2.087 0 2.355-2.062 1.501-2.251-3.831-.836-6.71-1.989-7.93-3.093a1.226 1.226 0 0 0-.842-.299Z"
        fill="#263238"
      />
      <path
        d="M132.176 117.068s-1.336 5.008 0 27.969c2.062 35.252 8.595 82.179 8.595 82.179l7.594-1.379s-.97-70.198.055-89.572c0 0 10.736-9.632 10.651-19.52-.086-9.888-26.895.323-26.895.323Z"
        fill="#263238"
      />
      <path
        opacity={0.2}
        d="M132.176 117.068s-1.336 5.008 0 27.969c2.062 35.252 8.595 82.179 8.595 82.179l7.594-1.379s-.97-70.198.055-89.572c0 0 10.736-9.632 10.651-19.52-.086-9.888-26.895.323-26.895.323Z"
        fill="#fff"
      />
      <path
        d="M158.735 113.902h-26.559v3.166l26.901-.329-.342-2.837Z"
        fill="#263238"
      />
      <path
        d="M140.716 118.093h.945v-5.612h-.945v5.612Zm13.438 0h.946v-5.612h-.946v5.612Z"
        fill="#263238"
      />
      <path d="M146.632 116.885h4.911v-3.538h-4.911v3.538Z" fill="#fff" />
      <path
        opacity={0.2}
        d="M140.716 118.093h.945v-5.612h-.945v5.612Zm13.438 0h.946v-5.612h-.946v5.612Z"
        fill="#fff"
      />
      <path
        d="M156.106 67.945s-16.781-.793-29.28 2.36c0 0 5.521 27.792 5.313 43.597h26.602s1.721-29.646-2.635-45.957Z"
        fill="#FFD04E"
      />
      <path
        opacity={0.6}
        d="M158.735 113.902h-26.596c0-1.72 0-3.574-.122-5.526-.232-4.563-.75-9.62-1.378-14.512l-.183-1.428a388.487 388.487 0 0 0-1.33-9.058c-1.22-7.534-2.3-13.072-2.3-13.072 12.499-3.154 29.28-2.367 29.28-2.367 4.356 16.317 2.629 45.963 2.629 45.963Z"
        fill="#8A8A8A"
      />
      <path
        d="M136.622 69.05c2.41 2.189 8.308 1.378 8.308 1.378s3.66-1.007 2.916-2.16c-2.172-.36-3.221-1.372-3.66-2.671a6.377 6.377 0 0 1-.22-2.477c.037-.465.098-.927.183-1.385l-7.32-5.404c.983 3.568 2.16 10.132-.207 12.718Z"
        fill="#EBB376"
      />
      <path
        opacity={0.2}
        d="M138.538 59.765c.293 4.38 3.892 5.587 5.649 5.831a6.358 6.358 0 0 1-.22-2.476l-5.429-3.355Z"
        fill="#000"
      />
      <path
        d="M134.42 44.984s-2.037 6.588 1.153 9.718c3.19 3.129 5.069-9.419 5.069-9.419l-6.222-.299Z"
        fill="#263238"
      />
      <path
        d="M145.157 61.613a6.18 6.18 0 0 1-7.381 1.318 6.87 6.87 0 0 1-3.416-3.752 7.515 7.515 0 0 1-.226-.695c-1.141-4.38-.61-11.67 4.142-13.719a6.711 6.711 0 0 1 8.821 3.453c.424.958.615 2.003.555 3.05-.183 4.91-.067 7.795-2.495 10.345Z"
        fill="#EBB376"
      />
      <path
        d="M140.246 52.804c.036.39-.195.72-.458.726-.262.006-.475-.317-.488-.713-.012-.397.202-.726.458-.726s.451.305.488.713Zm-4.508.086c.037.397-.201.726-.457.732-.257.006-.476-.317-.489-.714-.012-.396.202-.726.458-.732.256-.006.482.336.488.714Z"
        fill="#263238"
      />
      <path
        d="M137.159 53.06a14.12 14.12 0 0 1-1.83 3.453 2.323 2.323 0 0 0 1.928.317l-.098-3.77Z"
        fill="#D58745"
      />
      <path
        d="M141.619 51.316a.259.259 0 0 1-.232-.091 1.883 1.883 0 0 0-1.513-.75.239.239 0 0 1-.256-.214.23.23 0 0 1 .213-.25 2.315 2.315 0 0 1 1.922.927.233.233 0 0 1 0 .323.492.492 0 0 1-.134.055Zm-7.852.061a.206.206 0 0 1-.134 0 .232.232 0 0 1-.122-.305 2.304 2.304 0 0 1 1.659-1.348.233.233 0 0 1 .263.195.237.237 0 0 1-.196.262 1.881 1.881 0 0 0-1.299 1.092.255.255 0 0 1-.074.07.258.258 0 0 1-.097.034Zm10.17-6.13-.324 2.147a1.61 1.61 0 0 1 .751 2.05c-.745 1.799.207 4.642 1.573 5.8 0 0 .061-.78.72-.61.659.171.366 4.27-.982 6.216a14.319 14.319 0 0 0 3.562-6.368c1.135-3.953 1.013-6.875.238-8.436l-5.538-.8Z"
        fill="#263238"
      />
      <path
        d="M149.872 55.257a4.688 4.688 0 0 1-2.623 2.44c-1.538.549-2.44-.8-2.099-2.312.305-1.348 1.458-3.294 3.05-3.178 1.592.115 2.294 1.653 1.672 3.05Z"
        fill="#EBB376"
      />
      <path
        d="M136.896 58.026c.205.566.366 1.148.482 1.738.106.006.212.006.318 0 1.439-.067 2.086-.573 2.366-1.11.13-.268.195-.562.189-.86a1.763 1.763 0 0 0-.067-.5 6.703 6.703 0 0 1-2.793.714c-.305.018-.495.018-.495.018Z"
        fill="#263238"
      />
      <path
        d="m137.391 57.995.152.513c1.354-.067 2.3-.293 2.709-.726a1.8 1.8 0 0 0-.068-.5 6.703 6.703 0 0 1-2.793.713Z"
        fill="#fff"
      />
      <path
        d="M137.696 59.752c1.44-.067 2.086-.573 2.367-1.11a4.655 4.655 0 0 0-1.586.39c-.33.151-.604.404-.781.72Z"
        fill="#DE5753"
      />
      <path
        d="M143.613 47.394s-5.142 2.25-8.345-.323c-3.202-2.575 0-6.582 0-6.582a4.4 4.4 0 0 0 2.83 1.11c1.69 0 5.49-1.287 8.748.08 3.257 1.366 4.733 5.794 2.013 8.045l-5.246-2.33Z"
        fill="#263238"
      />
      <path
        d="m147.334 70.068-.702 3.477s4.039 16.86 4.911 25.62l-1.476 1.47-2.318-1.47s-.208-17.812-2.251-25.297l-3.66-3.001 2.885-.933 2.611.134Z"
        fill="#FFD04E"
      />
      <path
        d="m137.446 65.743 7.033 3.16.415-2.532 2.574.909a2.106 2.106 0 0 1 1.403 2.214l-.286 2.782-3.587-1.946-1.769 2.507-8.424-4.056 2.641-3.038Z"
        fill="#fff"
      />
      <path
        opacity={0.7}
        d="m137.446 65.743 7.033 3.16.415-2.532 2.574.909a2.106 2.106 0 0 1 1.403 2.214l-.286 2.782-3.587-1.946-1.769 2.507-8.424-4.056 2.641-3.038Z"
        fill="#FFECB8"
      />
      <path
        d="M135 68.348s6.613 5.38 14.085 22.533c0 0 .275-16.763-1.135-23.338l8.156.396s5.966 13.182 2.971 41.657l2.068 14.237-8.992 3.764-2.074-6.637-2.129 6.509-17.336-2.318.921-17.977s-4.135-29.7-4.709-36.844L135 68.348Z"
        fill="#263238"
      />
      <path
        opacity={0.1}
        d="m161.144 123.839-8.991 3.758-2.074-6.637-2.129 6.503-6.71-.897-10.638-1.421.457-8.931.464-9.046s-.745-5.35-1.653-12.054c-.47-3.477-.976-7.32-1.452-10.98-.757-5.905-1.409-11.31-1.605-13.859l7.607-1.83.567-.134s6.613 5.386 14.085 22.533c0 0 .275-16.763-1.134-23.332l8.155.396s5.966 13.176 2.971 41.657l2.08 14.274Z"
        fill="#fff"
      />
      <path
        opacity={0.2}
        d="m152.092 75.21 1.891 3.337s-1.965 12.81-5.124 17.3v-2.654l-12.237-14.11 2.196-3.391-5.49 1.086s-.512-5.155 1.122-8.296l.568-.135s6.612 5.387 14.085 22.534c0 0 .274-16.763-1.135-23.333 0 0 3.898 2.11 6.673 7.662h-2.549Z"
        fill="#fff"
      />
      <path
        opacity={0.2}
        d="M129.881 95.132c-.469-3.477-.976-7.32-1.451-10.98l1.946-.994s1.744 6.692-.495 11.974Zm11.372 31.452-10.639-1.421.458-8.931c3.251 1.58 8.528 4.899 10.181 10.352Z"
        fill="#000"
      />
      <path
        d="M126.826 70.33s-13.024 19.453-11.737 30.311c1.288 10.858 17.08 28.548 17.08 28.548l5.033-3.129s-12.627-19.38-13.749-25.419c-.61-3.245 6.917-17.452 6.917-17.452s-.5-9.809-3.544-12.859Z"
        fill="#263238"
      />
      <path
        opacity={0.1}
        d="M126.826 70.33s-13.024 19.453-11.737 30.311c1.288 10.858 17.08 28.548 17.08 28.548l5.033-3.129s-12.627-19.38-13.749-25.419c-.61-3.245 6.917-17.452 6.917-17.452s-.5-9.809-3.544-12.859Z"
        fill="#fff"
      />
      <path
        d="m167.696 83.848 1.171-15.995 10.413.72 1.232-5.124 2.52 1.055-5.271 18.673-10.065.67Z"
        fill="#8B44E5"
      />
      <path
        opacity={0.7}
        d="m167.696 83.848 1.171-15.995 10.413.72 1.232-5.124 2.52 1.055-5.271 18.673-10.065.67Z"
        fill="#fff"
      />
      <path
        d="m165.566 86.153 3.862-16.567h10.455l3.703-5.978-3.056 17.727-3.703 3.88-11.261.938Z"
        fill="#FFD04E"
      />
      <path
        opacity={0.2}
        d="m179.884 69.586-3.056 15.628 3.703-3.88 3.056-17.726-3.703 5.978Z"
        fill="#000"
      />
      <path
        d="M181.53 75.521s-1.763.61-2.007 1.635c-.244 1.025-.64 7.478-.64 7.478s2.733-.61 3.135-2.092c.403-1.482-.488-7.021-.488-7.021Z"
        fill="#EBB376"
      />
      <defs>
        <linearGradient
          id="a"
          x1={63.67}
          y1={5}
          x2={63.67}
          y2={225.37}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#32B4FF" />
          <stop offset={1} stopColor="#058FDE" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default SvgNotificationTelegram;
