import * as React from 'react';
import { SVGProps } from 'react';

const SvgNetworkTronIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={47}
      height={47}
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.5 47C36.479 47 47 36.479 47 23.5S36.479 0 23.5 0 0 10.521 0 23.5 10.521 47 23.5 47Z"
        fill="#0098EA"
      />
      <path
        d="M31.524 13.116H15.475c-2.95 0-4.82 3.183-3.336 5.756l9.904 17.168c.647 1.12 2.267 1.12 2.913 0l9.906-17.168c1.483-2.569-.39-5.756-3.338-5.756Zm-9.489 17.776-2.157-4.175-5.204-9.309a.91.91 0 0 1 .8-1.36h6.56v14.846l.001-.002Zm10.287-13.486-5.203 9.313-2.157 4.173V16.047h6.56a.91.91 0 0 1 .8 1.36Z"
        fill="#fff"
      />
    </svg>
  );
};
export default SvgNetworkTronIcon;
