import * as React from 'react';
import { SVGProps } from 'react';

const SvgErrorIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={16} cy={16} r={16} fill="#FF4949" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.255 8.263a.895.895 0 0 1 1.258-.01L16 14.581l6.488-6.328a.895.895 0 0 1 1.257.01.875.875 0 0 1-.01 1.246L17.08 16l6.655 6.491c.35.342.354.9.01 1.246a.895.895 0 0 1-1.258.01L16 17.419l-6.487 6.328a.895.895 0 0 1-1.258-.01.875.875 0 0 1 .01-1.246L14.92 16 8.265 9.509a.875.875 0 0 1-.01-1.246Z"
        fill="#fff"
      />
    </svg>
  );
};
export default SvgErrorIcon;
