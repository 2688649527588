import { keyframes } from 'styled-components';

export const show = keyframes`
  0% {opacity: 0;}
  100% {opacity: 1;}
`;

export const hide = keyframes`
  0% {opacity: 1;}
  100% {opacity: 0; visibility: hidden;}
`;

export const dropDownShow = keyframes`
  0% {
    transform: translateY(-40px);
    height: 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    height: auto;
    opacity: 1;
  }
`;

export const dropDownHide = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-40px);
    opacity: 0;
    visibility: hidden;
  }
`;

export const sidebarRightShow = keyframes`
  0% {
    transform: translateX(120px);
    width: 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    width: auto;
    opacity: 1;
  }
`;

export const sidebarRightClose = keyframes`
  0% {
    transform: translateX(0);
    width: auto;
    opacity: 1;
  }
  100% {
    transform: translateX(120px);
    width: 0;
    opacity: 0;
    visibility: hidden;
  }
`;

export const sidebarLeftShow = keyframes`
  0% {
    transform: translateX(-120px);
    width: 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    width: auto;
    opacity: 1;
  }
`;

export const sidebarLeftClose = keyframes`
  0% {
    transform: translateX(0);
    width: auto;
    opacity: 1;
  }
  100% {
    transform: translateX(-120px);
    width: 0;
    opacity: 0;
    visibility: hidden;
  }
`;

export const globalNotificationShow = keyframes`
0%{
  transform: translateX(80%) scale(1.05);
  opacity: 0;
}
80%{
  transform: translateX(0) scale(1.05);
  opacity: 1;
}
100%{
  transform: scale(1);
}
`;

export const accordionItemShow = keyframes`
0%{
  height: 0;
  opacity: 0;
}
100%{
  height: 100%;
  opacity: 1;
}
`;

export const loading = keyframes`
0%{
  width: 1%;
}
100%{
  width: 100%;
}
`;

export const widgetAppearanceAnimation = keyframes`
  0% {
    transform: translateX(48px);
}
  100% {
    transform: translateX(-4px);
}
`;

export const widgetDisappearanceAnimation = keyframes`
  0% {
    transform: translateX(-4px);
}
  100% {
    transform: translateX(86px);
}
`;

export const gelatineAnimation = keyframes`
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(0.9, 1.1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  75% {
    transform: scale(0.95, 1.05);
  }
  100% {
    transform: scale(1, 1);
  }
`;

export const pulseAppearAnimation = keyframes`
  0% {transform: scale(0.8, 0.8);}
  50% {transform: scale(1.1, 1.1);}
  100% {transform: scale(1, 1);}
`;
