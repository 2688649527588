import React, { FC } from 'react';

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useNavigation } from 'react-navi';

import { Button } from 'components/UI/Button';
import { Icon } from 'components/UI/Icon';

import { ScErrorBlock, ScErrorBlockContent } from './styled';

export interface ErrorBlockProps {
  text?: string | ((t: TFunction) => string);
  refresh?: boolean;
  inline?: boolean;
  hardRefresh?: boolean;
}

interface ButtonRefreshProps {
  onClick: () => void;
}

export const ErrorBlock: FC<ErrorBlockProps> = ({
  text,
  inline,
  refresh = true,
  hardRefresh = false,
}) => {
  const navigation = useNavigation();

  const { t } = useTranslation();

  let errorText;

  if (typeof text == 'function') {
    errorText = text(t);
  } else if (typeof text == 'string') {
    errorText = text;
  } else {
    errorText = t('common:error.unknown');
  }

  const ButtonRefresh: FC<ButtonRefreshProps> = ({ onClick }) => (
    <Button onClick={onClick}>{t('common:glossary.refresh')}</Button>
  );

  const content = (
    <>
      <Icon name="NotFoundIcon" />

      <p>{errorText}</p>

      {refresh && <ButtonRefresh onClick={() => navigation.refresh()} />}

      {hardRefresh && (
        <ButtonRefresh onClick={() => document.location.reload()} />
      )}
    </>
  );

  return inline ? (
    <ScErrorBlockContent>{content}</ScErrorBlockContent>
  ) : (
    <ScErrorBlock>{content}</ScErrorBlock>
  );
};
