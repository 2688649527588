import { FC, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useCurrentRoute } from 'react-navi';

import { useTheme } from 'hooks/theme';

const TitleSetter: FC = () => {
  const { title } = useCurrentRoute();

  const { defaultTitle } = useTheme();

  const { t } = useTranslation();

  useEffect(() => {
    if (typeof title === 'function') {
      document.title = title(t);
    } else if (title != null) {
      document.title = title;
    } else {
      document.title = defaultTitle;
    }
  }, [title, defaultTitle, t]);

  return null;
};

export default TitleSetter;
