import verifiedIcon from 'assets/svg/verified_icon.svg';
import styled from 'styled-components/macro';

export const ScUserInitials = styled.div<{
  verified?: boolean;
  blockBg?: boolean;
}>`
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  position: relative;
  text-align: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.button.fill.primaryBg};
  font-weight: 700;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.text.inverse};
  &::before {
    content: '';
    display: ${({ verified }) => (verified === false ? 'static' : 'none')};
    border-radius: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 8px;
    width: 8px;
    border: 2px solid
      ${({ theme, blockBg }) =>
        blockBg
          ? theme.colors.layer.layer01
          : theme.colors.background.foreground};
    background-color: #ef4444;
  }
  &::after {
    content: url(${verifiedIcon});
    display: ${({ verified }) => (verified === true ? 'static' : 'none')};
    border-radius: 100%;
    position: absolute;
    bottom: 0;
    right: -8px;
    height: 20px;
    width: 20px;
  }
  @media (max-width: ${({ theme }) => theme.mobileSize}) {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    line-height: 32px;
    font-size: 14px;
  }
`;
