import * as React from 'react';
import { SVGProps } from 'react';

const SvgCrystals1X = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={81}
      height={76}
      viewBox="0 0 81 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m65.553 8.188-.122-1.837a7.389 7.389 0 0 0-5.359-2.316L20.892 4a7.36 7.36 0 0 0-5.356 2.306l.002 1.837L25.674 24.92l31.267.028 8.612-16.76Z"
        fill="#C3DDFF"
      />
      <path
        d="M20.891 4a7.36 7.36 0 0 0-5.355 2.306l.002 1.837 5.095 8.433 8.754-12.568L20.891 4Z"
        fill="#A4CCFF"
      />
      <path
        d="M40.481 4.018 26.42 24.92l.879.862 25.864.023 1.419-.86-14.1-20.927ZM15.536 6.306 1.483 21.158A5.431 5.431 0 0 0 0 24.896l.932.862 24.02.021 1.467-.859L15.536 6.306Z"
        fill="#DBEAFF"
      />
      <path
        d="m14.828 24.91 6.298-9.043-5.59-9.56L1.483 21.157A5.431 5.431 0 0 0 0 24.896l.932.862 14.34.013-.444-.861Z"
        fill="#C3DDFF"
      />
      <path
        d="M81 24.97a5.45 5.45 0 0 0-1.49-3.742L65.431 6.351l-10.85 18.595 1.08.861 24.63.022.709-.86Z"
        fill="#DBEAFF"
      />
      <path
        d="M0 24.896a6.605 6.605 0 0 0 1.565 4.265l33.2 39.153a7.553 7.553 0 0 0 11.545.01l33.132-39.093A6.58 6.58 0 0 0 81 24.969l-81-.073Z"
        fill="#A4CCFF"
      />
      <path
        d="M14.828 24.91 0 24.896a6.605 6.605 0 0 0 1.565 4.265l33.2 39.153a7.602 7.602 0 0 0 3.691 2.39L14.828 24.91Z"
        fill="#8BB3EA"
      />
      <path
        d="M40.54 71c.713 0 1.414-.1 2.085-.293L54.58 24.946l-28.162-.026 12.037 45.784A7.555 7.555 0 0 0 40.54 71Z"
        fill="#C3DDFF"
      />
    </svg>
  );
};
export default SvgCrystals1X;
