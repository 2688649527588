import * as React from 'react';
import { SVGProps } from 'react';

const SvgRedirectIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={12}
      height={12}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.333 12c-.366 0-.68-.13-.942-.392A1.281 1.281 0 0 1 0 10.667V1.333C0 .967.13.653.392.391.653.13.967 0 1.333 0H6v1.333H1.333v9.334h9.334V6H12v4.667c0 .366-.13.68-.392.942a1.282 1.282 0 0 1-.941.391H1.333Zm3.134-3.533-.934-.934 6.2-6.2h-2.4V0H12v4.667h-1.333v-2.4l-6.2 6.2Z"
        fill="#000"
      />
    </svg>
  );
};
export default SvgRedirectIcon;
