import * as React from 'react';
import { SVGProps } from 'react';

const SvgAlertSevereIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={30}
      height={30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m27.586 14.957-8.984-.112s.346 2.044-1.859 3.22l4.528 7.83s6.628-3.306 6.315-10.938ZM16.782 11.957 21.3 4.121s-6.177-4.087-12.63 0l4.394 7.836s1.598-1.32 3.718 0ZM11.474 14.978l-9.045.006s-.451 7.392 6.315 10.938l4.59-7.724s-1.944-.723-1.86-3.22Z"
        fill="#DC2626"
      />
    </svg>
  );
};
export default SvgAlertSevereIcon;
