import * as React from 'react';
import { SVGProps } from 'react';

const SvgInfoIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 18.334a8.333 8.333 0 1 1 0-16.667 8.333 8.333 0 0 1 0 16.667Zm0-1.667a6.667 6.667 0 1 0 0-13.333 6.667 6.667 0 0 0 0 13.333ZM9.165 5.834h1.667V7.5H9.166V5.834Zm0 3.333h1.667v5H9.166v-5Z"
        fill="#3B82F6"
      />
    </svg>
  );
};
export default SvgInfoIcon;
