import React, { FC } from 'react';

import { getLoggedEmail } from 'helpers/auth';

import { ScUserInitials } from './styled';

export const UserInitials: FC<{ verified?: boolean; blockBg?: boolean }> = ({
  verified,
  blockBg,
}) => {
  const loggedEmail = getLoggedEmail();
  const avatarLetters = loggedEmail && loggedEmail.slice(0, 2).toUpperCase();

  return (
    <ScUserInitials verified={verified} blockBg={blockBg}>
      {avatarLetters}
    </ScUserInitials>
  );
};
