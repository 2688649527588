import * as React from 'react';
import { SVGProps } from 'react';

const SvgSwitcherBadge = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Z"
        fill="#fff"
      />
      <path
        d="m7 9.586 4.596-4.597.707.708L7 11 3.818 7.818l.707-.707L7 9.586Z"
        fill="#FD9A07"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m7 9.586 4.596-4.597.707.708L7 11 3.818 7.818l.707-.707L7 9.586Zm0-.566 4.596-4.596 1.273 1.272L7 11.566 3.252 7.818l1.273-1.273L7 9.02Z"
        fill="#FD9A07"
      />
    </svg>
  );
};
export default SvgSwitcherBadge;
