import * as React from 'react';
import { SVGProps } from 'react';

const SvgVerifyFactor4X = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={34}
      height={35}
      viewBox="0 0 34 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={17} cy={17.91} r={16.5} fill="#94A3B8" stroke="#94A3B8" />
      <path
        d="m31.966 11.76-9.593-1.394-4.289-8.693a1.202 1.202 0 0 0-.547-.548 1.214 1.214 0 0 0-1.621.548l-4.289 8.693-9.593 1.395A1.206 1.206 0 0 0 1 12.972c.004.321.136.628.366.851l6.94 6.767-1.64 9.556a1.207 1.207 0 0 0 1.754 1.273L17 26.908l8.58 4.51a1.207 1.207 0 0 0 1.753-1.273l-1.64-9.555 6.942-6.767c.188-.185.313-.427.35-.69a1.205 1.205 0 0 0-1.02-1.372Z"
        fill="#fff"
        fillOpacity={0.4}
      />
      <path
        d="m12.487 18.054 1.664-2.56h2.464l-2.88 4.048c.64.8 1.222 1.579 1.744 2.336.523.757.94 1.435 1.248 2.032h-2.56c-.085-.16-.192-.357-.32-.592a11.183 11.183 0 0 0-.432-.752 12.3 12.3 0 0 0-.512-.784 8.38 8.38 0 0 0-.512-.72c-.16.203-.325.427-.496.672-.16.245-.314.496-.464.752l-.448.736c-.138.245-.26.475-.368.688H8.183c.128-.267.304-.581.528-.944a29.113 29.113 0 0 1 1.552-2.288c.288-.395.571-.763.848-1.104-.48-.672-.96-1.35-1.44-2.032-.48-.693-.954-1.376-1.424-2.048h2.576l1.664 2.56Zm9.5-2.32a110.59 110.59 0 0 0-1.328 1.776c-.47.64-.864 1.296-1.184 1.968h2.512v-3.744Zm2.336-2.912v6.656h1.184v1.952h-1.184v2.48h-2.336v-2.48h-4.784v-1.744c.235-.47.528-.997.88-1.584a30.44 30.44 0 0 1 2.544-3.664c.48-.597.96-1.136 1.44-1.616h2.256Z"
        fill="#2D3344"
      />
    </svg>
  );
};
export default SvgVerifyFactor4X;
