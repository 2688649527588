import { RequestWithRecaptcha, factory } from 'api/base';

import { snakify } from 'helpers/api';

export interface LoginCredentials extends RequestWithRecaptcha {
  email: string;
  password: string;
}

export enum LoginError {
  INVALID_CREDENTIALS = 1,
  ACCOUNT_IS_BLOCKED = 2,
  RECAPTCHA_ERROR = 3,
  GA_REQUIRED = 4,
}

export const login = factory(
  (r) => (credentials: LoginCredentials) =>
    r<null>('post', '/login', snakify(credentials))
);

export const logout = factory((r) => () => r<null>('delete', '/logout'));

export interface RegistrationParams extends RequestWithRecaptcha {
  email: string;
  refer_id?: number;
  lang?: string;
}

export enum RegistrationError {
  EMAIL_ALREADY_TAKEN = 1,
  RECAPTCHA_ERROR = 2,
}

export const registration = factory(
  (r) => (params: RegistrationParams) =>
    r<null>('post', '/user', snakify(params))
);

export interface RecoveryParams extends RequestWithRecaptcha {
  email: string;
}

export enum RecoveryError {
  INVALID_EMAIL = 1,
  RECAPTCHA_ERROR = 3,
}

export const recovery = factory(
  (r) => (params: RecoveryParams) =>
    r<null>('post', '/user/recover', snakify(params))
);

export interface PerformRecoveryParams {
  token: string;
  password: string;
}

export enum PerformRecoveryError {
  INVALID_TOKEN = 2,
}

export const performRecovery = factory(
  (r) => (params: PerformRecoveryParams) =>
    r<null>('put', '/user/recover', params)
);

export const changeEmail = factory((r) => () => r<null>('post', '/user/email'));

export interface PerformChangeEmailParams {
  token: string;
  email: string;
}

export enum PerformChangeEmailError {
  INVALID_TOKEN = 0,
  ALREADY_EXISTS = 1,
}

export const performChangeEmail = factory(
  (r) => (params: PerformChangeEmailParams) =>
    r<null>('put', '/user/email', params)
);

export enum TwoFactorMethod {
  GCODE = 0,
}

export enum TwoFactorAction {
  LOGIN = 0,
}

export enum TwoFactorError {
  ALREADY_ACTIVE = 0,
  INVALID_CONFIRMATION_DATA = 1,
}

export interface AddTwoFactorParams {
  method: TwoFactorMethod;
  actions: TwoFactorAction[];
  gcode: string;
  gsecret: string;
}

export const addTwoFactor = factory(
  (r) => (params: AddTwoFactorParams) =>
    r<null>('put', '/user/auth/method', params)
);

export interface DeleteTwoFactorParams {
  gcode: string;
}

export const deleteTwoFactor = factory(
  (r) => (params: DeleteTwoFactorParams) =>
    r<null>('delete', '/user/auth/method', params)
);

export interface CheckTwoFactorParams extends LoginCredentials {
  gcode: string;
}

export enum CheckTwoFactorError {
  INVALID_CREDENTIALS = 1,
  ACCOUNT_IS_BLOCKED = 2,
  RECAPTCHA_ERROR = 3,
  INVALID_CODE = 4,
  NOT_ENABLED = 5,
}

export const checkTwoFactor = factory(
  (r) => (params: CheckTwoFactorParams) => r<null>('post', '/login/2f', params)
);
