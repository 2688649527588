import React, { Fragment } from 'react';

import { SiteAssistantPagePath } from './types';

type SiteAssistantPageContent = {
  [key in SiteAssistantPagePath]: {
    title: string;
    content: React.ReactNode;
  };
};

export const siteAssistantPageContent: SiteAssistantPageContent = {
  [SiteAssistantPagePath.EXCHANGE]: {
    title: 'Exchange',
    content: (
      <Fragment>
        <p>
          <strong>CryptoEx</strong> is a service for exchanging crypto and fiat
          currencies at current rates, monitoring exchange rates, verifying
          wallets, with support for a customizable affiliate program and
          extensive options for currency selection.
        </p>
        <br />
        <p>
          Any payment system or exchange that provides an API for such
          interaction can be connected. The service has already implemented the
          logic of working with a number of popular blockchains, so when
          exchanging currencies, you can work directly with them without paying
          the commission of an exchange or external payment system. Supported
          blockchains are constantly expanding, for example, it is already
          possible to work with: Bitcoin, Litecoin, Ethereum, Tron, BSC, TON,
          Polygon and others.
        </p>
        <br />
        <p>
          <strong>Sell</strong> - Select the cryptocurrency you want to sell:
          TRX, ETH, BNB, BTC, LTC, BSC, USDT ERC20, USDT BEP20, USDT TRC20, TON
          and others. The system implements the logic of working with the listed
          blockchains. It is also possible to connect payment systems for
          payments in fiat.
        </p>
        <br />
        <p>
          <strong>Buy</strong> - Select the currency you want to buy: Card USD,
          Card EUR, Ethereum ETH, BSC BNB, Tron TRX, Bitcoin BTC, Litecoin LTC,
          Polygon MATIC, and others. It is possible to integrate any payment
          system and its currencies.
        </p>
        <br />
        <p>
          <strong>Your email</strong> - Enter your email address to confirm the
          transaction. Filled in automatically for logged in users.
        </p>
        <p>
          <strong>Card number</strong> - Enter your card number to complete the
          transaction. This field depends on the output method. In addition to
          the card number, it can be the address in the blockchain, id of the
          account on the exchange, etc.
        </p>
        <br />
        <p>
          <strong>Exchange</strong> - Click to exchange.
        </p>
      </Fragment>
    ),
  },
  [SiteAssistantPagePath.LOGIN]: {
    title: 'Sign In',
    content: (
      <Fragment>
        <p>
          <strong>CryptoEx</strong> is a service for exchanging crypto and fiat
          currencies at current rates, monitoring exchange rates, verifying
          wallets, with support for a customizable affiliate program and
          extensive options for currency selection.
        </p>
        <br />
        <p>
          <strong>Email</strong> - Enter your login (email address) from the
          registration email you received.
        </p>
        <br />
        <p>
          <strong>Password</strong> - Enter your password from the registration
          email you received.
        </p>
        <br />
        <p>
          <strong>No account? Create one!</strong> - If you don't have an
          account, click "Create one!" to register.
        </p>
        <p>
          <strong>Sign In</strong> - Click to sign in to your account.
        </p>
        <br />
        <p>
          <strong>Already have an account?</strong> - Already have an account?
          Click "Sign in" to log in.
        </p>
      </Fragment>
    ),
  },
  [SiteAssistantPagePath.REGISTRATION]: {
    title: 'Sign Up',
    content: (
      <Fragment>
        <p>
          <strong>CryptoEx</strong> is a service for exchanging crypto and fiat
          currencies at current rates, monitoring exchange rates, verifying
          wallets, with support for a customizable affiliate program and
          extensive options for currency selection.
        </p>
        <br />
        <p>
          <strong>Email</strong> - Enter your login (email address) from the
          registration email you received.
        </p>
        <br />
        <p>
          <strong>Password</strong> - Enter your password from the registration
          email you received.
        </p>
        <br />
        <p>
          <strong>No account? Create one!</strong> - If you don't have an
          account, click "Create one!" to register.
        </p>
        <p>
          <strong>Sign In</strong> - Click to sign in to your account.
        </p>
        <br />
        <p>
          <strong>Already have an account?</strong> - Already have an account?
          Click "Sign in" to log in.
        </p>
      </Fragment>
    ),
  },
  [SiteAssistantPagePath.RECOVERY_INIT]: {
    title: 'Password recovery',
    content: (
      <Fragment>
        <p>
          <strong>CryptoEx</strong> is a service for exchanging crypto and fiat
          currencies at current rates, monitoring exchange rates, verifying
          wallets, with support for a customizable affiliate program and
          extensive options for currency selection.
        </p>
        <br />
        <p>
          <strong>Email</strong> - Enter your login (email address) from the
          registration email you received.
        </p>
        <br />
        <p>
          <strong>Password</strong> - Enter your password from the registration
          email you received.
        </p>
        <br />
        <p>
          <strong>No account? Create one!</strong> - If you don't have an
          account, click "Create one!" to register.
        </p>
        <p>
          <strong>Sign In</strong> - Click to sign in to your account.
        </p>
        <br />
        <p>
          <strong>Already have an account?</strong> - Already have an account?
          Click "Sign in" to log in.
        </p>
      </Fragment>
    ),
  },
  [SiteAssistantPagePath.EXCHANGE_PAY]: {
    title: 'Exchange process',
    content: (
      <Fragment>
        <p>
          <strong>CryptoEx</strong> is a service for exchanging crypto and fiat
          currencies at current rates, monitoring exchange rates, verifying
          wallets, with support for a customizable affiliate program and
          extensive options for currency selection.{' '}
        </p>
        <br />
        <p>
          The “Exchange Process” page provides users with a step-by-step
          interface to complete a cryptocurrency exchange transaction. Each
          transaction goes through AML verification, the service provides
          flexible risk checking customization, allowing you to configure the
          threshold of critical content of risky funds.
        </p>
      </Fragment>
    ),
  },
  [SiteAssistantPagePath.BONUSES]: {
    title: 'Bonuses',
    content: (
      <Fragment>
        <p>
          <strong>CryptoEx</strong> is a service for exchanging crypto and fiat
          currencies at current rates, monitoring exchange rates, verifying
          wallets, with support for a customizable affiliate program and
          extensive options for currency selection.
        </p>
        <br />
        <p>
          The Bonuses page details the tiered bonus program available to users
          based on their transaction turnover. This page outlines the various
          levels — Bronze, Silver, Gold, Platinum, and Diamond — each offering
          increasing bonuses and additional benefits as users progress through
          the tiers. The values and levels are customizable through the admin
          panel, allowing flexibility in tailoring the bonus program to meet
          specific needs.
        </p>
        <br />
        <p>
          <strong>Bronze, Silver, Gold, Platinum, Diamond</strong>: Display of
          tiers with corresponding turnover ranges, bonus multipliers, and
          additional benefits.
        </p>
        <br />
        <p>
          <strong>More details</strong>: Button to access further information
          about the bonus program and its specific benefits.
        </p>
      </Fragment>
    ),
  },
  [SiteAssistantPagePath.RESERVES]: {
    title: 'Reserves',
    content: (
      <Fragment>
        <p>
          <strong>CryptoEx</strong> is a service for exchanging crypto and fiat
          currencies at current rates, monitoring exchange rates, verifying
          wallets, with support for a customizable affiliate program and
          extensive options for currency selection.
        </p>
        <br />
        <p>
          The Reserves page provides an overview of the available reserves for
          different cryptocurrencies and fiat currencies on the platform. This
          page allows users to see the current amounts of each currency that the
          platform holds. It also highlights the platform's capability to handle
          transactions across various digital assets and fiat currencies.{' '}
        </p>
        <br />
        <p>
          <strong>Currency Tabs (CRST, UAH, USD, CRYPTO)</strong>: Tabs to view
          the reserves for specific currencies or categories of assets.
        </p>
        <br />
        <p>
          <strong>Reserve Amounts</strong>: Displays the exact amount of each
          currency (e.g., 100,000 TON TON, 26,295.68 TRX Tron, etc.).
        </p>
      </Fragment>
    ),
  },
  [SiteAssistantPagePath.PARTNERS_INFO]: {
    title: 'For partners (Affiliate program)',
    content: (
      <Fragment>
        <p>
          <strong>CryptoEx</strong> is a service for exchanging crypto and fiat
          currencies at current rates, monitoring exchange rates, verifying
          wallets, with support for a customizable affiliate program and
          extensive options for currency selection.
        </p>
        <br />
        <p>
          The Affiliate Program page invites users to participate in the
          platform's affiliate program, allowing them to earn commissions by
          referring others to use the exchange service. This page provides all
          necessary details about the program, including potential rewards,
          methods to attract users, and the mechanics of earning and withdrawing
          commissions. It is designed to help users easily manage their
          affiliate activities, track earnings, and understand the benefits of
          participating in the program.
        </p>
        <br />
        <p>
          <strong>Information</strong>: Access detailed information about the
          affiliate program, including benefits, commission structures, and how
          to get started.
        </p>
        <br />
        <p>
          <strong>Withdraw</strong>: Request withdrawals of your earned
          commissions.
        </p>
        <br />
        <p>
          <strong>Operations</strong>: Track your affiliate operations.
        </p>
      </Fragment>
    ),
  },
  [SiteAssistantPagePath.PROFILE_PARTNERS]: {
    title: 'For partners (Affiliate program)',
    content: (
      <Fragment>
        <p>
          <strong>CryptoEx</strong> is a service for exchanging crypto and fiat
          currencies at current rates, monitoring exchange rates, verifying
          wallets, with support for a customizable affiliate program and
          extensive options for currency selection.
        </p>
        <br />
        <p>
          The Affiliate Program page invites users to participate in the
          platform's affiliate program, allowing them to earn commissions by
          referring others to use the exchange service. This page provides all
          necessary details about the program, including potential rewards,
          methods to attract users, and the mechanics of earning and withdrawing
          commissions. It is designed to help users easily manage their
          affiliate activities, track earnings, and understand the benefits of
          participating in the program.
        </p>
        <br />
        <p>
          <strong>Information</strong>: Access detailed information about the
          affiliate program, including benefits, commission structures, and how
          to get started.
        </p>
        <br />
        <p>
          <strong>Withdraw</strong>: Request withdrawals of your earned
          commissions.
        </p>
        <br />
        <p>
          <strong>Operations</strong>: Track your affiliate operations.
        </p>
      </Fragment>
    ),
  },
  [SiteAssistantPagePath.FAQ]: {
    title: 'FAQ',
    content: (
      <Fragment>
        <p>
          <strong>CryptoEx</strong> is a service for exchanging crypto and fiat
          currencies at current rates, monitoring exchange rates, verifying
          wallets, with support for a customizable affiliate program and
          extensive options for currency selection.
        </p>
        <br />
        <p>
          The FAQ content can be easily edited and updated through the admin
          panel, providing instant updates.
        </p>
      </Fragment>
    ),
  },
  [SiteAssistantPagePath.DASHBOARD]: {
    title: 'Control Center',
    content: (
      <Fragment>
        <p>
          <strong>CryptoEx</strong> s a service for exchanging crypto and fiat
          currencies at current rates, monitoring exchange rates, verifying
          wallets, with support for a customizable affiliate program and
          extensive options for currency selection.
        </p>
        <br />
        <p>
          The “Control Center” page serves as a central point for users to
          manage and control all aspects of their account. This page provides a
          complete overview of key information such as bonus balance, number of
          available AML checks, status and turnover, and recent exchanges. Users
          can also set default payment destinations and save details to simplify
          future exchanges. The page is designed for quick access to the main
          account features.
        </p>
        <br />
        <p>
          <strong>Use bonuses</strong> - Use your accumulated bonuses.
        </p>
        <br />
        <p>
          <strong>Check address for risks</strong> - Check the address for
          risks.
        </p>
        <br />
        <p>
          <strong>Status and turnover</strong> - Shows the user's current status
          (e.g., Bronze) and tracks turnover towards the next tier.
        </p>
        <br />
        <p>
          <strong>Selected payment direction</strong> - Allows users to add or
          set their preferred exchange direction, which will be applied by
          default in the main page
        </p>
        <br />
        <p>
          <strong>Saved requisites</strong> - Option to add and save details for
          faster exchanges.
        </p>
        <br />
        <p>
          <strong>Last exchanges</strong> - Displays a list of the user's most
          recent exchanges, with links to track the status of each transaction.
        </p>
      </Fragment>
    ),
  },
  [SiteAssistantPagePath.PERSONAL_INFO]: {
    title: 'Personal info',
    content: (
      <Fragment>
        <p>
          <strong>CryptoEx</strong> is a service for exchanging crypto and fiat
          currencies at current rates, monitoring exchange rates, verifying
          wallets, with support for a customizable affiliate program and
          extensive options for currency selection.
        </p>
        <br />
        <p>
          The Personal Info page allows users to manage their personal
          information, security settings, and view KYC level. Users can update
          their contact details, enhance account security with two-factor
          authentication, and complete KYC verification to access additional
          platform benefits.
        </p>
        <br />
        <p>
          The service provides a complete platform for automatic verification.
          On request, it can also work with any other service to provide similar
          services.
        </p>
        <br />
        <p>
          <strong>Level KYC</strong> - Displays the user's current KYC status
          and provides options for starting or upgrading KYC verification.
        </p>
        <br />
        <p>
          <strong>Verify</strong> - Button to initiate the KYC verification
          process.
        </p>
        <br />
        <p>
          <strong>Contacts Change</strong> - Change your contact information.
        </p>
        <br />
        <p>
          <strong>Contacts (Change)</strong> - Option to update personal contact
          information such as Last Name, First Name, Middle Name, Phone number,
          and Telegram.
        </p>
        <br />
        <p>
          <strong>Sign-in email</strong> - Displays the current email address
          used for sign-in with an option to change it.
        </p>
        <br />
        <p>
          <strong>Sign-in password</strong> - Button to change the sign-in
          password.
        </p>
        <br />
        <p>
          <strong>Two-factor authentication</strong> - Option to enable
          two-factor authentication to improve account security.
        </p>
      </Fragment>
    ),
  },
  [SiteAssistantPagePath.EXCHANGE_BIDS]: {
    title: 'Exchanges',
    content: (
      <Fragment>
        <p>
          <strong>CryptoEx</strong> is a service for exchanging crypto and fiat
          currencies at current rates, monitoring exchange rates, verifying
          wallets, with support for a customizable affiliate program and
          extensive options for currency selection.
        </p>
        <br />
        <p>
          The Exchanges page provides users with a detailed overview of their
          past and current exchange transactions.
        </p>
        <p>
          <strong>Your bids</strong>: Displays a list of all exchange
          transactions made by the user, including bid number, status, date, and
          the details of what was exchanged.
        </p>
        <br />
        <p>
          <strong>Track</strong>: Link to blockchain scanner for that
          transaction
        </p>
        <br />
        <p>
          <strong>Status</strong>: Indicates whether the exchange is open,
          closed, or in another state of completion
        </p>
        <br />
        <p>
          <strong>Date</strong>: Shows the date and time when the exchange was
          initiated.
        </p>
        <br />
        <p>
          <strong>You give / You get</strong>: Displays the specific amounts and
          types of currency exchanged in each transaction.
        </p>
      </Fragment>
    ),
  },
  [SiteAssistantPagePath.CRYPTO_CHECK]: {
    title: 'Crypto Check',
    content: (
      <Fragment>
        <p>
          <strong>CryptoEx</strong> is a service for exchanging crypto and fiat
          currencies at current rates, monitoring exchange rates, verifying
          wallets, with support for a customizable affiliate program and
          extensive options for currency selection.
        </p>
        <br />
        <p>
          The Crypto Check page allows users to perform Anti-Money Laundering
          (AML) verification on their cryptocurrency assets. This verification
          is crucial to ensure that the cryptocurrency you hold has not been
          associated with illegal activities.
        </p>
        <br />
        <p>
          <strong>Currency</strong> - Dropdown menu to select the cryptocurrency
          you want to verify (e.g., Tron TRX).
        </p>
        <br />
        <p>
          <strong>Wallet address</strong> - Input field to enter the blockchain
          address you want to check.
        </p>
        <br />
        <p>
          <strong>Balance</strong> - Displays the available checks for use.
        </p>
        <br />
        <p>
          <strong>Check</strong> - Button to initiate the AML check on the
          selected cryptocurrency (becomes active when crystals are available).
        </p>
        <br />
        <p>
          <strong>Buy crystals</strong> - Click to buy AML checks.
        </p>
      </Fragment>
    ),
  },
  [SiteAssistantPagePath.PROFILE_BONUSES]: {
    title: 'My bonus',
    content: (
      <Fragment>
        <p>
          <strong>CryptoEx</strong> is a service for exchanging crypto and fiat
          currencies at current rates, monitoring exchange rates, verifying
          wallets, with support for a customizable affiliate program and
          extensive options for currency selection.
        </p>
        <br />
        <p>
          The My Bonus page provides users with a comprehensive overview of
          their bonus balance, and possibility to withdraw it.
        </p>
        <br />
        <p>
          <strong>Bonus balance</strong>: Displays the current bonus points
          available and provides a button to withdraw bonuses.
        </p>
        <br />
        <p>
          <strong>Status and turnover</strong>: Shows the user's current status
          (e.g., Bronze) and tracks turnover towards the next tier with an
          option to view more details.
        </p>
        <br />
        <p>
          <strong>History</strong>: Section where users can view their bonus
          withdrawal history.
        </p>
      </Fragment>
    ),
  },
  [SiteAssistantPagePath.PROFILE_SETTINGS]: {
    title: 'Exchange settings',
    content: (
      <Fragment>
        <p>
          <strong>CryptoEx</strong> is a service for exchanging crypto and fiat
          currencies at current rates, monitoring exchange rates, verifying
          wallets, with support for a customizable affiliate program and
          extensive options for currency selection.
        </p>
        <br />
        <p>
          The Exchange Settings page allows users to configure and manage their
          default exchange preferences and requisites.
        </p>
        <br />
        <p>
          <strong>Selected payment exchange direction</strong>: Displays the
          currently set default exchange direction (e.g., TRX to Card UAH) and
          provides an option to change it.
        </p>
        <br />
        <p>
          <strong>Change</strong>: Button to modify the default payment exchange
          direction.
        </p>
      </Fragment>
    ),
  },
  [SiteAssistantPagePath.API]: {
    title: 'API',
    content: (
      <Fragment>
        <p>
          <strong>CryptoEx</strong> is a service for exchanging crypto and fiat
          currencies at current rates, monitoring exchange rates, verifying
          wallets, with support for a customizable affiliate program and
          extensive options for currency selection.
        </p>
        <br />
        <p>
          On the API Management page, users have access to functionality that
          allows them to automate exchange processes on the platform. With the
          help of API, users can simplify such tasks as obtaining exchange
          rates, creating requests, monitoring transactions, etc. This page is
          necessary for users who want to integrate the platform's functionality
          into their own systems, which will allow them to perform exchanges
          almost instantly, without manual intervention.
        </p>
        <br />
        <p>
          <strong>Generate API key</strong>: Button to generate a new API key
        </p>
        <br />
        <p>
          <strong>API keys</strong>: Displays a list of generated API keys,
          including details such as name, status, creation date, and actions
          available for each key.
        </p>
        <br />
        <p>
          <strong>Read the API technical documentation</strong>: Link to the
          platform’s API documentation, providing technical details necessary
          for integration.
        </p>
      </Fragment>
    ),
  },
};

// Function to match the pathname against the enum values
export const matchPathname = (
  pathname: string
): SiteAssistantPagePath | undefined => {
  return (Object.values(SiteAssistantPagePath) as string[]).find((pattern) =>
    new RegExp(pattern).test(pathname)
  ) as SiteAssistantPagePath | undefined;
};
