import * as React from 'react';
import { SVGProps } from 'react';

const SvgCrystals20X = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={111}
      height={81}
      viewBox="0 0 111 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <path
            d="m61.669 18.061-6.802 3.495-8.2-3.495c-2.57-7.768-4.924-14.004-1.523-16.033 3.4-2.028 4.838 4.463 4.838 4.463s-.017-.851.166-1.935C50.47 2.643 51.413 0 54.168 0c4.316 0 4.184 6.491 4.184 6.491s1.44-6.491 4.84-4.463c3.4 2.029 1.047 8.265-1.523 16.033ZM84.974 64.391c0-17.138-13.793-38.34-30.807-38.34-17.013 0-30.805 21.202-30.805 38.34s13.792 16.565 30.805 16.565c17.014 0 30.807.573 30.807-16.565Z"
            fill="#FFD04E"
          />
          <path
            d="M52.31 80.8c-16.15.016-28.952-.055-28.952-16.566 0-17.138 13.792-38.34 30.805-38.34-8.38 0-15.172 21.202-15.172 38.34 0 15.854 5.814 16.55 13.32 16.565ZM51.892 17.903h-5.23c-2.57-7.769-4.924-14.005-1.522-16.033 3.4-2.028 4.837 4.463 4.837 4.463s.478 6.327 1.915 11.57Z"
            fill="#FD9A07"
          />
          <path
            d="M64.061 21.23v3.467c0 1.838-1.478 3.33-3.302 3.33h-13.19c-1.825 0-3.306-1.492-3.306-3.33V21.23c0-1.839 1.48-3.328 3.306-3.328h13.19c1.824 0 3.302 1.49 3.302 3.328Z"
            fill="#4588B5"
          />
          <path
            d="M64.061 21.23v3.467c0 1.838-1.478 3.33-3.303 3.33h-6.6c-1.825 0-3.303-1.492-3.303-3.33V21.23c0-1.839 1.478-3.328 3.303-3.328h6.6c1.825 0 3.303 1.49 3.303 3.328Z"
            fill="#6B9DC6"
          />
        </g>
        <path
          d="m25.204 54.192.42-.592a2.81 2.81 0 0 0-1.022-2.01l-11.686-9.52a2.798 2.798 0 0 0-2.172-.593l-.457.563-1.152 7.596 9.326 7.597 6.743-3.04Z"
          fill="#C3DDFF"
        />
        <path
          d="M12.916 42.07a2.798 2.798 0 0 0-2.172-.593l-.457.563-.579 3.818 5.742-1.723-2.534-2.065Z"
          fill="#A4CCFF"
        />
        <path
          d="m18.759 46.83-9.401 2.987.047.477 7.714 6.285.638.08 1.002-9.828ZM10.744 41.477l-7.892 1.136a2.174 2.174 0 0 0-1.374.785l.064.49 7.164 5.836.652.093 1.386-8.34Z"
          fill="#DBEAFF"
        />
        <path
          d="m5.9 47 4.132-1.24.712-4.283-7.892 1.136a2.174 2.174 0 0 0-1.374.785l.064.49 4.277 3.484.082-.371Z"
          fill="#C3DDFF"
        />
        <path
          d="M25.636 63.08c.346-.427.52-.967.487-1.508l-.498-7.972-7.868 3.06.108.526 7.345 5.984.426-.09Z"
          fill="#DBEAFF"
        />
        <path
          d="M1.478 43.398a2.647 2.647 0 0 0-.594 1.685l.161 20.046A2.888 2.888 0 0 0 2.1 67.352c.663.54 1.535.753 2.388.582l19.622-3.928a2.638 2.638 0 0 0 1.526-.927L1.478 43.398Z"
          fill="#A4CCFF"
        />
        <path
          d="m5.9 47-4.422-3.602a2.647 2.647 0 0 0-.594 1.685l.161 20.046c.005.592.185 1.156.507 1.627L5.9 47Z"
          fill="#8BB3EA"
        />
        <path
          d="M2.1 67.352c.213.173.447.313.695.417l14.962-11.11-8.4-6.842-7.805 16.939c.152.222.336.423.548.596Z"
          fill="#C3DDFF"
        />
        <path
          d="m29 46.124-.545-.479a2.81 2.81 0 0 0-2.104.812L15.683 57.105a2.798 2.798 0 0 0-.813 2.1l.513.512 7.438 1.924 8.513-8.497L29 46.124Z"
          fill="#C3DDFF"
        />
        <path
          d="M15.683 57.105a2.798 2.798 0 0 0-.813 2.1l.513.512 3.739.967-1.126-5.888-2.313 2.309Z"
          fill="#A4CCFF"
        />
        <path
          d="m21.017 51.781 2.007 9.658.48.001 7.041-7.029.147-.626-9.675-2.004Z"
          fill="#DBEAFF"
        />
        <path
          d="m14.87 59.205.321 7.967c.022.54.25 1.058.64 1.447l.494-.013 6.54-6.528.159-.64-8.154-2.233Z"
          fill="#DBEAFF"
        />
        <path
          d="m19.868 64.589-.81-4.236-4.188-1.148.321 7.967c.022.54.25 1.058.64 1.447l.494-.013 3.904-3.898-.36-.12Z"
          fill="#C3DDFF"
        />
        <path
          d="M37.885 46.605a2.181 2.181 0 0 0-1.45-.638l-7.98-.322 2.237 8.14.534-.053 6.706-6.694-.047-.433Z"
          fill="#DBEAFF"
        />
        <path
          d="M15.83 68.619c.437.435 1.01.706 1.616.764l19.957 1.894a2.888 2.888 0 0 0 2.32-.822c.605-.603.906-1.45.823-2.316L38.65 48.22a2.638 2.638 0 0 0-.765-1.614L15.83 68.62Z"
          fill="#A4CCFF"
        />
        <path
          d="m19.868 64.589-4.037 4.03c.436.435 1.009.706 1.615.764l19.957 1.894c.59.056 1.17-.065 1.67-.337l-19.205-6.351Z"
          fill="#8BB3EA"
        />
        <path
          d="M39.723 70.455c.194-.193.357-.412.485-.648l-9.516-16.022-7.668 7.654 16.05 9.5c.236-.128.455-.29.65-.484ZM97.88 59.392l.437-.497a2.558 2.558 0 0 0-.74-1.916l-9.695-9.714a2.548 2.548 0 0 0-1.913-.74l-.466.467-1.752 6.773 7.738 7.752 6.391-2.125Z"
          fill="#C3DDFF"
        />
        <path
          d="M87.882 47.265a2.548 2.548 0 0 0-1.913-.74l-.466.467-.88 3.404 5.36-1.025-2.102-2.106Z"
          fill="#A4CCFF"
        />
        <path
          d="m92.73 52.122-8.795 1.828-.001.436 6.4 6.413.57.133 1.826-8.81Z"
          fill="#DBEAFF"
        />
        <path
          d="m85.97 46.525-7.255.292a1.98 1.98 0 0 0-1.318.583l.012.45 5.945 5.955.581.145 2.034-7.425Z"
          fill="#DBEAFF"
        />
        <path
          d="m81.067 51.076 3.857-.737 1.045-3.814-7.254.292a1.98 1.98 0 0 0-1.318.583l.012.45 3.55 3.555.108-.329Z"
          fill="#C3DDFF"
        />
        <path
          d="M97.442 67.482c.354-.354.562-.827.582-1.32l.293-7.267-7.413 2.037.05.486 6.094 6.107.394-.043Z"
          fill="#DBEAFF"
        />
        <path
          d="M77.397 47.4a2.41 2.41 0 0 0-.696 1.47l-1.724 18.173c-.075.79.198 1.562.748 2.113.55.55 1.32.824 2.109.75l18.14-1.727a2.402 2.402 0 0 0 1.468-.697L77.397 47.4Z"
          fill="#A4CCFF"
        />
        <path
          d="m81.067 51.076-3.67-3.676a2.41 2.41 0 0 0-.696 1.47l-1.724 18.173c-.05.536.06 1.065.307 1.521l5.783-17.488Z"
          fill="#8BB3EA"
        />
        <path
          d="M75.725 69.156c.176.176.376.324.59.441l14.59-8.665-6.97-6.982-8.651 14.614c.117.215.265.415.441.592ZM107.646 63.064l.268-.305a1.57 1.57 0 0 0-.454-1.177l-5.955-5.966a1.567 1.567 0 0 0-1.175-.455l-.286.287-1.076 4.16 4.752 4.762 3.926-1.306Z"
          fill="#C3DDFF"
        />
        <path
          d="M101.505 55.616a1.567 1.567 0 0 0-1.175-.455l-.286.287-.541 2.091 3.293-.63-1.291-1.293Z"
          fill="#A4CCFF"
        />
        <path
          d="m104.482 58.599-5.401 1.123-.001.268 3.931 3.938.35.082 1.121-5.411ZM100.33 55.16l-4.456.18c-.302.013-.592.141-.809.358l.007.277 3.651 3.658.358.089 1.249-4.561Z"
          fill="#DBEAFF"
        />
        <path
          d="m97.319 57.957 2.37-.453.641-2.343-4.456.18c-.302.012-.592.14-.809.358l.007.276 2.18 2.184.067-.202Z"
          fill="#C3DDFF"
        />
        <path
          d="M107.377 68.033a1.22 1.22 0 0 0 .357-.81l.18-4.464-4.553 1.251.03.3 3.744 3.75.242-.027Z"
          fill="#DBEAFF"
        />
        <path
          d="M95.065 55.699a1.48 1.48 0 0 0-.427.903l-1.06 11.162c-.046.485.122.96.46 1.297.337.339.81.507 1.295.46l11.142-1.06c.339-.032.658-.184.902-.428L95.065 55.7Z"
          fill="#A4CCFF"
        />
        <path
          d="m97.319 57.957-2.254-2.258a1.48 1.48 0 0 0-.427.903l-1.06 11.162c-.03.33.037.654.189.934l3.552-10.741Z"
          fill="#8BB3EA"
        />
        <path
          d="M94.038 69.061c.108.109.23.2.363.272l8.96-5.323-4.28-4.288-5.314 8.976c.072.132.163.255.27.363Z"
          fill="#C3DDFF"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h111v81H0z" />
        </clipPath>
        <clipPath id="b">
          <path fill="#fff" transform="translate(21)" d="M0 0h64v65H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgCrystals20X;
