import * as React from 'react';
import { SVGProps } from 'react';

const SvgApiIllustration = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={470}
      height={336}
      viewBox="0 0 470 336"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M445.039 70.154a7.644 7.644 0 0 0-2.343-2.313 7.813 7.813 0 0 0-3.121-1.129l-33.294-4.494-11.96-30.115a7.586 7.586 0 0 0-1.865-2.736 7.716 7.716 0 0 0-2.869-1.697c-13.129-4.31-27.007-6.109-40.893-5.302a8.024 8.024 0 0 0-5.705 2.858l-21.611 25.937-33.507-4.255a7.98 7.98 0 0 0-3.25.259 8.028 8.028 0 0 0-2.883 1.536 110.83 110.83 0 0 0-26.257 31.839 7.715 7.715 0 0 0-.944 3.194 7.58 7.58 0 0 0 .493 3.27l11.724 30.24-21.31 26.005a7.832 7.832 0 0 0-1.516 2.955 7.643 7.643 0 0 0-.141 3.292 100.586 100.586 0 0 0 14.649 37.28c.601.93 1.4 1.719 2.343 2.313a7.802 7.802 0 0 0 3.121 1.129l33.294 4.494 11.963 30.124a7.583 7.583 0 0 0 1.865 2.736 7.705 7.705 0 0 0 2.868 1.697 110.585 110.585 0 0 0 40.894 5.302 8.007 8.007 0 0 0 5.705-2.858l21.621-25.993 33.506 4.255a7.9 7.9 0 0 0 3.922-.509 8.093 8.093 0 0 0 2.204-1.304 111.005 111.005 0 0 0 26.265-31.82 7.748 7.748 0 0 0 .944-3.194 7.582 7.582 0 0 0-.493-3.272l-11.724-30.239 21.31-26.005a7.824 7.824 0 0 0 1.516-2.955c.286-1.078.334-2.2.141-3.291a100.582 100.582 0 0 0-14.662-37.234Zm-79.196 94.17a39.906 39.906 0 0 1-22.716 1.965c-7.569-1.571-14.464-5.326-19.811-10.791a37.818 37.818 0 0 1-10.229-19.91c-1.323-7.517-.348-15.284 2.8-22.319 3.148-7.035 8.329-13.022 14.886-17.205a40.096 40.096 0 0 1 21.955-6.29c7.757.08 15.282 2.427 21.623 6.742a37.978 37.978 0 0 1 14.003 17.504c3.739 9.517 3.468 20.163-.753 29.596-4.222 9.434-12.048 16.883-21.758 20.708Z"
        fill="url(#a)"
      />
      <ellipse cx={240.203} cy={311.684} rx={210} ry={10.611} fill="url(#b)" />
      <g opacity={0.5}>
        <rect
          x={170.792}
          y={36.695}
          width={99.021}
          height={70.788}
          rx={6.662}
          fill="#D9D9D9"
        />
        <rect
          x={169.126}
          y={35.029}
          width={102.352}
          height={74.12}
          rx={8.328}
          stroke="#fff"
          strokeOpacity={0.3}
          strokeWidth={3.331}
        />
        <path
          d="M170.792 43.357a6.662 6.662 0 0 1 6.662-6.662h85.696a6.663 6.663 0 0 1 6.663 6.662v9.994h-99.021v-9.994Z"
          fill="#C9C9C9"
        />
        <rect
          x={256.499}
          y={41.691}
          width={6.657}
          height={6.662}
          rx={3.328}
          fill="#EEE"
        />
        <rect
          x={244.018}
          y={41.691}
          width={6.657}
          height={6.662}
          rx={3.328}
          fill="#EEE"
        />
        <rect
          x={231.536}
          y={41.691}
          width={6.657}
          height={6.662}
          rx={3.328}
          fill="#EEE"
        />
        <path fill="#C8C8C8" d="M177.448 60.013h14.978v3.331h-14.978z" />
        <path fill="#fff" d="M177.448 66.675h14.978v3.331h-14.978z" />
        <path
          fill="#C8C8C8"
          d="M177.448 76.669h14.978V80h-14.978zM249.01 60.013h14.978v3.331H249.01z"
        />
        <path fill="#fff" d="M249.01 89.994h14.978v3.331H249.01z" />
        <path
          fill="#CCC"
          d="M197.419 60.013h49.095v3.331h-49.095zM197.419 89.994h49.095v3.331h-49.095z"
        />
        <path fill="#C8C8C8" d="M197.419 66.675h25.795v3.331h-25.795z" />
        <path fill="#CCC" d="M197.419 76.669h31.62V80h-31.62z" />
        <path fill="#fff" d="M197.419 83.332h19.971v3.331h-19.971z" />
        <path fill="#C8C8C8" d="M197.419 96.657h19.971v3.331h-19.971z" />
        <path fill="#fff" d="M226.543 66.675h25.795v3.331h-25.795z" />
        <path
          fill="#C8C8C8"
          d="M230.704 76.669h32.452V80h-32.452zM220.719 83.332h19.971v3.331h-19.971z"
        />
        <path fill="#fff" d="M220.719 96.657h19.971v3.331h-19.971z" />
        <path fill="#CCC" d="M244.018 83.332h19.971v3.331h-19.971z" />
      </g>
      <g filter="url(#c)">
        <path fill="url(#d)" d="M170.792 75.6h99.032v7.074h-99.032z" />
      </g>
      <path
        d="M133.409 197.921a5.05 5.05 0 0 0-1.548-1.528 5.168 5.168 0 0 0-2.063-.746l-22.001-2.972-7.906-19.896a5.012 5.012 0 0 0-1.233-1.808 5.1 5.1 0 0 0-1.896-1.121 73.118 73.118 0 0 0-27.023-3.507 5.307 5.307 0 0 0-3.77 1.888l-14.278 17.132-22.142-2.814a5.295 5.295 0 0 0-4.053 1.185 73.2 73.2 0 0 0-17.348 21.031 5.112 5.112 0 0 0-.624 2.11c-.05.738.06 1.475.327 2.161l7.75 19.978-14.08 17.178a5.165 5.165 0 0 0-1.001 1.952 5.044 5.044 0 0 0-.093 2.174 66.459 66.459 0 0 0 9.684 24.631 5.052 5.052 0 0 0 1.549 1.528 5.166 5.166 0 0 0 2.062.746l22.002 2.972 7.908 19.902a5.012 5.012 0 0 0 1.233 1.808 5.1 5.1 0 0 0 1.895 1.121 73.118 73.118 0 0 0 27.024 3.507 5.307 5.307 0 0 0 3.77-1.888l14.284-17.169 22.142 2.814a5.221 5.221 0 0 0 2.592-.336 5.37 5.37 0 0 0 1.457-.861 73.314 73.314 0 0 0 17.352-21.019c.36-.653.573-1.373.624-2.11a5.017 5.017 0 0 0-.326-2.161l-7.751-19.978 14.08-17.178a5.17 5.17 0 0 0 1.001-1.952 5.037 5.037 0 0 0 .093-2.174 66.454 66.454 0 0 0-9.693-24.6Zm-52.324 62.204a26.366 26.366 0 0 1-15.01 1.296 25.57 25.57 0 0 1-13.093-7.131 24.985 24.985 0 0 1-6.762-13.154 25.33 25.33 0 0 1 1.849-14.744 26.186 26.186 0 0 1 9.835-11.365 26.495 26.495 0 0 1 14.507-4.153c5.126.054 10.099 1.604 14.29 4.456a25.1 25.1 0 0 1 9.255 11.565c2.472 6.287 2.294 13.321-.495 19.552-2.789 6.232-7.96 11.152-14.376 13.678Z"
        fill="url(#e)"
      />
      <rect
        x={100.944}
        y={81.346}
        width={271.105}
        height={180.888}
        rx={8.66}
        fill="#D1D5DB"
        stroke="url(#f)"
        strokeWidth={1.924}
      />
      <rect
        x={111.518}
        y={91.93}
        width={249.957}
        height={159.72}
        rx={1.924}
        fill="#EFF6FF"
      />
      <rect
        x={111.518}
        y={91.93}
        width={249.957}
        height={159.72}
        rx={1.924}
        fill="url(#g)"
        fillOpacity={0.74}
      />
      <path
        d="M111.518 93.854c0-1.063.862-1.924 1.925-1.924H359.55c1.063 0 1.925.861 1.925 1.924v21.168H111.518V93.854Z"
        fill="#3B82F6"
      />
      <path
        d="M198.042 263.196h75.948l3.845 37.525h-83.639l3.846-37.525Z"
        fill="#D1D5DB"
      />
      <path
        d="M198.041 263.196h75.949l.961 11.546H197.08l.961-11.546Z"
        fill="url(#h)"
      />
      <path
        d="M177.853 293.986a7.698 7.698 0 0 1 7.697-7.698h101.893a7.698 7.698 0 0 1 7.697 7.698v6.735H177.853v-6.735Z"
        fill="url(#i)"
      />
      <rect
        x={342.248}
        y={99.627}
        width={7.691}
        height={7.697}
        rx={3.845}
        fill="#BFDBFE"
      />
      <rect
        x={327.827}
        y={99.627}
        width={7.691}
        height={7.697}
        rx={3.845}
        fill="#BFDBFE"
      />
      <rect
        x={313.407}
        y={99.627}
        width={7.691}
        height={7.697}
        rx={3.845}
        fill="#BFDBFE"
      />
      <path
        d="M284.445 170.981a3.525 3.525 0 0 0-1.71-2.376l-13.113-7.483-.052-14.797a3.531 3.531 0 0 0-1.244-2.676 49.177 49.177 0 0 0-16.141-9.097 3.518 3.518 0 0 0-2.84.259l-13.226 7.403-13.24-7.416a3.516 3.516 0 0 0-2.844-.264 49.18 49.18 0 0 0-16.123 9.133 3.525 3.525 0 0 0-1.244 2.671l-.066 14.811-13.113 7.482a3.524 3.524 0 0 0-1.71 2.377 46.917 46.917 0 0 0 0 18.291 3.525 3.525 0 0 0 1.71 2.377l13.113 7.482.052 14.802a3.531 3.531 0 0 0 1.244 2.676 49.177 49.177 0 0 0 16.141 9.097 3.52 3.52 0 0 0 2.84-.259l13.24-7.425 13.239 7.416a3.477 3.477 0 0 0 1.715.44c.384 0 .765-.063 1.129-.185a49.256 49.256 0 0 0 16.124-9.124 3.534 3.534 0 0 0 1.244-2.671l.066-14.811 13.112-7.482a3.513 3.513 0 0 0 1.71-2.377 46.926 46.926 0 0 0-.013-18.274Zm-48.326 26.756a17.573 17.573 0 0 1-9.769-2.967 17.607 17.607 0 0 1-7.476-18.073 17.608 17.608 0 0 1 4.812-9.014 17.575 17.575 0 0 1 9.002-4.818 17.56 17.56 0 0 1 10.159 1.001 17.587 17.587 0 0 1 7.891 6.485 17.62 17.62 0 0 1-2.187 22.229 17.568 17.568 0 0 1-12.432 5.157Z"
        fill="#3B82F6"
      />
      <path
        d="M326.626 142.094a1.472 1.472 0 0 0-.718-1l-5.507-3.147-.022-6.225a1.488 1.488 0 0 0-.523-1.126 20.637 20.637 0 0 0-6.779-3.827 1.474 1.474 0 0 0-1.192.109l-5.555 3.115-5.561-3.12a1.477 1.477 0 0 0-1.195-.111 20.629 20.629 0 0 0-6.771 3.842 1.472 1.472 0 0 0-.523 1.123l-.027 6.231-5.507 3.147a1.474 1.474 0 0 0-.719 1 19.77 19.77 0 0 0 0 7.695 1.48 1.48 0 0 0 .719 1l5.507 3.147.022 6.227a1.475 1.475 0 0 0 .522 1.125 20.629 20.629 0 0 0 6.779 3.827c.196.067.403.091.608.072.206-.018.405-.08.585-.181l5.561-3.123 5.56 3.119c.22.123.468.187.72.186.161-.001.322-.027.475-.078a20.693 20.693 0 0 0 6.771-3.838 1.485 1.485 0 0 0 .523-1.124l.027-6.23 5.508-3.148a1.482 1.482 0 0 0 .718-1c.501-2.538.499-5.15-.006-7.687Zm-20.296 11.255a7.367 7.367 0 0 1-4.103-1.248 7.402 7.402 0 0 1-2.72-3.324 7.424 7.424 0 0 1 1.601-8.071 7.37 7.37 0 0 1 11.362 1.123 7.42 7.42 0 0 1-.919 9.351 7.373 7.373 0 0 1-5.221 2.169Z"
        fill="#C3DAFE"
      />
      <path
        d="M313.295 216.631a.958.958 0 0 0-.468-.65l-3.586-2.046-.014-4.046a.963.963 0 0 0-.34-.732 13.442 13.442 0 0 0-4.415-2.487.947.947 0 0 0-.776.071l-3.617 2.024-3.621-2.028a.969.969 0 0 0-.778-.072 13.453 13.453 0 0 0-4.41 2.497.969.969 0 0 0-.34.73l-.018 4.05-3.586 2.046a.95.95 0 0 0-.304.274.944.944 0 0 0-.163.376 12.793 12.793 0 0 0 0 5.001.944.944 0 0 0 .163.376c.08.113.184.206.304.274l3.586 2.046.015 4.047c0 .14.031.277.089.404a.972.972 0 0 0 .251.328 13.45 13.45 0 0 0 4.414 2.488.967.967 0 0 0 .777-.071l3.621-2.03 3.62 2.027a.953.953 0 0 0 .778.07 13.475 13.475 0 0 0 4.41-2.495.96.96 0 0 0 .34-.73l.018-4.05 3.586-2.046a.952.952 0 0 0 .468-.65 12.825 12.825 0 0 0-.004-4.996Zm-13.216 7.316a4.807 4.807 0 0 1-4.443-2.972 4.823 4.823 0 0 1 1.042-5.246 4.816 4.816 0 0 1 2.462-1.318c.933-.185 1.9-.09 2.779.274a4.825 4.825 0 0 1 2.158 1.773 4.823 4.823 0 0 1-.598 6.079 4.81 4.81 0 0 1-3.4 1.41Z"
        fill="#E6EDF8"
      />
      <path
        d="M430.146 92.753a1.059 1.059 0 0 0-.751-.473l-4.578-.618-1.645-4.14a1.038 1.038 0 0 0-.651-.61 15.207 15.207 0 0 0-5.623-.73 1.104 1.104 0 0 0-.784.394l-2.971 3.566-4.608-.585a1.099 1.099 0 0 0-.843.247 15.22 15.22 0 0 0-3.61 4.377 1.051 1.051 0 0 0-.062.89l1.612 4.157-2.93 3.576a1.063 1.063 0 0 0-.228.859 13.819 13.819 0 0 0 2.014 5.126 1.05 1.05 0 0 0 .751.473l4.578.618 1.645 4.142a1.047 1.047 0 0 0 .651.61c1.805.592 3.713.84 5.623.729a1.11 1.11 0 0 0 .784-.393l2.973-3.574 4.607.585a1.116 1.116 0 0 0 .843-.249 15.274 15.274 0 0 0 3.611-4.376 1.064 1.064 0 0 0 .062-.889l-1.612-4.158 2.93-3.575a1.048 1.048 0 0 0 .228-.859 13.84 13.84 0 0 0-2.016-5.12Zm-10.889 12.949a5.49 5.49 0 0 1-3.124.27 5.314 5.314 0 0 1-2.724-1.484 5.201 5.201 0 0 1-1.407-2.738 5.28 5.28 0 0 1 .386-3.069 5.448 5.448 0 0 1 2.046-2.365 5.515 5.515 0 0 1 3.019-.865 5.38 5.38 0 0 1 2.973.927 5.226 5.226 0 0 1 1.926 2.407 5.25 5.25 0 0 1-.104 4.069 5.472 5.472 0 0 1-2.991 2.848Z"
        fill="#fff"
      />
      <path
        d="M179.474 208.542a1.486 1.486 0 0 0-.719-.999l-5.507-3.148-.022-6.225a1.475 1.475 0 0 0-.522-1.125 20.629 20.629 0 0 0-6.779-3.827 1.461 1.461 0 0 0-.608-.072c-.206.018-.405.08-.585.181l-5.555 3.114-5.561-3.12a1.47 1.47 0 0 0-1.194-.111 20.651 20.651 0 0 0-6.772 3.842 1.472 1.472 0 0 0-.522 1.124l-.028 6.23-5.507 3.148a1.486 1.486 0 0 0-.718.999 19.77 19.77 0 0 0 0 7.695 1.482 1.482 0 0 0 .718 1l5.507 3.148.022 6.226a1.488 1.488 0 0 0 .523 1.126 20.655 20.655 0 0 0 6.779 3.827 1.474 1.474 0 0 0 1.192-.109l5.561-3.124 5.561 3.12c.22.123.468.187.72.185.161 0 .321-.026.474-.078a20.67 20.67 0 0 0 6.772-3.838 1.472 1.472 0 0 0 .522-1.124l.028-6.23 5.507-3.147a1.489 1.489 0 0 0 .718-1c.501-2.538.5-5.15-.005-7.688Zm-20.297 11.256a7.392 7.392 0 0 1-6.822-4.572 7.426 7.426 0 0 1 1.6-8.071 7.368 7.368 0 0 1 11.362 1.122 7.418 7.418 0 0 1-.918 9.351 7.374 7.374 0 0 1-5.222 2.17Z"
        fill="#C3DAFE"
      />
      <ellipse
        cx={236.497}
        cy={180.45}
        rx={23.073}
        ry={23.092}
        fill="#3B82F6"
      />
      <path
        d="m208.932 190.071 10.16-21.706h4.465l10.16 21.706h-5.665l-1.878-4.403h-9.699l-1.878 4.403h-5.665Zm12.346-15.795-2.956 7.051h5.974l-2.956-7.051h-.062ZM235.032 190.071v-21.706h10.437c2.464 0 4.362.636 5.696 1.909 1.355 1.252 2.033 2.986 2.033 5.203s-.678 3.962-2.033 5.234c-1.334 1.252-3.232 1.878-5.696 1.878h-4.772v7.482h-5.665Zm5.665-11.823h3.787c2.217 0 3.325-.924 3.325-2.771s-1.108-2.771-3.325-2.771h-3.787v5.542ZM255.839 190.071v-21.706h5.665v21.706h-5.665Z"
        fill="#fff"
      />
      <rect
        x={29.318}
        y={71.179}
        width={116.172}
        height={83.553}
        rx={8.586}
        stroke="url(#j)"
        strokeWidth={1.768}
      />
      <rect
        x={30.203}
        y={72.063}
        width={114.403}
        height={81.785}
        rx={7.697}
        fill="#BFDBFE"
      />
      <path
        d="M30.203 79.76a7.697 7.697 0 0 1 7.698-7.697h99.008a7.697 7.697 0 0 1 7.697 7.697v11.546H30.203V79.76Z"
        fill="#3B82F6"
      />
      <rect
        x={129.224}
        y={77.836}
        width={7.691}
        height={7.697}
        rx={3.845}
        fill="#BFDBFE"
      />
      <rect
        x={114.804}
        y={77.836}
        width={7.691}
        height={7.697}
        rx={3.845}
        fill="#BFDBFE"
      />
      <rect
        x={100.384}
        y={77.836}
        width={7.691}
        height={7.697}
        rx={3.845}
        fill="#BFDBFE"
      />
      <path
        d="M112.441 116.261c-.369-9.543-8.281-17.17-17.808-17.17-3.314.001-6.563.926-9.382 2.673a17.882 17.882 0 0 0-8.465 15.149 1.664 1.664 0 0 1-1.506 1.68 1.609 1.609 0 0 1-1.222-.433 1.63 1.63 0 0 1-.515-1.192 21.093 21.093 0 0 1 1.082-6.687.816.816 0 0 0-.533-1.042.813.813 0 0 0-.43-.011 12.99 12.99 0 0 0-7.088 4.61 13.039 13.039 0 0 0-2.759 8.005c0 7.148 6.03 13.001 13.174 13.001H94.62a17.846 17.846 0 0 0 12.852-5.494 17.916 17.916 0 0 0 4.968-13.089Zm-11.809 8.357-6.486 6.5a1.627 1.627 0 0 1-1.147.477 1.614 1.614 0 0 1-1.147-.477l-6.485-6.5a1.63 1.63 0 0 1 0-2.3 1.62 1.62 0 0 1 2.294 0l3.717 3.728v-12.328a1.626 1.626 0 0 1 1.621-1.625 1.626 1.626 0 0 1 1.621 1.625v12.328l3.718-3.728a1.62 1.62 0 0 1 2.294 0 1.63 1.63 0 0 1 0 2.3Z"
        fill="#fff"
      />
      <g clipPath="url(#k)">
        <rect
          x={335.256}
          y={156.948}
          width={114.403}
          height={81.785}
          rx={7.697}
          fill="#BFDBFE"
        />
        <path
          d="M335.256 164.645a7.697 7.697 0 0 1 7.697-7.697h99.008a7.697 7.697 0 0 1 7.698 7.697v11.546H335.256v-11.546Z"
          fill="#3B82F6"
        />
        <rect
          x={434.277}
          y={162.721}
          width={7.691}
          height={7.697}
          rx={3.845}
          fill="#BFDBFE"
        />
        <rect
          x={419.856}
          y={162.721}
          width={7.691}
          height={7.697}
          rx={3.845}
          fill="#BFDBFE"
        />
        <rect
          x={405.436}
          y={162.721}
          width={7.691}
          height={7.697}
          rx={3.845}
          fill="#BFDBFE"
        />
        <path fill="#3B82F6" d="M342.947 183.888h17.305v3.849h-17.305z" />
        <path fill="#fff" d="M342.947 191.586h17.305v3.849h-17.305z" />
        <path
          fill="#3B82F6"
          d="M342.947 203.132h17.305v3.849h-17.305zM425.625 183.888h17.305v3.849h-17.305z"
        />
        <path fill="#fff" d="M425.625 218.527h17.305v3.849h-17.305z" />
        <path
          fill="#393939"
          d="M366.019 183.888h56.721v3.849h-56.721zM366.019 218.527h56.721v3.849h-56.721z"
        />
        <path fill="#3B82F6" d="M366.019 191.586h29.802v3.849h-29.802z" />
        <path fill="#393939" d="M366.019 203.132h36.532v3.849h-36.532z" />
        <path fill="#fff" d="M366.019 210.829h23.073v3.849h-23.073z" />
        <path fill="#3B82F6" d="M366.019 226.224h23.073v3.849h-23.073z" />
        <path fill="#fff" d="M399.668 191.586h29.802v3.849h-29.802z" />
        <path
          fill="#3B82F6"
          d="M404.475 203.132h37.493v3.849h-37.493zM392.939 210.829h23.073v3.849h-23.073z"
        />
        <path fill="#fff" d="M392.939 226.224h23.073v3.849h-23.073z" />
        <path fill="#393939" d="M419.856 210.829h23.073v3.849h-23.073z" />
      </g>
      <rect
        x={334.371}
        y={156.063}
        width={116.172}
        height={83.553}
        rx={8.586}
        stroke="url(#l)"
        strokeWidth={1.768}
      />
      <defs>
        <linearGradient
          id="a"
          x1={447.231}
          y1={18.345}
          x2={374.445}
          y2={262.419}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E2EDFF" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="d"
          x1={219.981}
          y1={64.334}
          x2={220.347}
          y2={82.462}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0} />
          <stop offset={1} stopColor="#D1D5DB" />
        </linearGradient>
        <linearGradient
          id="e"
          x1={-9.507}
          y1={203.306}
          x2={72.017}
          y2={324.949}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E1EDFF" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="f"
          x1={236.497}
          y1={80.384}
          x2={236.497}
          y2={263.196}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#C8CED7" />
        </linearGradient>
        <linearGradient
          id="h"
          x1={236.496}
          y1={262.234}
          x2={236.496}
          y2={280.515}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9CA3AF" />
          <stop offset={1} stopColor="#9CA3AF" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="i"
          x1={236.497}
          y1={286.288}
          x2={236.497}
          y2={300.721}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E5E6E8" />
          <stop offset={1} stopColor="#D1D5DB" />
        </linearGradient>
        <linearGradient
          id="j"
          x1={87.404}
          y1={72.063}
          x2={87.501}
          y2={162.019}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#78AAFD" />
          <stop offset={0.327} stopColor="#BFDBFE" />
          <stop offset={1} stopColor="#A4BCE3" />
        </linearGradient>
        <linearGradient
          id="l"
          x1={392.457}
          y1={156.948}
          x2={392.457}
          y2={238.732}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#78AAFD" />
          <stop offset={0.384} stopColor="#BFDBFE" />
          <stop offset={1} stopColor="#A8C0E7" />
        </linearGradient>
        <radialGradient
          id="b"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(-90.53 274.553 36.991) scale(36.8857 730.013)"
        >
          <stop stopColor="#F9FAFB" />
          <stop offset={1} stopColor="#E9E9E9" />
        </radialGradient>
        <radialGradient
          id="g"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 252.57 -395.263 0 236.497 171.79)"
        >
          <stop offset={0.239} stopColor="#F9FAFF" />
          <stop offset={1} stopColor="#A8AFC6" />
        </radialGradient>
        <clipPath id="k">
          <rect
            x={335.255}
            y={156.948}
            width={114.403}
            height={81.785}
            rx={7.701}
            fill="#fff"
          />
        </clipPath>
        <filter
          id="c"
          x={164.602}
          y={69.41}
          width={111.411}
          height={19.453}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            stdDeviation={3.095}
            result="effect1_foregroundBlur_89_6262"
          />
        </filter>
      </defs>
    </svg>
  );
};
export default SvgApiIllustration;
