import React, { FC } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { useTheme } from 'hooks/theme';

import { Button, ButtonLink } from 'components/UI/Button';

import { PopupNotificationProps } from './type';

export const TelegramPopup: FC<PopupNotificationProps> = ({ closePopup }) => {
  const { t } = useTranslation('common');
  const { images } = useTheme();
  return (
    <>
      <div className="popup_image">
        <images.notificationTelegram.component />
      </div>
      <div className="popup_content">
        <h3>{t('common:notifications.telegram.title')}</h3>
        <p>
          <Trans i18nKey="common:notifications.telegram.content">
            Присоединяйтесь к телеграм-каналу <b>The Change Team</b> и следите
            за актуальными новостями ресурса. Ждём вас, там интересно.
          </Trans>
        </p>
        <div className="popup_ctrl">
          <ButtonLink
            href="https://t.me/the_changeteam"
            as="a"
            target="_blank"
            onClick={closePopup}
          >
            {t('common:notifications.buttons.goTo')}
          </ButtonLink>
          <Button onClick={closePopup}>
            {t('common:notifications.buttons.informed')}
          </Button>
        </div>
      </div>
    </>
  );
};
