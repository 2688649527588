import * as React from 'react';
import { SVGProps } from 'react';

const SvgPromoBg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={47}
      height={44}
      viewBox="0 0 47 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M14.064 31.826c7.915 0 14.332-6.268 14.332-14s-6.417-14-14.332-14c-7.915 0-14.331 6.268-14.331 14s6.416 14 14.331 14Z"
          fill="#FFCA5D"
        />
        <path
          d="M32.49 27.826c7.916 0 14.332-6.268 14.332-14s-6.416-14-14.331-14c-7.915 0-14.332 6.268-14.332 14s6.417 14 14.332 14Z"
          fill="#F6B545"
        />
        <path
          d="M32.49-.174c-7.984 0-14.33 6.2-14.33 14s6.346 14 14.33 14c7.985 0 14.332-6.2 14.332-14s-6.347-14-14.331-14Zm0 24c-5.732 0-10.236-4.4-10.236-10s4.504-10 10.237-10c5.732 0 10.236 4.4 10.236 10s-4.504 10-10.236 10Z"
          fill="#FFCB5B"
        />
        <path
          d="M32.49 27.626c7.985.2 14.332-6.2 14.332-13.8h-.205c-1.023 0-1.842.8-2.047 1.8-.41 2.8-1.843 5.4-3.89 7.2-1.638 1.6-3.89 2.6-6.347 3-1.024 0-1.842.8-1.842 1.8Z"
          fill="#F6B545"
        />
        <path
          d="M32.49.026c-7.984-.2-14.33 6.2-14.33 13.8h.204c1.023 0 1.842-.8 2.047-1.8.41-2.8 1.843-5.4 3.89-7.2 1.638-1.6 3.89-2.6 6.347-3 1.024 0 1.843-.8 1.843-1.8Z"
          fill="#FFE27A"
        />
        <path
          d="M23.687 15.226c0-5.6 4.504-10 10.237-10 2.456 0 4.709.8 6.551 2.4-2.047-2.4-4.709-3.8-7.984-3.8-5.733 0-10.237 4.4-10.237 10 0 3.2 1.433 5.8 3.685 7.6-1.433-1.6-2.252-3.8-2.252-6.2Z"
          fill="#F19920"
        />
        <path
          d="M38.633 20.026c-.205 0-.41 0-.615-.2-.409-.4-.409-1 0-1.4 1.229-1.2 1.843-3 1.843-4.6 0-.6.41-1 1.024-1 .614 0 1.023.4 1.023 1 0 2.2-.819 4.4-2.252 6-.41.2-.819.2-1.023.2Z"
          fill="#FFCB5B"
        />
        <path
          d="M32.49 19.826c-1.228 0-2.047-.8-2.047-2v-8c0-1.2.82-2 2.048-2 1.228 0 2.047.8 2.047 2v8c0 1.2-.819 2-2.047 2Z"
          fill="#F19920"
        />
        <path
          d="M40.066 25.626c-1.433-5-5.119-9-9.828-10.8-.819-4.6-3.89-8.6-7.78-11-2.456 2.6-4.094 6-4.094 9.8 0 7.8 6.346 14 14.331 14 2.662.2 5.119-.6 7.37-2Z"
          fill="#F19920"
        />
        <path
          d="M25.939 21.426c-1.433-1.8-2.457-4-2.457-6.4 0-2.8 1.229-5.4 3.276-7.2-.41-.6-.82-1-1.229-1.4-2.047 1.8-3.275 4.4-3.275 7.4 0 3.2 1.433 5.8 3.685 7.6ZM30.443 15.026v2.8c0 1.2.82 2 2.047 2 1.229 0 2.048-.8 2.048-2v-.4c-1.229-1-2.662-1.8-4.095-2.4Z"
          fill="#E78825"
        />
        <path
          d="M40.066 25.626c-.41-1.4-.82-2.6-1.638-3.8-1.638 1.2-3.685 2-5.937 2-5.733 0-10.237-4.4-10.237-10 0-3 1.228-5.6 3.275-7.4-1.023-1-2.047-1.8-3.275-2.4-2.457 2.6-4.095 6-4.095 9.8 0 7.8 6.347 14 14.332 14 2.866 0 5.323-.8 7.575-2.2Z"
          fill="#F6B545"
        />
        <path
          d="M39.452 23.826c-1.434 1-3.276 1.6-5.119 2-1.024.2-1.843 1-1.843 2 2.867 0 5.324-.8 7.576-2.2l-.614-1.8Z"
          fill="#F19920"
        />
        <path
          d="M22.254 4.026c-2.457 2.6-4.095 6-4.095 9.8h.205c1.023 0 1.842-.8 2.047-1.8.41-2.8 1.843-5.2 3.685-7-.614-.2-1.228-.6-1.842-1Z"
          fill="#FFCB5B"
        />
        <path
          d="M14.064 31.826c7.915 0 14.332-6.268 14.332-14s-6.417-14-14.332-14c-7.915 0-14.331 6.268-14.331 14s6.416 14 14.331 14Z"
          fill="#F6B545"
        />
        <path
          d="M12.017 27.826c0 1.4.205 2.6.614 4h1.433c7.985 0 14.332-6.2 14.332-14 0-1.4-.205-2.6-.614-4h-1.433c-7.985 0-14.332 6.2-14.332 14Z"
          fill="#F19920"
        />
        <path
          d="M14.064 3.826c-7.984 0-14.331 6.2-14.331 14s6.347 14 14.331 14c7.985 0 14.332-6.2 14.332-14s-6.347-14-14.332-14Zm0 24c-5.732 0-10.236-4.4-10.236-10s4.504-10 10.236-10c5.733 0 10.237 4.4 10.237 10s-4.504 10-10.237 10Z"
          fill="#FFCB5B"
        />
        <path
          d="M14.064 31.626c7.985.2 14.332-6.2 14.332-13.8h-.205c-1.024 0-1.843.8-2.047 1.8-.41 2.8-1.843 5.4-3.89 7.2-1.638 1.6-3.89 2.6-6.347 3-1.024 0-1.843.8-1.843 1.8Z"
          fill="#F6B545"
        />
        <path
          d="M14.064 4.026c-7.984-.2-14.331 6.2-14.331 13.8h.205c1.023 0 1.842-.8 2.047-1.8.41-2.8 1.843-5.4 3.89-7.2 1.638-1.6 3.89-2.6 6.347-3 1.024 0 1.842-.8 1.842-1.8Z"
          fill="#FFE27A"
        />
        <path
          d="M5.26 19.226c0-5.6 4.505-10 10.238-10 2.456 0 4.708.8 6.551 2.4-2.047-2.4-4.709-3.8-7.985-3.8-5.732 0-10.236 4.4-10.236 10 0 3.2 1.433 5.8 3.685 7.6-1.433-1.6-2.252-3.8-2.252-6.2Z"
          fill="#F19920"
        />
        <path
          d="M20.206 24.026c-.204 0-.409 0-.614-.2-.41-.4-.41-1 0-1.4 1.229-1.2 1.843-3 1.843-4.6 0-.6.41-1 1.024-1 .614 0 1.023.4 1.023 1 0 2.2-.819 4.4-2.252 6-.41.2-.819.2-1.024.2Z"
          fill="#FFCB5B"
        />
        <path
          d="M14.064 23.826c-1.228 0-2.047-.8-2.047-2v-8c0-1.2.82-2 2.047-2 1.229 0 2.048.8 2.048 2v8c0 1.2-.82 2-2.048 2Z"
          fill="#F19920"
        />
        <path
          d="M23.482 14.026c.41 1.2.82 2.4.82 3.8 0 5.6-4.505 10-10.237 10-.615 0-1.434 0-2.048-.2v.2c0 1.4.205 2.6.614 4h1.434c7.984 0 14.331-6.2 14.331-14 0-1.4-.205-2.6-.614-4h-1.433c-1.024 0-1.843 0-2.867.2Z"
          fill="#F6B545"
        />
        <path
          d="M24.301 43.826c7.915 0 14.332-6.268 14.332-14s-6.417-14-14.332-14c-7.915 0-14.331 6.268-14.331 14s6.416 14 14.331 14Z"
          fill="#F6B545"
        />
        <path
          d="M24.301 15.826c-7.985 0-14.331 6.2-14.331 14s6.346 14 14.331 14c7.985 0 14.332-6.2 14.332-14s-6.347-14-14.332-14Zm0 24c-5.732 0-10.237-4.4-10.237-10s4.505-10 10.237-10c5.733 0 10.237 4.4 10.237 10s-4.504 10-10.237 10Z"
          fill="#FFCB5B"
        />
        <path
          d="M24.301 43.626c7.985.2 14.332-6.2 14.332-13.8h-.205c-1.024 0-1.843.8-2.047 1.8-.41 2.8-1.843 5.4-3.89 7.2-1.638 1.6-3.89 2.6-6.347 3-1.024 0-1.843.8-1.843 1.8Z"
          fill="#F6B545"
        />
        <path
          d="M24.301 16.026c-7.985-.2-14.331 6.2-14.331 13.8h.204c1.024 0 1.843-.8 2.048-1.8.41-2.8 1.842-5.4 3.89-7.2 1.638-1.6 3.89-2.6 6.346-3 1.024 0 1.843-.8 1.843-1.8Z"
          fill="#FFE27A"
        />
        <path
          d="M15.498 31.226c0-5.6 4.504-10 10.236-10 2.457 0 4.71.8 6.552 2.4-2.048-2.4-4.71-3.8-7.985-3.8-5.732 0-10.237 4.4-10.237 10 0 3.2 1.433 5.8 3.685 7.6-1.433-1.6-2.251-3.8-2.251-6.2Z"
          fill="#F19920"
        />
        <path
          d="M30.443 36.026c-.205 0-.41 0-.614-.2-.41-.4-.41-1 0-1.4 1.228-1.2 1.843-3 1.843-4.6 0-.6.409-1 1.023-1 .614 0 1.024.4 1.024 1 0 2.2-.82 4.4-2.252 6-.41.2-.82.2-1.024.2Z"
          fill="#FFCB5B"
        />
        <path
          d="M24.301 35.826c-1.228 0-2.047-.8-2.047-2v-8c0-1.2.819-2 2.047-2 1.229 0 2.047.8 2.047 2v8c0 1.2-.819 2-2.047 2Z"
          fill="#F19920"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h47v44H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgPromoBg;
