import React from 'react';

import { TFunction } from 'i18next';
import createStore from 'zustand';

import { uuid } from 'helpers/base';

export enum TooltipTypes {
  SUCCESS,
  WARNING,
  INFO,
  ERROR,
}

interface TooltipState {
  id: string;
  type: TooltipTypes;
  body: any;
  animate: boolean;
  translate: boolean;
}

export interface GlobalTooltipState {
  tooltip: TooltipState | undefined;
}

export interface GlobalTooltipConfig {
  type: TooltipTypes;
  body: React.ReactNode | ((t: TFunction) => void) | string;
  translate?: boolean;
  id?: string;
  animate?: boolean;
}

export interface GlobalTooltipActions {
  show(config: GlobalTooltipConfig): void;
  show(config: any): void;

  remove: () => void;
}

const globalTooltipStore = createStore<
  GlobalTooltipState & GlobalTooltipActions
>((set) => ({
  tooltip: undefined,
  show: ({ type, body, id, animate = true, translate = true }: TooltipState) =>
    set({
      tooltip: {
        id: id == null ? uuid() : id,
        type,
        body,
        animate,
        translate,
      },
    }),
  remove: () => set({ tooltip: undefined }),
}));

const globalTooltipStoreState = globalTooltipStore.getState();

export { globalTooltipStore, globalTooltipStoreState };
