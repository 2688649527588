import React from 'react';

import createStore from 'zustand';

interface PopupState {
  body: React.ReactNode;
}

export interface PopupActions {
  showPopup(body: any): void;

  removePopup: () => void;
}

const globalPopupStore = createStore<PopupState & PopupActions>((set) => ({
  body: undefined,
  showPopup: (body) => set({ body }),
  removePopup: () => set({ body: undefined }),
}));

const globalPopupStoreState = globalPopupStore.getState();

export { globalPopupStore, globalPopupStoreState };
