import * as React from 'react';
import { SVGProps } from 'react';

const SvgDashboardIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.891 10.04a1.57 1.57 0 0 0 2.218 0l2.932-2.932a1.568 1.568 0 0 0 0-2.217L13.109 1.96a1.57 1.57 0 0 0-2.218 0L7.96 4.89a1.567 1.567 0 0 0 0 2.217l2.931 2.933ZM9.016 5.952 11.95 3.02a.067.067 0 0 1 .093 0l2.933 2.931a.07.07 0 0 1 0 .094L12.048 8.98a.068.068 0 0 1-.094 0L9.02 6.048a.07.07 0 0 1 0-.097h-.004Zm13.022 4.94L19.109 7.96a1.57 1.57 0 0 0-2.217 0L13.96 10.89a1.57 1.57 0 0 0 0 2.218l2.932 2.933a1.57 1.57 0 0 0 2.217 0l2.933-2.933a1.57 1.57 0 0 0 0-2.218h-.004Zm-1.057 1.157L18.05 14.98a.07.07 0 0 1-.094 0l-2.932-2.932a.067.067 0 0 1 0-.094l2.932-2.932a.07.07 0 0 1 .094 0l2.932 2.932a.067.067 0 0 1 0 .094Zm-10.94-1.157L7.108 7.96a1.57 1.57 0 0 0-2.217 0L1.96 10.89a1.57 1.57 0 0 0 0 2.218l2.932 2.933a1.57 1.57 0 0 0 2.217 0l2.933-2.933a1.57 1.57 0 0 0 0-2.218Zm-1.06 1.157L6.047 14.98a.07.07 0 0 1-.094 0L3.02 12.048a.068.068 0 0 1 0-.094L5.95 9.02a.07.07 0 0 1 .094 0l2.933 2.932a.068.068 0 0 1 .002.096Zm4.124 1.912a1.57 1.57 0 0 0-2.218 0L7.96 16.892a1.566 1.566 0 0 0 0 2.217l2.931 2.933a1.57 1.57 0 0 0 2.218 0l2.932-2.933a1.566 1.566 0 0 0 0-2.217l-2.936-2.932Zm1.875 4.089-2.932 2.931a.066.066 0 0 1-.094 0L9.02 18.05a.07.07 0 0 1 0-.094l2.932-2.932a.067.067 0 0 1 .094 0l2.933 2.932a.07.07 0 0 1 .002.094Z"
        fill="#000"
      />
    </svg>
  );
};
export default SvgDashboardIcon;
