import * as React from 'react';
import { SVGProps } from 'react';

const SvgSavedCredentialsIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        className="svg-fill"
        d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1Zm17 8H4v8h16v-8Zm0-2V5H4v4h16Zm-6 6h4v2h-4v-2Z"
        fill="#F8FAFC"
      />
    </svg>
  );
};
export default SvgSavedCredentialsIcon;
