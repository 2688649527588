import { authService } from 'services/authService';

export const loggedEmailCookie = 'LOGGED';

export function getCookie(name: string) {
  const value = '; ' + document.cookie;
  const parts = value.split('; ' + name + '=');
  if (parts.length === 2) {
    const partPop = parts.pop();
    if (partPop != null) return partPop.split(';').shift();
  }
}

export const isAuthenticated = () => {
  const cookie = getCookie(loggedEmailCookie);

  return Boolean(cookie) && cookie !== 'null';
};

export const getLoggedEmail = () => {
  const email = getCookie(loggedEmailCookie);

  return email && atob(email);
};

export function handleAuth() {
  if (authService == null) return;

  const isCookieAuth = isAuthenticated();
  const isServiceAuth = authService.isAuthenticated;

  if (isCookieAuth !== isServiceAuth) {
    if (isCookieAuth) {
      authService.login();
    } else {
      authService.logout();
    }
  }
}
