import { BonusRangesName } from 'api/bonus';

import styled, { css } from 'styled-components/macro';

const handleRangeName = (name: BonusRangesName, theme: any) => {
  switch (name) {
    case BonusRangesName.BRONZE:
      return css`
        color: ${theme.colors.bonuses.bronze01};
        background: ${theme.colors.bonuses.bronze02};
        &::before {
          background: ${theme.colors.bonuses.bronze01};
        }
      `;
    case BonusRangesName.SILVER:
      return css`
        color: ${theme.colors.bonuses.silver01};
        background: ${theme.colors.bonuses.silver02};
        &::before {
          background: ${theme.colors.bonuses.silver01};
        }
      `;
    case BonusRangesName.GOLD:
      return css`
        color: ${theme.colors.bonuses.gold01};
        background: ${theme.colors.bonuses.gold02};
        &::before {
          background: ${theme.colors.bonuses.gold01};
        }
      `;
    case BonusRangesName.PLATINUM:
      return css`
        color: ${theme.colors.bonuses.platinum01};
        background: ${theme.colors.bonuses.platinum02};
        &::before {
          background: ${theme.colors.bonuses.platinum01};
        }
      `;
    case BonusRangesName.DIAMOND:
      return css`
        color: ${theme.colors.bonuses.diamond01};
        background: ${theme.colors.bonuses.diamond02};
        &::before {
          background: ${theme.colors.bonuses.diamond01};
        }
      `;
    default:
      return css`
        color: ${theme.colors.bonuses.bronze01};
        background: ${theme.colors.bonuses.bronze02};
        &::before {
          background: ${theme.colors.bonuses.bronze01};
        }
      `;
  }
};

interface ScUserStatusTagProps {
  name: BonusRangesName;
}

export const ScUserStatusTag = styled.div<ScUserStatusTagProps>`
  position: relative;
  display: inline-block;
  border-radius: 4px;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  padding: 4px 6px 4px 18px;
  ${({ name, theme }) => handleRangeName(name, theme)};
  &::before {
    content: '';
    position: absolute;
    left: 4px;
    top: 7px;
    height: 8px;
    width: 8px;
    border-radius: 100%;
  }
`;
