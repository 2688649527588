import React, { ReactNode } from 'react';

import * as Sentry from '@sentry/react';
import { SeverityLevel } from '@sentry/types';

import { SeverityLevelType } from 'lib/sentry/types';

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | undefined;
  eventId: string | undefined;
}

interface ErrorBoundaryProps {
  fallback?: ((error: Error | undefined) => React.ReactNode) | React.ReactNode;
  severity: SeverityLevel;
  children: ReactNode;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state = {
    hasError: false,
    error: undefined,
    eventId: undefined,
  };

  static defaultProps = { severity: SeverityLevelType.Error };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any): void {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      scope.setLevel(this.props.severity);

      const eventId = Sentry.captureException(error);

      this.setState((s) => ({ ...s, eventId }));
    });
  }

  render() {
    if (this.state.hasError) {
      const { fallback } = this.props;
      if (!fallback) return null;

      if (typeof fallback === 'function') return fallback(this.state.error);

      return fallback;
    }

    return this.props.children || null;
  }
}
