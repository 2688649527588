import { ScButton } from 'components/UI/Button';

import styled from 'styled-components/macro';

import { ScBlock } from '../block/styled';

export const ScNotFound = styled(ScBlock)`
  align-items: center;
  justify-content: center;
  h3 {
    font-weight: 500;
    font-size: 48px;
    line-height: 55px;
    margin: 12px 0;
    color: ${({ theme }) => theme.colors.text.primary};
  }
  p {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 30px;
    color: ${({ theme }) => theme.colors.text.primary};
  }
  @media (max-width: ${({ theme }) => theme.mobileSize}) {
    svg {
      max-width: 168px;
      height: auto;
      margin-bottom: 41px;
    }
    h3 {
      font-size: 28px;
      line-height: 32px;
      margin: 0 0 12px 0;
    }
    p {
      text-align: center;
      margin-bottom: 36px;
    }
    ${ScButton} {
      width: 100%;
      font-size: 18px;
      line-height: 21px;
    }
  }
`;
