import * as React from 'react';
import { SVGProps } from 'react';

const SvgGiftIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.625.5v2.021L2.447 1.214l-.645 1.072L2.994 3H.875v11.875h3.75v-1.25h-2.5V4.25h5.017A3.102 3.102 0 0 1 9 3.625c.22 0 .426.026.625.064V3H7.507l1.19-.714-.644-1.072-2.178 1.307V.5h-1.25ZM9 4.875A1.885 1.885 0 0 0 7.125 6.75c0 .22.047.428.117.625H5.875v7.5h11.25v-7.5h-1.367c.075-.2.115-.411.117-.625A1.885 1.885 0 0 0 14 4.875c-1.094 0-1.836.83-2.324 1.524-.066.092-.118.183-.176.274-.059-.09-.11-.181-.176-.275-.488-.693-1.23-1.523-2.324-1.523Zm0 1.25c.39 0 .899.42 1.29.977.094.136.08.144.156.274H9a.614.614 0 0 1-.625-.625A.616.616 0 0 1 9 6.125Zm5 0c.354 0 .625.271.625.625a.614.614 0 0 1-.625.625h-1.446c.077-.13.062-.138.157-.274.39-.556.898-.976 1.289-.976Zm-6.875 2.5h3.75v5h-3.75v-5Zm5 0h3.75v5h-3.75v-5Z"
        fill="#fff"
      />
    </svg>
  );
};
export default SvgGiftIcon;
