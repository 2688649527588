import * as React from 'react';
import { SVGProps } from 'react';

const SvgDiscountIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.707 8.293a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414-1.414l6-6a1 1 0 0 1 1.414 0Z"
        fill="#747890"
      />
      <path d="M9.5 10a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z" fill="#747890" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 10a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1ZM8 9.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
        fill="#747890"
      />
      <path d="M14.5 15a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z" fill="#747890" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 15a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Zm-1.5-.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
        fill="#747890"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.01 4.011a1.2 1.2 0 0 0-.85.354l-.003.002-.7.7-.002.002A3.2 3.2 0 0 1 8.201 6h-1A1.2 1.2 0 0 0 6 7.2v1c-.001.845-.336 1.656-.932 2.255l-.002.002-.7.7-.002.002a1.2 1.2 0 0 0 0 1.702l.002.002.702.702c.596.599.93 1.41.931 2.254v1.001a1.2 1.2 0 0 0 1.2 1.2h1c.845 0 1.656.335 2.255.93l.002.003.702.702a1.2 1.2 0 0 0 1.702 0l.002-.002.7-.7.002-.002a3.2 3.2 0 0 1 2.255-.931h1a1.2 1.2 0 0 0 1.2-1.2v-1a3.2 3.2 0 0 1 .931-2.255l.002-.002.7-.7.002-.002a1.198 1.198 0 0 0 0-1.702l-.002-.002-.7-.7-.002-.002a3.2 3.2 0 0 1-.93-2.254V7.2a1.2 1.2 0 0 0-1.2-1.2h-1a3.201 3.201 0 0 1-2.256-.93l-.002-.003-.7-.7-.002-.002a1.2 1.2 0 0 0-.85-.354ZM4 8.2a1.2 1.2 0 0 1-.348.844l-.697.697-.001.001a3.2 3.2 0 0 0 0 4.536l.001.001.696.696v.001a1.2 1.2 0 0 1 .35.844v1a3.2 3.2 0 0 0 3.2 3.2H8.2a1.2 1.2 0 0 1 .844.348l.697.697.001.001a3.2 3.2 0 0 0 4.536 0h.001l.696-.697h.001a1.2 1.2 0 0 1 .845-.349h1a3.2 3.2 0 0 0 3.2-3.2v-1c0-.316.124-.62.347-.844h.001l.696-.697.002-.001a3.2 3.2 0 0 0-.002-4.537l-.696-.696a1.202 1.202 0 0 1-.349-.846V7.2a3.2 3.2 0 0 0-3.2-3.2h-1a1.2 1.2 0 0 1-.844-.348V3.65l-.697-.696v-.001a3.201 3.201 0 0 0-4.537 0l-.697.697A1.2 1.2 0 0 1 8.2 4h-1A3.2 3.2 0 0 0 4 7.2v1Z"
        fill="#747890"
      />
    </svg>
  );
};
export default SvgDiscountIcon;
