import React, { FC, useMemo } from 'react';

import { ResponsiveMediaType } from './enum';
import { useCurrentMediaType } from './hooks';
import { isResponsiveMediaType } from './util';

export interface ResponsiveProps {
  mobile?: React.ReactNode | ResponsiveMediaType;
  tablet?: React.ReactNode | ResponsiveMediaType;
  desktop?: React.ReactNode | ResponsiveMediaType;
}

export const Responsive: FC<ResponsiveProps> = ({
  mobile,
  tablet,
  desktop,
}) => {
  const currentType = useCurrentMediaType();

  const Component = useMemo(() => {
    if (typeof currentType === 'undefined') return null;

    function getComponentByType(type: ResponsiveMediaType) {
      switch (type) {
        case ResponsiveMediaType.DESKTOP:
          return desktop;
        case ResponsiveMediaType.TABLET:
          return tablet;
        case ResponsiveMediaType.MOBILE:
          return mobile;
        default:
          return null;
      }
    }

    let component = getComponentByType(currentType);

    if (isResponsiveMediaType(component)) {
      component = getComponentByType(component);
    }

    return component;
  }, [currentType, desktop, tablet, mobile]);

  return <>{Component}</>;
};

export default Responsive;
