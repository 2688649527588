import React, { FC, Fragment } from 'react';

import { useTheme } from 'hooks/theme';

import ScLoader from './styled';
import { LoaderProps, LoaderType } from './types';

const Loader: FC<LoaderProps> = ({ type = LoaderType.SMALL }) => {
  const { images } = useTheme();

  return (
    <Fragment>
      {type === LoaderType.FULL_SCREEN ? (
        <ScLoader.Fullscreen />
      ) : (
        <ScLoader className={`${type}`}>
          <images.loader.component />
        </ScLoader>
      )}
    </Fragment>
  );
};

export default Loader;
