import * as React from 'react';
import { SVGProps } from 'react';

const SvgArrowsIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)" fill="#C3C3C3">
        <path d="M14.342 6.93c-.53 0-.959.429-.959.958v.865a2.134 2.134 0 0 1-2.131 2.131H6.015v-1.52a.31.31 0 0 0-.483-.258l-3.735 2.478a.31.31 0 0 0 0 .518l3.735 2.478a.31.31 0 0 0 .483-.26v-1.519h5.237A4.053 4.053 0 0 0 15.3 8.753v-.865c0-.53-.429-.958-.958-.958ZM14.203 4.539 10.468 2.06a.31.31 0 0 0-.483.259v1.52H4.362a.955.955 0 0 0-.354.067A4.055 4.055 0 0 0 .7 7.887v.865a.959.959 0 0 0 1.917 0v-.864c0-1.175.956-2.132 2.131-2.132h5.237v1.52a.31.31 0 0 0 .483.259l3.735-2.478a.31.31 0 0 0 0-.518Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path
            fill="#fff"
            transform="translate(.32 .64)"
            d="M0 0h15.36v15.36H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgArrowsIcon;
