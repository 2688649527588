import * as React from 'react';
import { SVGProps } from 'react';

const SvgSuccessIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={16} cy={16} r={16} fill="#8BE22C" />
      <path
        d="M24.736 9.751a.91.91 0 0 0-1.272 0L12.68 20.356 8.536 16.28a.91.91 0 0 0-1.272 0 .875.875 0 0 0 0 1.251l4.78 4.702a.911.911 0 0 0 1.274 0l11.418-11.23a.875.875 0 0 0 0-1.252Z"
        fill="#FFF"
      />
    </svg>
  );
};
export default SvgSuccessIcon;
