import * as React from 'react';
import { SVGProps } from 'react';

const SvgPartnersIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.593 13.224a2.282 2.282 0 0 0-1.744-.447C21.609 11 22.5 9.233 22.5 7.5c0-2.482-1.996-4.5-4.45-4.5a4.46 4.46 0 0 0-3.425 1.57A4.461 4.461 0 0 0 11.199 3C8.746 3 6.75 5.018 6.75 7.5c0 1.031.304 2.033.943 3.094a2.987 2.987 0 0 0-1.383.787L4.19 13.5H1.5A1.5 1.5 0 0 0 0 15v3.75a1.5 1.5 0 0 0 1.5 1.5h9.75a.74.74 0 0 0 .182-.023l6-1.5a.644.644 0 0 0 .111-.037l3.645-1.55.04-.02a2.305 2.305 0 0 0 .37-3.896h-.005ZM11.2 4.5a2.92 2.92 0 0 1 2.732 1.781.75.75 0 0 0 1.388 0A2.92 2.92 0 0 1 18.05 4.5C19.649 4.5 21 5.873 21 7.5c0 1.83-1.48 3.898-4.28 5.99l-1.04.24a2.625 2.625 0 0 0-2.555-3.23H9.439c-.81-1.091-1.189-2.051-1.189-3 0-1.627 1.35-3 2.95-3ZM1.5 15h2.25v3.75H1.5V15Zm19.072.77-3.563 1.517-5.853 1.463H5.25v-4.19l2.122-2.12a1.487 1.487 0 0 1 1.06-.44h4.693a1.125 1.125 0 0 1 0 2.25H10.5a.75.75 0 1 0 0 1.5h3a.776.776 0 0 0 .168-.019l6.281-1.444.03-.008a.806.806 0 0 1 .59 1.49h.003Z"
        fill="#000"
      />
    </svg>
  );
};
export default SvgPartnersIcon;
