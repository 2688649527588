export enum BidStatus {
  NEW = 100,

  WAITING = 200,
  INVOICE_PS_CREATING_ERROR = 201,
  EXPIRED = 202,
  CALLBACK_ERROR = 203,

  TERMINATED_BY_MANAGER = 250,
  WAITING_FOR_CONFIRMATION = 251,
  RISKY_PAYMENT = 252,

  PAID = 300,
  INVOICE_REJECTED = 301,
  PAID_EXPIRED = 302,
  INVALID_CALLBACK_ACCOUNT = 303,
  ON_HOLD = 304,
  INVALID_INVOICE_AMOUNT = 305,
  CARDING = 306,

  PAYMENT_PROCESSING = 400,
  PAYMENT_PROCESSING_ERROR = 401,
  PAYMENT_PROCESSING_NETWORK_ERROR = 402,
  PAYMENT_PAID_OUT_PARTIALLY = 403,
  REFUND_INITIATED = 404,

  SUCCESS = 500,
  PAYMENT_REJECTED = 501,
  INVOICE_REFUND = 502,
  RECALCULATED = 503,
  SELF_INVOICE_REFUND = 504,
  PARTIAL_RECALCULATED = 505,
}

export enum RecaptchaVersion {
  V2 = 2,
  V3 = 3,
}

export enum RecaptchaV3Action {
  LOGIN = 'login',
  TWOFACTOR = 'twoFactor',
  SIGNUP = 'signup',
  RECOVERY = 'recovery',
  FEEDBACK = 'feedback',
}

export const USERS_STATUSES_AMOUNT = 5;

export enum VerifyStatus {
  NOT_VERIFIED,
  VERIFYING,
  LEVEL_2,
  VERIFIED,
}

export enum VerifyErrorCode {
  ALREADY_EXISTS = 1,
}

export enum PayMethodNetwork {
  ETHEREUM = 'Ethereum',
  BUSD = 'BUSD',
  TRC20 = 'TRC20',
  BNB = 'BNB',
  TON = 'TON',
}

export enum VerificationLevel {
  NOT_VERIFIED = 1,
  LEVEL_2,
  Verified,
}

export enum VerificationPendingStatus {
  FULL_CHECK = 'full_check',
  DOCUMENT_VERIFICATION = 'document_upload_only',
}

export enum VerificationScheme {
  FULL_CHECK,
  DOCUMENT_UPLOAD_ONLY,
}

export enum ApiKeyStatus {
  ACTIVE,
  DISABLED,
}

export enum ThemeName {
  BITSTORE = 'bitstore',
  CRYPTON = 'crypton',
  ROCKETCHANGE = 'rocketchange',
  TOPRATE = 'toprate',
}
