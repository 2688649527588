import * as React from 'react';
import { SVGProps } from 'react';

const SvgFactor6X = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={34}
      height={35}
      viewBox="0 0 34 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M17 33.91c8.837 0 16-7.163 16-16s-7.163-16-16-16-16 7.163-16 16 7.163 16 16 16Z"
          fill="#93C5FD"
        />
        <path d="M17 34.41c9.113 0 16.5-7.387 16.5-16.5S26.113 1.41 17 1.41.5 8.797.5 17.91s7.387 16.5 16.5 16.5Z" />
        <path
          d="m12.487 18.054 1.664-2.56h2.464l-2.88 4.048c.64.8 1.222 1.579 1.744 2.336.523.757.94 1.435 1.248 2.032h-2.56c-.085-.16-.192-.357-.32-.592a11.183 11.183 0 0 0-.432-.752 12.3 12.3 0 0 0-.512-.784 8.38 8.38 0 0 0-.512-.72c-.16.203-.325.427-.496.672-.16.245-.314.496-.464.752l-.448.736c-.138.245-.26.475-.368.688H8.183c.128-.267.304-.581.528-.944a29.104 29.104 0 0 1 1.552-2.288c.288-.395.571-.763.848-1.104-.48-.672-.96-1.35-1.44-2.032-.48-.693-.954-1.376-1.424-2.048h2.576l1.664 2.56Zm8.78.64c-.235 0-.485.021-.752.064a2.215 2.215 0 0 0-.624.176c0 .043-.005.107-.016.192v.224c0 .384.026.747.08 1.088.053.341.144.64.272.896.128.256.299.459.512.608.213.139.48.208.8.208.266 0 .49-.053.672-.16.192-.117.347-.261.464-.432.128-.17.218-.357.272-.56.064-.213.096-.416.096-.608 0-.544-.139-.96-.416-1.248-.267-.299-.72-.448-1.36-.448Zm.304-1.856c.693 0 1.28.096 1.76.288.49.181.89.432 1.2.752.31.32.533.693.672 1.12.139.427.208.88.208 1.36 0 .427-.08.864-.24 1.312a3.49 3.49 0 0 1-.704 1.216c-.31.363-.71.661-1.2.896-.48.235-1.05.352-1.712.352-1.334 0-2.347-.421-3.04-1.264-.694-.843-1.04-2.005-1.04-3.488 0-1.067.165-2.01.496-2.832.33-.821.8-1.51 1.408-2.064a6.117 6.117 0 0 1 2.224-1.28c.874-.299 1.856-.453 2.944-.464a455.44 455.44 0 0 0 .128 1.952 8.702 8.702 0 0 0-1.536.16c-.47.085-.901.224-1.296.416a3.51 3.51 0 0 0-1.008.752 3.1 3.1 0 0 0-.672 1.104c.235-.107.474-.181.72-.224.245-.043.475-.064.688-.064Z"
          fill="#2D3344"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h34v35H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgFactor6X;
