import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { useTheme } from 'hooks/theme';

import { Button, ButtonLink } from 'components/UI/Button';

import { PopupNotificationProps } from './type';

export const GiveawayPopup: FC<PopupNotificationProps> = ({ closePopup }) => {
  const { t } = useTranslation('common');
  const { images } = useTheme();
  return (
    <>
      <div className="popup_image">
        <images.notificationGiveaway.component />
      </div>
      <div className="popup_content">
        <h3>{t('common:notifications.giveaway.title')}</h3>
        <p>{t('common:notifications.giveaway.content')}</p>
        <div className="popup_ctrl">
          <ButtonLink href="/giveaway" as="a" onClick={closePopup}>
            {t('common:notifications.buttons.more')}
          </ButtonLink>
          <Button onClick={closePopup}>
            {t('common:notifications.buttons.informed')}
          </Button>
        </div>
      </div>
    </>
  );
};
