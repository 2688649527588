import * as React from 'react';
import { SVGProps } from 'react';

const SvgRegistrationIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)" fillRule="evenodd" clipRule="evenodd" fill="#fff">
        <path d="M12.364-.001v2.182h-2.182v1.454h2.182v2.182h1.455V3.635H16V2.18h-2.181V-.001h-1.455ZM1.454 13.82c0-1.203.979-2.182 2.182-2.182h5.091c1.203 0 2.182.98 2.182 2.182v.728H1.454v-.728Zm-1.455 0V16h12.365V13.82a3.64 3.64 0 0 0-3.637-3.636H3.636a3.64 3.64 0 0 0-3.637 3.636ZM6.182 4.363A1.82 1.82 0 0 1 8 6.18 1.82 1.82 0 0 1 6.182 8 1.82 1.82 0 0 1 4.363 6.18a1.82 1.82 0 0 1 1.819-1.818m0 5.091a3.276 3.276 0 0 0 3.272-3.273 3.277 3.277 0 0 0-3.273-3.273A3.277 3.277 0 0 0 2.91 6.181 3.276 3.276 0 0 0 6.18 9.454" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgRegistrationIcon;
