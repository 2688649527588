import * as React from 'react';
import { SVGProps } from 'react';

const SvgVerifyProfileIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        className="svg-fill"
        d="M12 2.25A9.75 9.75 0 1 0 21.75 12 9.76 9.76 0 0 0 12 2.25ZM6.945 18.516a6 6 0 0 1 10.11 0 8.234 8.234 0 0 1-10.11 0ZM9 11.25a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm9.165 6.226a7.469 7.469 0 0 0-3.38-2.695 4.5 4.5 0 1 0-5.57 0 7.469 7.469 0 0 0-3.38 2.695 8.25 8.25 0 1 1 12.33 0Z"
        fill="#000"
      />
      <path
        className="svg-fill"
        d="M19 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
        fill="#DC2626"
      />
    </svg>
  );
};
export default SvgVerifyProfileIcon;
