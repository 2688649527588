import * as React from 'react';
import { SVGProps } from 'react';

const SvgFactor2X = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={16}
        cy={16}
        r={15}
        fill="#DCDCDC"
        stroke="#5B5B64"
        strokeWidth={2}
      />
      <path
        d="m11.487 16.144 1.664-2.56h2.464l-2.88 4.048c.64.8 1.222 1.579 1.744 2.336.523.757.94 1.435 1.248 2.032h-2.56c-.085-.16-.192-.357-.32-.592a11.183 11.183 0 0 0-.432-.752 12.3 12.3 0 0 0-.512-.784 8.38 8.38 0 0 0-.512-.72c-.16.203-.325.427-.496.672-.16.245-.314.496-.464.752l-.448.736c-.138.245-.26.475-.368.688H7.183c.128-.267.304-.581.528-.944a29.074 29.074 0 0 1 1.552-2.288c.288-.395.571-.763.848-1.104-.48-.672-.96-1.35-1.44-2.032-.48-.693-.954-1.376-1.424-2.048h2.576l1.664 2.56Zm12.332-2.32c0 .405-.08.795-.24 1.168a5.61 5.61 0 0 1-.624 1.088 9.276 9.276 0 0 1-.864.992c-.32.32-.635.624-.944.912-.16.15-.336.32-.528.512a13.12 13.12 0 0 0-.528.56c-.17.192-.326.373-.464.544-.128.16-.208.293-.24.4h4.768v2h-7.392a2.582 2.582 0 0 1-.032-.448v-.384c0-.512.08-.981.24-1.408.17-.427.39-.821.656-1.184.267-.373.565-.72.896-1.04.341-.32.677-.64 1.008-.96l.72-.688c.224-.224.421-.437.592-.64.17-.213.304-.421.4-.624.096-.213.144-.427.144-.64 0-.47-.134-.8-.4-.992-.267-.192-.597-.288-.992-.288-.288 0-.56.048-.816.144a3.685 3.685 0 0 0-.688.32c-.203.117-.379.24-.528.368a3.95 3.95 0 0 0-.336.288l-1.184-1.664a5.93 5.93 0 0 1 1.632-1.072 4.787 4.787 0 0 1 2.016-.432c.65 0 1.21.075 1.68.224.47.15.853.363 1.152.64.31.267.533.597.672.992a3.6 3.6 0 0 1 .224 1.312Z"
        fill="#373743"
      />
    </svg>
  );
};
export default SvgFactor2X;
