import * as React from 'react';
import { SVGProps } from 'react';

const SvgCloseIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.281 18.219a.75.75 0 1 1-1.061 1.061L12 13.06l-6.219 6.22A.75.75 0 0 1 4.72 18.22l6.22-6.22L4.72 5.78A.75.75 0 0 1 5.78 4.72l6.22 6.22 6.219-6.22A.75.75 0 1 1 19.28 5.78L13.061 12l6.22 6.219Z"
        fill="#475569"
      />
    </svg>
  );
};
export default SvgCloseIcon;
