import React from 'react';

import createStore from 'zustand';

interface GlobalSiteAssistantState {
  isAssistantPanelVisible: boolean;
  title: string;
  content: React.ReactNode;
  notifyState: boolean;
}

export interface GlobalSiteAssistantActions {
  setIsAssistantPanelVisible: (visible?: boolean) => void;
  setAssistantPanelContent: (
    title: string,
    content: React.ReactNode,
    notifyState?: boolean
  ) => void;
}

const GlobalSiteAssistantStore = createStore<
  GlobalSiteAssistantState & GlobalSiteAssistantActions
>((set) => ({
  title: 'Assistant',
  content: <p>Test info</p>,
  isAssistantPanelVisible: false,
  notifyState: false,
  setIsAssistantPanelVisible: (visible) =>
    set((state) => ({
      ...state,
      isAssistantPanelVisible: visible ?? !state.isAssistantPanelVisible,
      notifyState: false,
    })),
  setAssistantPanelContent: (title, content, notifyState = false) =>
    set((state) => ({ ...state, title, content, notifyState })),
}));

export default GlobalSiteAssistantStore;
