import styled, { StyledComponentBase } from 'styled-components/macro';

import { LoaderType } from './types';

interface IScLoader extends StyledComponentBase<any, Record<never, never>> {
  Fullscreen?: any;
}

const ScLoader: IScLoader = styled.div`
  display: inline-block;
  width: 13px;
  height: 11px;
  &.${LoaderType.MEDIUM} {
    height: 30px;
    width: 100%;
    svg {
      height: 100%;
    }
    margin: auto;
  }
`;

ScLoader.Fullscreen = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(${({ theme }) => theme.images.loader.path}) no-repeat 50% 50%;
  background-size: 62px 62px;
  z-index: 10;
`;

export default ScLoader;
