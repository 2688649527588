import * as React from 'react';
import { SVGProps } from 'react';

const SvgNotVerifyFactor5X = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle opacity={0.8} cx={12} cy={12.91} r={12} fill="#67E8F9" />
      <path
        d="m8.616 12.518 1.248-1.92h1.848l-2.16 3.036c.48.6.916 1.184 1.308 1.752.392.568.704 1.076.936 1.524h-1.92a55.29 55.29 0 0 0-.24-.444 8.38 8.38 0 0 0-.324-.564c-.12-.2-.248-.396-.384-.588a6.31 6.31 0 0 0-.384-.54c-.12.152-.244.32-.372.504a13.3 13.3 0 0 0-.348.564l-.336.552c-.104.184-.196.356-.276.516H5.388c.096-.2.228-.436.396-.708a21.82 21.82 0 0 1 1.164-1.716c.216-.296.428-.572.636-.828-.36-.504-.72-1.012-1.08-1.524-.36-.52-.716-1.032-1.068-1.536h1.932l1.248 1.92Zm7.616 1.944a1.18 1.18 0 0 0-.156-.612c-.104-.184-.284-.336-.54-.456-.256-.12-.6-.212-1.032-.276a11.46 11.46 0 0 0-1.632-.096c.088-.744.16-1.5.216-2.268.056-.768.1-1.488.132-2.16h4.5v1.5h-3c-.016.288-.036.568-.06.84-.016.264-.036.496-.06.696 1.168.08 2.028.356 2.58.828.56.464.84 1.112.84 1.944 0 .384-.068.74-.204 1.068-.136.328-.34.612-.612.852a2.9 2.9 0 0 1-1.032.564c-.408.136-.888.204-1.44.204-.216 0-.444-.016-.684-.048a6.41 6.41 0 0 1-.684-.108 7.294 7.294 0 0 1-.588-.132 2.309 2.309 0 0 1-.408-.144l.324-1.476c.2.088.464.176.792.264.328.08.72.12 1.176.12.552 0 .952-.108 1.2-.324.248-.224.372-.484.372-.78Z"
        fill="#2D3344"
      />
    </svg>
  );
};
export default SvgNotVerifyFactor5X;
