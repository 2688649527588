import * as React from 'react';
import { SVGProps } from 'react';

const SvgTagNewIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={9} cy={9} fill="#fff" r={7} />
      <path
        d="m17.93 8.736-1.065-1.844V4.764c0-.189-.1-.363-.264-.457l-1.844-1.064-1.064-1.844a.527.527 0 0 0-.457-.264h-2.129L9.263.071a.527.527 0 0 0-.527 0L6.892 1.135H4.763c-.188 0-.362.1-.456.264L3.242 3.243 1.4 4.307a.527.527 0 0 0-.264.457v2.128L.07 8.736a.527.527 0 0 0 0 .528l1.065 1.844v2.128c0 .189.1.363.263.457l1.844 1.064 1.065 1.844a.527.527 0 0 0 .456.264h2.13l1.843 1.064a.528.528 0 0 0 .527 0l1.844-1.064h2.13c.188 0 .362-.1.456-.264l1.064-1.843 1.844-1.065a.527.527 0 0 0 .264-.457v-2.129l1.064-1.843a.527.527 0 0 0 0-.528ZM6.42 10.694a.527.527 0 0 1-.966.293l-1.293-1.94v1.647a.527.527 0 1 1-1.055 0V7.306a.527.527 0 0 1 .967-.292l1.293 1.94V7.305a.527.527 0 0 1 1.054 0v3.39Zm2.297-2.221a.527.527 0 0 1 0 1.054h-.602v.64h1.167a.527.527 0 1 1 0 1.055H7.588a.527.527 0 0 1-.528-.527v-3.39c0-.29.236-.527.528-.527h1.694a.527.527 0 0 1 0 1.055H8.115v.64h.602Zm6.443-1.04-.848 3.39a.527.527 0 0 1-1.011.038l-.63-1.888-.63 1.888a.528.528 0 0 1-1.011-.039l-.847-3.389a.527.527 0 1 1 1.023-.255l.408 1.632.557-1.671a.527.527 0 0 1 1 0l.558 1.67.408-1.631a.527.527 0 1 1 1.023.255Z"
        fill="#FF822E"
      />
    </svg>
  );
};
export default SvgTagNewIcon;
