import * as React from 'react';
import { SVGProps } from 'react';

const SvgCheckmarkIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={10} cy={10} r={10} fill="#DEF6EE" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.97 6.97a.75.75 0 0 1 1.07 1.05l-3.991 4.99a.749.749 0 0 1-1.08.02l-2.645-2.646a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.24.24 0 0 1 .02-.022h-.001Z"
        fill="#359978"
      />
    </svg>
  );
};
export default SvgCheckmarkIcon;
