import React from 'react';

import * as Sentry from '@sentry/react';

import { createRoot } from 'react-dom/client';
import { Theme } from 'themes/contracts';

import App from './app/app';
import { ENV } from './env/env.config';
import './lib/i18n';
import './lib/sentry/sentry';

const theme: Theme = require(`themes/${process.env.THEME}`).default;

Sentry.setTag('project', process.env.THEME || 'not defined');

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);

  root.render(<App theme={theme} />);
}

if (ENV.IS_PROD) {
  console.log(`[v${ENV.APP_VERSION}]: ${ENV.APP_BUILD_DATE}`);
}
