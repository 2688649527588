import * as Sentry from '@sentry/react';

import i18next, { i18n } from 'i18next';
import LanguageDetector, {
  DetectorOptions,
} from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

import { ENV } from 'env/env.config';

import { SeverityLevelType } from 'lib/sentry/types';

import { ThemeName } from '../helpers/constants';

export enum Languages {
  ENGLISH = 'en',
  RUSSIAN = 'ru',
}

const themeLanguages = {
  [ThemeName.BITSTORE]: [Languages.ENGLISH, Languages.RUSSIAN],
  [ThemeName.CRYPTON]: [Languages.ENGLISH],
  [ThemeName.ROCKETCHANGE]: [Languages.ENGLISH, Languages.RUSSIAN],
  [ThemeName.TOPRATE]: [Languages.ENGLISH, Languages.RUSSIAN],
};

export const availableLng = Object.values(
  themeLanguages[ENV.APP_THEME as ThemeName] || Languages
);
export const fallbackLng = Languages.ENGLISH;

class CustomLanguageDetector extends LanguageDetector {
  detect(detectionOrder: DetectorOptions['order']) {
    const detectedLang = super.detect(detectionOrder);

    if (!detectedLang || !Array.isArray(detectedLang)) return fallbackLng;

    return availableLng.includes(detectedLang[0] as Languages)
      ? detectedLang
      : fallbackLng;
  }
}

let i18nInstance: i18n;

const I18nInstanceSetter = {
  type: '3rdParty' as const,

  init(instance: i18n) {
    i18nInstance = instance;
  },
};

export { i18nInstance };

i18next.on('languageChanged', (lng) => {
  document.documentElement.lang = lng;
  Sentry.addBreadcrumb({
    category: 'localization',
    message: `Language was changed to ${lng}`,
    level: SeverityLevelType.Info,
  });

  Sentry.setTag('lng', lng);
});

i18next.on('initialized', function () {
  Sentry.addBreadcrumb({
    category: 'localization',
    message: `Localization was initialized`,
    level: SeverityLevelType.Info,
  });
});

i18next.on('failedLoading', function (lng, ns, msg) {
  Sentry.captureMessage(
    `Failed to load i18n resource ${lng}.${ns}\nMsg: ${msg}`,
    SeverityLevelType.Warning
  );
});

export default i18next
  .use(CustomLanguageDetector)
  .use(XHR)
  .use(I18nInstanceSetter)
  .use(initReactI18next)
  .init({
    fallbackLng,
    backend: {
      loadPath: `${
        !ENV.IS_DEV && ENV.PUBLIC_URL ? ENV.PUBLIC_URL : ''
      }/locales/{{lng}}/{{ns}}.json`,
      queryStringParams: { v: ENV.BUILD_VERSION },
    },
    detection: {
      order: ['localStorage', 'querystring', 'cookie', 'navigator', 'header'],
      lookupLocalStorage: 'lng',
      caches: ['localStorage', 'cookie'],
    },
    ns: ['common'],
    defaultNS: 'common',
    debug: false, // process.env.REACT_APP_ENV !== 'production',
    react: { useSuspense: false },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
