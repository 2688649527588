import React from 'react';

import { useTranslation } from 'react-i18next';

import { ButtonLink } from 'components/UI/Button';
import { Icon } from 'components/UI/Icon';

import { ScNotFound } from './styled';

const NotFoundBody = () => {
  const { t } = useTranslation();

  return (
    <>
      <Icon name="NotFoundIcon" />
      <h3>{t('common:error.notFound.header')}</h3>
      <p>{t('common:error.notFound.text')}</p>

      <ButtonLink href="/">{t('common:error.notFound.button')}</ButtonLink>
    </>
  );
};

export const NotFound = () => (
  <ScNotFound>
    <NotFoundBody />
  </ScNotFound>
);
