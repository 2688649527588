import * as React from 'react';
import { SVGProps } from 'react';

const SvgArrow = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="#fff"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m12 13.672 4.95-4.95 1.414 1.414L12 16.5l-6.364-6.364L7.05 8.722l4.95 4.95Z" />
    </svg>
  );
};
export default SvgArrow;
