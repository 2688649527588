import React, { FC, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useCurrentRoute } from 'react-navi';

import { useTheme } from 'hooks/theme';

import { ProfileDropdown } from 'components/ProfileDropdown';
import { ButtonLink, ButtonType } from 'components/UI/Button';

import { AuthServiceContext } from 'modules/authService/context';

import { ScAuthorizationButtons } from './styled';

export const AuthorizationButtons: FC = () => {
  const authService = useContext(AuthServiceContext);
  const { images } = useTheme();

  const {
    url: { href, pathname },
  } = useCurrentRoute();

  const next =
    pathname === '/login' || pathname === '/registration' ? null : href;

  const { t } = useTranslation();

  return (
    <ScAuthorizationButtons>
      {authService.isAuthenticated ? (
        <ProfileDropdown />
      ) : (
        <>
          <ButtonLink
            className="loginButton"
            href={
              next == null
                ? '/login'
                : `/login?next=${encodeURIComponent(href)}`
            }
            btnType={ButtonType.SECONDARY}
          >
            {t(`common:header.${process.env.THEME}.login`, {
              defaultValue: t('common:header.login'),
            })}
          </ButtonLink>

          <ButtonLink className="profileButton" href="/registration">
            {t(`common:header.${process.env.THEME}.signUp`, {
              defaultValue: t('common:header.signUp'),
            })}
          </ButtonLink>

          <Link href="/login" className="signInLink">
            <images.accountIcon.component />
          </Link>
        </>
      )}
    </ScAuthorizationButtons>
  );
};
