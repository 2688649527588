import * as React from 'react';
import { SVGProps } from 'react';

const SvgSuccessBadge = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M10 18.333a8.333 8.333 0 1 1 0-16.666 8.333 8.333 0 0 1 0 16.666Zm-.831-5 5.891-5.892-1.178-1.178-4.713 4.714L6.81 8.619 5.633 9.798l3.536 3.535Z"
          fill="#10B981"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgSuccessBadge;
