import React, { memo } from 'react';

import { iconSizes } from 'components/UI/Icon/consts';

import * as icons from 'assets/icons';

const Icon: React.FC<IconProps> = ({
  name,
  size,
  className,
  onClick,
  width,
  height,
  ...rest
}) => {
  const iconName = String(name) as keyof typeof icons;
  const IconComponent = icons[iconName];

  if (!IconComponent) {
    return <div className="text-red-primary p-1">{`⚠️error "${name}"`}</div>;
  }

  const sizeStyles = size
    ? {
        width: iconSizes[size],
        height: iconSizes[size],
      }
    : {
        width,
        height,
      };

  return (
    <IconComponent
      data-testid="svg"
      style={sizeStyles}
      className={className}
      onClick={onClick}
      {...rest}
    />
  );
};

export default memo(Icon);
