import * as React from 'react';
import { SVGProps } from 'react';

const SvgPlusCircleIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.333 7.333V4.667h1.334v2.666h2.666v1.334H8.667v2.666H7.333V8.667H4.667V7.333h2.666ZM8 14.667A6.667 6.667 0 1 1 8 1.333a6.667 6.667 0 0 1 0 13.334Zm0-1.334A5.334 5.334 0 1 0 8 2.666a5.334 5.334 0 0 0 0 10.667Z"
        fill="#60A5FA"
      />
    </svg>
  );
};
export default SvgPlusCircleIcon;
