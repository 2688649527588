import React from 'react';

import { TFunction } from 'i18next';

import { entries } from 'helpers/base';

export const errorHandlerFactory =
  (
    mapping: {
      [code: number]: React.ReactNode;
    },
    def?: string
  ) =>
  (code: number | null | undefined): React.ReactNode => {
    if (code == null) {
      return null;
    }

    const error = mapping[code];

    if (typeof error === 'undefined') {
      return def || 'Что-то пошло не так';
    }

    return error;
  };

export type TranslationMapping = {
  [code: number]:
    | string
    | {
        key: string;
        params: object;
      };
};

export function errorHandlerFactoryWithTranslation(
  t: TFunction,
  mapping: TranslationMapping,
  def?: string | string[]
) {
  const errorMapping: { [code: number]: string } = {};

  const defaultKeyArray: string[] = [];

  if (Array.isArray(def)) {
    def.forEach((d) => defaultKeyArray.push(d));
  } else if (typeof def === 'string') {
    defaultKeyArray.push(def);
  }

  defaultKeyArray.push('common:error.unknown');

  for (const [code, param] of entries(mapping)) {
    let key;
    let params;
    if (typeof param == 'string') {
      key = param;
      params = undefined;
    } else {
      ({ key, params } = param);
    }

    const keyArray = [key, ...defaultKeyArray];

    errorMapping[parseInt(code)] = t(keyArray, params);
  }

  return errorHandlerFactory(errorMapping, t(defaultKeyArray));
}
