import * as React from 'react';
import { SVGProps } from 'react';

const SvgCrystals100X = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={136}
      height={98}
      viewBox="0 0 136 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M114.374 54.914H23.977a.422.422 0 0 1-.422-.422V4.224a3.38 3.38 0 0 1 3.379-3.38h84.483a3.38 3.38 0 0 1 3.379 3.38v50.268a.422.422 0 0 1-.422.422Z"
          fill="#895438"
        />
        <path
          d="m40.698 40.073.245-.345a1.637 1.637 0 0 0-.596-1.171l-6.812-5.55a1.631 1.631 0 0 0-1.267-.346l-.266.328-.671 4.428 5.436 4.43 3.931-1.774Z"
          fill="#C3DDFF"
        />
        <path
          d="M33.535 33.007a1.631 1.631 0 0 0-1.267-.346l-.266.328-.338 2.226 3.348-1.005-1.477-1.203Z"
          fill="#A4CCFF"
        />
        <path
          d="m36.94 35.782-5.48 1.74.028.279 4.497 3.663.372.048.584-5.73Z"
          fill="#DBEAFF"
        />
        <path
          d="m32.268 32.66-4.6.663a1.267 1.267 0 0 0-.801.457l.037.286 4.176 3.403.38.054.808-4.862Z"
          fill="#DBEAFF"
        />
        <path
          d="m29.445 35.88 2.408-.722.415-2.497-4.6.662a1.267 1.267 0 0 0-.801.457l.037.286 2.493 2.031.048-.216Z"
          fill="#C3DDFF"
        />
        <path
          d="M40.95 45.254c.202-.248.304-.563.284-.878l-.29-4.648-4.587 1.784.063.307 4.282 3.488.248-.053Z"
          fill="#DBEAFF"
        />
        <path
          d="M26.867 33.78c-.227.28-.35.628-.347.983l.094 11.686c.005.508.23.982.615 1.296.387.315.895.439 1.393.34l11.438-2.29c.348-.07.663-.262.89-.54L26.867 33.78Z"
          fill="#A4CCFF"
        />
        <path
          d="m29.445 35.88-2.578-2.1c-.227.28-.35.628-.347.983l.094 11.686c.003.345.108.674.296.949l2.535-11.517Z"
          fill="#8BB3EA"
        />
        <path
          d="M27.23 47.745c.123.101.26.183.404.243l8.723-6.476-4.897-3.99-4.55 9.876c.088.129.195.246.32.347ZM45.99 35.287l-.318-.28a1.638 1.638 0 0 0-1.226.474l-6.22 6.207c-.32.321-.492.764-.474 1.225l.3.298 4.336 1.122 4.963-4.954-1.36-4.092Z"
          fill="#C3DDFF"
        />
        <path
          d="M38.227 41.688c-.322.321-.493.764-.475 1.225l.3.298 2.18.564-.657-3.433-1.349 1.346Z"
          fill="#A4CCFF"
        />
        <path
          d="m41.336 38.584 1.17 5.63.28.002 4.105-4.098.085-.365-5.64-1.169ZM37.752 42.913l.188 4.644c.013.315.146.617.373.843l.288-.007 3.812-3.806.093-.372-4.754-1.302Z"
          fill="#DBEAFF"
        />
        <path
          d="m40.666 46.051-.472-2.47-2.442-.668.188 4.644c.013.315.146.617.373.843l.288-.007 2.276-2.272-.21-.07Z"
          fill="#C3DDFF"
        />
        <path
          d="M51.17 35.567a1.272 1.272 0 0 0-.845-.372l-4.653-.187 1.304 4.745.312-.031 3.909-3.902-.027-.253Z"
          fill="#DBEAFF"
        />
        <path
          d="M38.313 48.4c.254.254.588.412.942.446l11.634 1.104c.505.048 1-.127 1.352-.479.353-.352.528-.845.48-1.35l-1.105-11.614a1.537 1.537 0 0 0-.446-.94L38.313 48.4Z"
          fill="#A4CCFF"
        />
        <path
          d="m40.666 46.051-2.353 2.35c.254.253.588.411.942.445l11.634 1.104c.343.033.681-.038.973-.197l-11.196-3.702Z"
          fill="#8BB3EA"
        />
        <path
          d="M52.241 49.471a1.65 1.65 0 0 0 .283-.378l-5.548-9.34-4.47 4.462 9.356 5.538c.138-.075.266-.169.38-.282ZM65.25 35.706l-.136-.4c-.39-.248-.86-.322-1.299-.204l-8.49 2.266a1.631 1.631 0 0 0-1.022.823l.11.408 3.195 3.14 6.774-1.809.868-4.224Z"
          fill="#C3DDFF"
        />
        <path
          d="M55.326 37.368a1.631 1.631 0 0 0-1.023.823l.11.408 1.606 1.578 1.148-3.3-1.841.49Z"
          fill="#A4CCFF"
        />
        <path
          d="m59.57 36.235-1.801 5.46.242.141 5.604-1.496.256-.273-4.3-3.832ZM54.303 38.191l-2.16 4.116c-.146.28-.182.607-.099.917l.254.137 5.204-1.39.267-.275-3.466-3.505Z"
          fill="#DBEAFF"
        />
        <path
          d="m55.257 42.366.826-2.375-1.78-1.8-2.16 4.116c-.146.28-.182.607-.099.917l.254.137 3.107-.83-.148-.165Z"
          fill="#C3DDFF"
        />
        <path
          d="M69.595 38.538a1.272 1.272 0 0 0-.545-.744l-3.936-2.489-1.243 4.762.285.128 5.337-1.424.102-.233Z"
          fill="#DBEAFF"
        />
        <path
          d="M52.044 43.224c.094.347.304.65.593.857l9.524 6.773c.414.294.93.39 1.41.261.482-.128.88-.468 1.091-.929l4.85-10.61c.147-.323.177-.69.083-1.038l-17.55 4.686Z"
          fill="#A4CCFF"
        />
        <path
          d="m55.257 42.366-3.212.858c.093.347.303.65.592.857l9.524 6.773c.28.2.609.308.941.316l-7.845-8.804Z"
          fill="#8BB3EA"
        />
        <path
          d="M63.572 51.115c.154-.04.3-.104.434-.186l-.135-10.862-6.102 1.629 5.333 9.474c.157.005.315-.013.47-.055ZM88.853 38.67l-.027-.422a1.637 1.637 0 0 0-1.202-.533l-8.787-.008c-.454 0-.888.191-1.2.53v.423l2.273 3.86 7.012.006 1.931-3.856Z"
          fill="#C3DDFF"
        />
        <path
          d="M78.837 37.707c-.454 0-.888.191-1.2.53v.423l1.142 1.94 1.963-2.891-1.905-.002Z"
          fill="#A4CCFF"
        />
        <path
          d="m83.23 37.711-3.153 4.809.197.198 5.8.005.318-.197-3.161-4.815ZM77.636 38.238l-3.152 3.416c-.213.232-.332.54-.332.86l.209.198 5.387.005.329-.197-2.44-4.282Z"
          fill="#DBEAFF"
        />
        <path
          d="m77.477 42.517 1.413-2.08-1.254-2.2-3.152 3.417c-.213.232-.332.54-.332.86l.209.198 3.216.003-.1-.198Z"
          fill="#C3DDFF"
        />
        <path
          d="M92.317 42.53c0-.32-.12-.627-.334-.86l-3.157-3.422-2.434 4.278.243.198 5.523.005.16-.198Z"
          fill="#DBEAFF"
        />
        <path
          d="M74.152 42.514c0 .36.125.707.35.981l7.447 9.007c.323.392.797.618 1.295.618.498 0 .97-.224 1.294-.615l7.43-8.994c.226-.273.35-.62.35-.98l-18.166-.017Z"
          fill="#A4CCFF"
        />
        <path
          d="m77.477 42.517-3.325-.003c0 .36.125.707.35.981l7.447 9.007c.22.266.508.456.827.55l-5.299-10.535Z"
          fill="#8BB3EA"
        />
        <path
          d="M83.244 53.12c.16 0 .317-.023.467-.067l2.681-10.527-6.315-.006 2.7 10.532c.15.044.307.068.467.068ZM105.53 35.83l-.38-.188a1.636 1.636 0 0 0-1.062.774l-4.4 7.606a1.631 1.631 0 0 0-.142 1.305l.367.211 4.479-.039 3.511-6.07-2.373-3.6Z"
          fill="#C3DDFF"
        />
        <path
          d="M99.688 44.022a1.631 1.631 0 0 0-.142 1.305l.367.211 2.251-.02-1.522-3.145-.954 1.649Z"
          fill="#A4CCFF"
        />
        <path
          d="m101.888 40.22 2.587 5.135.27-.072 2.905-5.02-.012-.375-5.75.331ZM99.546 45.327l1.384 4.438c.093.301.301.558.578.718l.276-.082 2.698-4.663-.007-.383-4.928-.028Z"
          fill="#DBEAFF"
        />
        <path
          d="m103.173 47.605-1.095-2.264-2.532-.014 1.384 4.438c.093.301.301.558.578.718l.276-.082 1.611-2.784-.222-.012Z"
          fill="#C3DDFF"
        />
        <path
          d="M110.605 34.76a1.27 1.27 0 0 0-.912-.141l-4.543 1.023 2.488 4.246.293-.11 2.766-4.782-.092-.236Z"
          fill="#DBEAFF"
        />
        <path
          d="M101.508 50.483c.312.18.675.246 1.025.187l11.524-1.945a1.681 1.681 0 0 0 1.182-.813c.25-.43.291-.953.114-1.428l-4.073-10.932a1.538 1.538 0 0 0-.675-.792l-9.097 15.723Z"
          fill="#A4CCFF"
        />
        <path
          d="m103.173 47.605-1.665 2.878c.312.18.675.246 1.025.187l11.524-1.945a1.7 1.7 0 0 0 .889-.442l-11.773-.678Z"
          fill="#8BB3EA"
        />
        <path
          d="M115.239 47.912c.08-.138.139-.286.176-.438l-7.777-7.586-3.163 5.467 10.471 2.928c.114-.108.213-.232.293-.37ZM100.545 38.941l.062-.31a1.221 1.221 0 0 0-.763-.615l-6.327-1.702a1.216 1.216 0 0 0-.968.15l-.081.305.892 3.219 5.05 1.358 2.135-2.405Z"
          fill="#C3DDFF"
        />
        <path
          d="M93.517 36.314a1.216 1.216 0 0 0-.968.15l-.081.305.449 1.618 1.972-1.704-1.372-.369Z"
          fill="#A4CCFF"
        />
        <path
          d="m96.68 37.165-3.2 2.855.104.18 4.177 1.124.267-.081-1.348-4.078Z"
          fill="#DBEAFF"
        />
        <path
          d="m92.55 36.465-2.93 1.852a.945.945 0 0 0-.406.556l.113.183 3.879 1.043.275-.079-.932-3.555Z"
          fill="#DBEAFF"
        />
        <path
          d="m91.609 39.517 1.419-1.226-.479-1.826-2.93 1.852a.945.945 0 0 0-.405.556l.113.183 2.316.623-.034-.162Z"
          fill="#C3DDFF"
        />
        <path
          d="M102.295 42.39a.944.944 0 0 0-.075-.684l-1.613-3.074-2.579 2.611.137.19 3.977 1.07.153-.113Z"
          fill="#DBEAFF"
        />
        <path
          d="M89.214 38.873a1.15 1.15 0 0 0 .064.774l3.624 7.924c.158.345.455.599.814.695a1.25 1.25 0 0 0 1.05-.193l7.088-5.044c.215-.153.372-.38.441-.639l-13.08-3.517Z"
          fill="#A4CCFF"
        />
        <path
          d="m91.609 39.517-2.395-.645a1.15 1.15 0 0 0 .064.775l3.624 7.924c.107.234.279.426.49.556l-1.783-8.61Z"
          fill="#8BB3EA"
        />
        <path
          d="M93.716 48.266c.115.031.233.045.35.042l3.962-7.065-4.547-1.223-.089 8.107c.1.061.209.108.324.14ZM80.289 43.2l.254-.29a1.49 1.49 0 0 0-.43-1.117L74.46 36.13a1.485 1.485 0 0 0-1.115-.432l-.272.273-1.02 3.948 4.51 4.52 3.726-1.24Z"
          fill="#C3DDFF"
        />
        <path
          d="M74.46 36.13a1.485 1.485 0 0 0-1.115-.432l-.272.273-.513 1.985 3.126-.598-1.226-1.228Z"
          fill="#A4CCFF"
        />
        <path
          d="m77.286 38.961-5.126 1.066-.001.255 3.731 3.738.332.077 1.064-5.136Z"
          fill="#DBEAFF"
        />
        <path
          d="m73.345 35.698-4.229.17a1.154 1.154 0 0 0-.768.34l.007.263 3.465 3.472.34.084 1.185-4.329Z"
          fill="#DBEAFF"
        />
        <path
          d="m70.487 38.352 2.25-.43.608-2.224-4.229.17a1.154 1.154 0 0 0-.768.34l.007.263 2.069 2.073.063-.192Z"
          fill="#C3DDFF"
        />
        <path
          d="M80.034 47.916c.206-.207.327-.482.339-.77l.17-4.236-4.32 1.187.028.284 3.553 3.56.23-.025Z"
          fill="#DBEAFF"
        />
        <path
          d="M68.348 36.209a1.404 1.404 0 0 0-.406.857L66.938 47.66c-.044.46.116.91.436 1.232.32.32.77.48 1.23.436l10.575-1.006a1.4 1.4 0 0 0 .856-.406L68.348 36.209Z"
          fill="#A4CCFF"
        />
        <path
          d="m70.487 38.352-2.139-2.143a1.404 1.404 0 0 0-.406.857L66.938 47.66c-.03.313.035.62.18.887l3.37-10.195Z"
          fill="#8BB3EA"
        />
        <path
          d="M67.373 48.892a1.5 1.5 0 0 0 .344.257l8.505-5.052-4.062-4.07-5.044 8.52c.068.125.154.242.257.345ZM58.114 40.074l.156-.178a.916.916 0 0 0-.265-.686l-3.472-3.478a.913.913 0 0 0-.684-.265l-.167.167-.626 2.424 2.77 2.776 2.288-.76Z"
          fill="#C3DDFF"
        />
        <path
          d="M54.533 35.732a.913.913 0 0 0-.684-.265l-.167.167-.315 1.218 1.919-.366-.753-.754Z"
          fill="#A4CCFF"
        />
        <path
          d="m56.269 37.471-3.147.653v.156l2.291 2.296.204.048.652-3.153Z"
          fill="#DBEAFF"
        />
        <path
          d="m53.849 35.467-2.597.103a.708.708 0 0 0-.471.209l.004.16 2.128 2.133.209.052.727-2.657Z"
          fill="#DBEAFF"
        />
        <path
          d="m52.094 37.095 1.381-.264.373-1.364-2.596.103a.708.708 0 0 0-.471.209l.004.16 1.27 1.274.04-.118Z"
          fill="#C3DDFF"
        />
        <path
          d="M57.958 42.97a.71.71 0 0 0 .208-.473l.104-2.601-2.653.728.018.174 2.182 2.187.141-.016Z"
          fill="#DBEAFF"
        />
        <path
          d="M50.78 35.779a.862.862 0 0 0-.248.526l-.615 6.504a.942.942 0 0 0 .268.756.939.939 0 0 0 .755.269l6.492-.615a.859.859 0 0 0 .526-.25l-7.177-7.19Z"
          fill="#A4CCFF"
        />
        <path
          d="m52.094 37.095-1.313-1.316a.862.862 0 0 0-.25.526l-.614 6.504a.952.952 0 0 0 .11.545l2.067-6.26Z"
          fill="#8BB3EA"
        />
        <path
          d="M50.185 43.565a.923.923 0 0 0 .212.159l5.22-3.1-2.495-2.5-3.095 5.23a.93.93 0 0 0 .158.211Z"
          fill="#C3DDFF"
        />
        <path
          d="M114.796 97.155H23.555a3.38 3.38 0 0 1-3.38-3.38v-47.31a3.38 3.38 0 0 1 3.38-3.379h91.241a3.38 3.38 0 0 1 3.379 3.38v47.31a3.38 3.38 0 0 1-3.379 3.38Z"
          fill="#FFD04E"
        />
        <path d="M111.417 49.845H26.934v40.552h84.483V49.845Z" fill="#895438" />
        <path
          d="M114.796 93.776H23.555v-47.31h91.241v47.31Zm-87.862-3.38h84.483V49.846H26.934v40.552Z"
          fill="#FFB432"
        />
        <path d="M111.417 59.983H26.934v3.379h84.483v-3.38Z" fill="#6C392C" />
        <path
          d="M111.417.845V19.43H26.934V.845a3.38 3.38 0 0 0-3.38 3.38V22.81h91.242V4.223a3.38 3.38 0 0 0-3.379-3.38Z"
          fill="#FFD04E"
        />
        <path
          d="M30.313.845h-3.38V19.43h3.38V.845ZM111.417.845h-3.38V19.43h3.38V.845Z"
          fill="#FFB432"
        />
        <path
          d="M111.417 16.052H26.934v3.379h84.483v-3.38ZM69.175 63.362a6.759 6.759 0 0 1-6.758-6.759V46.465h13.517v10.138a6.759 6.759 0 0 1-6.759 6.759Z"
          fill="#FFB432"
        />
        <path
          d="M69.175 54.914a1.69 1.69 0 1 0 0-3.38 1.69 1.69 0 0 0 0 3.38Z"
          fill="#6C392C"
        />
        <path
          d="M69.175 58.293a.845.845 0 0 1-.845-.845V53.33c0-.059.048-.106.106-.106h1.478c.059 0 .106.047.106.106v4.118a.845.845 0 0 1-.845.845ZM108.037 5.914H30.313v3.38h77.724v-3.38Z"
          fill="#6C392C"
        />
      </g>
      <path
        d="m35.204 59.192.42-.592a2.81 2.81 0 0 0-1.023-2.01l-11.685-9.52a2.798 2.798 0 0 0-2.172-.593l-.457.563-1.152 7.596 9.326 7.597 6.743-3.04Z"
        fill="#C3DDFF"
      />
      <path
        d="M22.916 47.07a2.798 2.798 0 0 0-2.172-.593l-.457.563-.579 3.818 5.742-1.723-2.534-2.065Z"
        fill="#A4CCFF"
      />
      <path
        d="m28.759 51.83-9.401 2.987.047.477 7.714 6.285.638.08 1.002-9.828ZM20.744 46.477l-7.893 1.136a2.174 2.174 0 0 0-1.373.785l.064.49 7.164 5.836.652.093 1.386-8.34Z"
        fill="#DBEAFF"
      />
      <path
        d="m15.9 52 4.132-1.24.712-4.283-7.893 1.136a2.174 2.174 0 0 0-1.373.785l.064.49 4.277 3.484.082-.371Z"
        fill="#C3DDFF"
      />
      <path
        d="M35.636 68.08c.346-.427.52-.967.487-1.508l-.498-7.972-7.868 3.06.108.526 7.345 5.984.426-.09Z"
        fill="#DBEAFF"
      />
      <path
        d="M11.478 48.398a2.647 2.647 0 0 0-.594 1.685l.161 20.046a2.888 2.888 0 0 0 1.055 2.223c.663.54 1.535.753 2.388.582l19.622-3.928a2.638 2.638 0 0 0 1.526-.927L11.478 48.398Z"
        fill="#A4CCFF"
      />
      <path
        d="m15.9 52-4.422-3.602a2.647 2.647 0 0 0-.594 1.685l.161 20.046c.005.592.185 1.156.507 1.627L15.9 52Z"
        fill="#8BB3EA"
      />
      <path
        d="M12.1 72.352c.213.173.447.313.695.417l14.962-11.11-8.4-6.842-7.805 16.939c.152.222.336.423.548.596Z"
        fill="#C3DDFF"
      />
      <path
        d="m39 51.124-.545-.479a2.81 2.81 0 0 0-2.104.812L25.683 62.105a2.798 2.798 0 0 0-.813 2.1l.512.512 7.44 1.924 8.512-8.497L39 51.124Z"
        fill="#C3DDFF"
      />
      <path
        d="M25.683 62.105a2.798 2.798 0 0 0-.813 2.1l.512.512 3.74.967-1.126-5.888-2.313 2.309Z"
        fill="#A4CCFF"
      />
      <path
        d="m31.017 56.781 2.007 9.658.48.001 7.041-7.029.147-.626-9.675-2.004ZM24.87 64.205l.321 7.967c.022.54.25 1.058.64 1.447l.494-.013 6.54-6.529.159-.638-8.154-2.234Z"
        fill="#DBEAFF"
      />
      <path
        d="m29.868 69.589-.81-4.236-4.188-1.148.321 7.967c.022.54.25 1.058.64 1.447l.494-.013 3.904-3.898-.36-.12Z"
        fill="#C3DDFF"
      />
      <path
        d="M47.885 51.605a2.181 2.181 0 0 0-1.45-.638l-7.98-.322 2.237 8.14.534-.053 6.706-6.694-.047-.433Z"
        fill="#DBEAFF"
      />
      <path
        d="M25.83 73.619c.437.435 1.01.706 1.616.764l19.957 1.894a2.888 2.888 0 0 0 2.32-.822c.605-.603.906-1.45.823-2.316L48.65 53.22a2.637 2.637 0 0 0-.765-1.614L25.83 73.62Z"
        fill="#A4CCFF"
      />
      <path
        d="m29.868 69.589-4.037 4.03c.436.435 1.009.706 1.615.764l19.957 1.894c.59.056 1.17-.065 1.67-.338l-19.205-6.35Z"
        fill="#8BB3EA"
      />
      <path
        d="M49.723 75.455c.194-.193.357-.412.485-.648l-9.516-16.022-7.668 7.654 16.05 9.5c.236-.128.455-.29.65-.484ZM123.055 64.392l.437-.497a2.558 2.558 0 0 0-.739-1.916l-9.696-9.714a2.548 2.548 0 0 0-1.913-.74l-.466.467-1.752 6.773 7.738 7.752 6.391-2.125Z"
        fill="#C3DDFF"
      />
      <path
        d="M113.057 52.265a2.548 2.548 0 0 0-1.913-.74l-.466.467-.881 3.404 5.362-1.025-2.102-2.106Z"
        fill="#A4CCFF"
      />
      <path
        d="m117.905 57.122-8.794 1.828-.002.436 6.401 6.413.57.133 1.825-8.81ZM111.144 51.525l-7.254.292c-.492.02-.964.228-1.317.583l.012.45 5.944 5.955.582.145 2.033-7.425Z"
        fill="#DBEAFF"
      />
      <path
        d="m106.242 56.076 3.858-.737 1.044-3.814-7.254.292c-.492.02-.964.228-1.317.583l.012.45 3.548 3.555.109-.329Z"
        fill="#C3DDFF"
      />
      <path
        d="M122.618 72.482c.353-.354.561-.827.581-1.32l.293-7.267-7.412 2.037.048.486 6.095 6.106.395-.042Z"
        fill="#DBEAFF"
      />
      <path
        d="M102.573 52.4a2.406 2.406 0 0 0-.696 1.47l-1.725 18.173c-.075.79.199 1.562.748 2.113a2.62 2.62 0 0 0 2.109.75l18.14-1.727a2.404 2.404 0 0 0 1.469-.697L102.573 52.4Z"
        fill="#A4CCFF"
      />
      <path
        d="m106.242 56.076-3.669-3.676a2.406 2.406 0 0 0-.696 1.47l-1.725 18.173a2.656 2.656 0 0 0 .307 1.521l5.783-17.488Z"
        fill="#8BB3EA"
      />
      <path
        d="M100.9 74.156c.177.176.376.324.591.441l14.589-8.665-6.969-6.982-8.652 14.614c.117.215.265.415.441.592ZM132.776 67.627l.268-.305a1.57 1.57 0 0 0-.454-1.177l-5.955-5.967a1.567 1.567 0 0 0-1.175-.454l-.286.287-1.076 4.16 4.752 4.761 3.926-1.305Z"
        fill="#C3DDFF"
      />
      <path
        d="M126.635 60.178a1.567 1.567 0 0 0-1.175-.454l-.286.287-.541 2.09 3.293-.629-1.291-1.294Z"
        fill="#A4CCFF"
      />
      <path
        d="m129.612 63.162-5.401 1.122-.001.268 3.931 3.94.35.08 1.121-5.41Z"
        fill="#DBEAFF"
      />
      <path
        d="m125.46 59.724-4.456.18c-.302.011-.592.14-.809.357l.007.276 3.652 3.658.357.09 1.249-4.561Z"
        fill="#DBEAFF"
      />
      <path
        d="m122.449 62.52 2.369-.454.642-2.342-4.456.18c-.302.011-.592.14-.809.357l.007.276 2.18 2.184.067-.202Z"
        fill="#C3DDFF"
      />
      <path
        d="M132.507 72.596a1.22 1.22 0 0 0 .357-.81l.18-4.464-4.553 1.25.03.3 3.744 3.75.242-.026Z"
        fill="#DBEAFF"
      />
      <path
        d="M120.195 60.261a1.477 1.477 0 0 0-.427.904l-1.06 11.161c-.046.486.122.96.46 1.298.338.338.811.507 1.295.46l11.142-1.06c.339-.032.659-.184.902-.428l-12.312-12.335Z"
        fill="#A4CCFF"
      />
      <path
        d="m122.449 62.52-2.254-2.259a1.477 1.477 0 0 0-.427.904l-1.06 11.161c-.031.33.037.654.189.935l3.552-10.742Z"
        fill="#8BB3EA"
      />
      <path
        d="M119.168 73.624c.108.109.231.2.363.272l8.96-5.323-4.28-4.289-5.314 8.977c.072.132.163.255.271.363ZM55.153 62.004l.305-.268c.443-.018.869.146 1.177.454l5.966 5.955c.309.308.473.732.455 1.175l-.287.286-4.16 1.076-4.762-4.752 1.306-3.926Z"
        fill="#C3DDFF"
      />
      <path
        d="M62.601 68.145c.309.308.473.732.455 1.175l-.287.286-2.091.541.63-3.293 1.293 1.291Z"
        fill="#A4CCFF"
      />
      <path
        d="m59.618 65.167-1.123 5.402h-.268l-3.938-3.93-.082-.35 5.411-1.122Z"
        fill="#DBEAFF"
      />
      <path
        d="m63.056 69.32-.18 4.456c-.012.302-.14.592-.357.809l-.277-.008-3.657-3.65-.09-.358 4.561-1.25Z"
        fill="#DBEAFF"
      />
      <path
        d="m60.26 72.33.454-2.369 2.342-.641-.18 4.456c-.012.302-.14.592-.357.809l-.277-.008-2.183-2.18.202-.066Z"
        fill="#C3DDFF"
      />
      <path
        d="M50.184 62.273a1.22 1.22 0 0 1 .81-.358l4.464-.18-1.251 4.553-.299-.03-3.75-3.743.026-.242Z"
        fill="#DBEAFF"
      />
      <path
        d="M62.519 74.585a1.48 1.48 0 0 1-.904.427l-11.162 1.06a1.615 1.615 0 0 1-1.297-.46 1.608 1.608 0 0 1-.46-1.295l1.06-11.142c.032-.339.184-.659.428-.902l12.335 12.312Z"
        fill="#A4CCFF"
      />
      <path
        d="m60.26 72.33 2.259 2.255a1.48 1.48 0 0 1-.904.427l-11.162 1.06a1.633 1.633 0 0 1-.934-.19l10.742-3.551Z"
        fill="#8BB3EA"
      />
      <path
        d="M49.156 75.612a1.58 1.58 0 0 1-.272-.363l5.323-8.96 4.288 4.28-8.976 5.314a1.585 1.585 0 0 1-.363-.271ZM104.398 67.185l.357-.407a2.096 2.096 0 0 0-.606-1.571l-7.95-7.964a2.09 2.09 0 0 0-1.567-.608l-.382.383-1.437 5.554 6.344 6.355 5.241-1.742Z"
        fill="#C3DDFF"
      />
      <path
        d="M96.2 57.243a2.09 2.09 0 0 0-1.568-.608l-.382.383-.722 2.792 4.396-.84-1.724-1.727Z"
        fill="#A4CCFF"
      />
      <path
        d="m100.175 61.225-7.21 1.498-.002.358 5.248 5.257.467.11 1.497-7.223Z"
        fill="#DBEAFF"
      />
      <path
        d="m94.632 56.636-5.948.24c-.403.016-.79.187-1.08.477l.01.369 4.874 4.882.477.12 1.667-6.088Z"
        fill="#DBEAFF"
      />
      <path
        d="m90.613 60.367 3.162-.604.857-3.127-5.948.24c-.403.016-.79.187-1.08.477l.01.369 2.91 2.915.089-.27Z"
        fill="#C3DDFF"
      />
      <path
        d="M104.039 73.818c.289-.29.46-.678.476-1.082l.24-5.958-6.077 1.67.04.399 4.997 5.006.324-.035Z"
        fill="#DBEAFF"
      />
      <path
        d="M87.604 57.353a1.976 1.976 0 0 0-.57 1.206L85.62 73.458c-.062.648.162 1.28.613 1.732.45.452 1.083.676 1.73.615l14.871-1.416a1.973 1.973 0 0 0 1.205-.571L87.604 57.353Z"
        fill="#A4CCFF"
      />
      <path
        d="m90.613 60.367-3.009-3.014a1.976 1.976 0 0 0-.57 1.206L85.62 73.458c-.042.44.048.873.251 1.247l4.742-14.338Z"
        fill="#8BB3EA"
      />
      <path
        d="M86.233 75.19c.145.145.308.266.484.362l11.961-7.105-5.713-5.724-7.094 11.982c.097.177.218.34.362.485ZM74.891 69.762l-.258-.293c-.017-.427.141-.836.437-1.133l5.733-5.743a1.506 1.506 0 0 1 1.13-.438l.276.276 1.036 4.005-4.575 4.583-3.779-1.257Z"
        fill="#C3DDFF"
      />
      <path
        d="M80.803 62.593a1.506 1.506 0 0 1 1.13-.438l.276.276.521 2.013-3.17-.606 1.243-1.245Z"
        fill="#A4CCFF"
      />
      <path
        d="m77.937 65.464 5.2 1.08v.259l-3.784 3.791-.337.079-1.08-5.209Z"
        fill="#DBEAFF"
      />
      <path
        d="m81.934 62.155 4.289.173c.29.011.57.135.779.344l-.007.266-3.515 3.521-.344.086-1.202-4.39Z"
        fill="#DBEAFF"
      />
      <path
        d="m84.832 64.846-2.28-.436-.618-2.255 4.289.173c.29.011.57.135.779.344l-.007.266-2.099 2.102-.064-.194Z"
        fill="#C3DDFF"
      />
      <path
        d="M75.15 74.546a1.174 1.174 0 0 1-.344-.78l-.173-4.297 4.383 1.204-.03.288-3.603 3.61-.233-.025Z"
        fill="#DBEAFF"
      />
      <path
        d="M87.002 62.672c.234.235.38.544.411.87l1.02 10.744c.044.467-.118.924-.443 1.25-.324.325-.78.487-1.246.442l-10.725-1.02a1.42 1.42 0 0 1-.869-.412l11.852-11.874Z"
        fill="#A4CCFF"
      />
      <path
        d="m84.832 64.846 2.17-2.174c.234.235.38.544.411.87l1.02 10.744c.03.317-.035.63-.182.9l-3.419-10.34Z"
        fill="#8BB3EA"
      />
      <path
        d="M87.99 75.535a1.519 1.519 0 0 1-.349.262l-8.625-5.124 4.12-4.128 5.115 8.64c-.069.128-.156.246-.26.35Z"
        fill="#C3DDFF"
      />
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(20)" d="M0 0h98v64H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgCrystals100X;
