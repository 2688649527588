import * as React from 'react';
import { SVGProps } from 'react';

const SvgArrowPrev = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m9.023 10 4.125 4.125-1.178 1.178L6.666 10l5.304-5.304 1.178 1.179L9.023 10Z"
        fill="#CBD5E1"
      />
    </svg>
  );
};
export default SvgArrowPrev;
