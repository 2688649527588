import { useCallback, useEffect, useReducer, useRef } from 'react';

import axios, { AxiosRequestConfig } from 'axios';
import produce, { Draft } from 'immer';

import { useMount, useMutable } from 'hooks/common';

import { parseErrorCode, parseErrorData, parseErrorMessage } from 'helpers/api';
import { isObject } from 'helpers/base';

export type RequestContext = AxiosRequestConfig | void;

export type RequestFn<Args extends any[] = any, Return = any> = (
  this: RequestContext,
  ...args: Args
) => Promise<{ data: Return }>;

interface ApiState<P> {
  pending: boolean;
  data: P | undefined;
  error: number | undefined;
  errorMessage: string | undefined;
  errorData: { [param: string]: any } | undefined;
  requestCount: number;
}

type ApiAction<P> =
  | {
      type: 'PENDING';
      payload: boolean;
    }
  | { type: 'LOADED'; payload: P }
  | {
      type: 'ERROR';
      payload: {
        code: number;
        message: string | undefined;
        data: object | undefined;
      };
    }
  | { type: 'RESET'; payload: ApiState<P> };

function mergeData(prev: any, next: any) {
  if (isObject(prev) && isObject(next)) {
    return { ...prev, ...next };
  }

  if (Array.isArray(prev) && Array.isArray(next)) {
    return [...prev, ...next];
  }

  return next;
}

export interface UseApiConfig {
  cleanUp?: boolean;
  mergeData?: boolean;
  countErrors?: boolean;
  cancelPrevious?: boolean;
  pendingOnSuccess?: boolean;
  clearDataOnError?: boolean;
  dispatchOnCancel?: boolean;
  axiosConf?: AxiosRequestConfig;
}

export function useApi<Args extends any[], Data>(
  fn: RequestFn<Args, Data>,
  initialState?: Partial<ApiState<Data>>,
  hookConfig?: UseApiConfig
) {
  const hookConfigRef = useMutable<UseApiConfig>({
    cleanUp: true,
    mergeData: false,
    countErrors: true,
    cancelPrevious: true,
    pendingOnSuccess: false,
    clearDataOnError: false,
    dispatchOnCancel: false,
    ...hookConfig,
  });

  const _initialState = {
    pending: false,
    data: undefined,
    error: undefined,
    errorMessage: undefined,
    requestCount: 0,
    errorData: undefined,
    ...initialState,
  };

  function apiReducer(
    state: ApiState<Data>,
    action: ApiAction<Data>
  ): ApiState<Data> {
    return produce(state, (draft: Draft<ApiState<unknown>>) => {
      switch (action.type) {
        case 'PENDING': {
          draft.pending = action.payload;
          break;
        }
        case 'LOADED': {
          if (!hookConfigRef.current.pendingOnSuccess) {
            draft.pending = false;
          }
          draft.data = hookConfigRef.current.mergeData
            ? mergeData(draft.data, action.payload)
            : action.payload;
          draft.error = draft.errorMessage = undefined;
          draft.requestCount += 1;
          break;
        }
        case 'ERROR': {
          draft.pending = false;
          draft.error = action.payload.code;
          draft.errorMessage = action.payload.message;
          draft.errorData = action.payload.data;

          if (hookConfigRef.current.countErrors) {
            draft.requestCount += 1;
          }
          if (hookConfigRef.current.clearDataOnError) {
            draft.data = undefined;
          }
          break;
        }
        case 'RESET': {
          return action.payload;
        }
      }
    });
  }

  const [state, dispatch] = useReducer(apiReducer, _initialState);

  const cancelRequest = useRef<() => void>();

  function tryCancelRequest() {
    if (cancelRequest && cancelRequest.current) {
      cancelRequest.current();
    }
  }

  const isMountedRef = useMount();

  const callApi = useCallback(
    (...args: Args) => {
      if (hookConfigRef.current.cancelPrevious) {
        tryCancelRequest();
      }

      const cancelSource = axios.CancelToken.source();

      const request = (async () => {
        dispatch({ type: 'PENDING', payload: true });

        try {
          const response = await fn.apply(
            {
              ...hookConfigRef.current.axiosConf,
              cancelToken: cancelSource.token,
            },
            args
          );

          cancelRequest.current = undefined;

          if (isMountedRef.current) {
            dispatch({
              type: 'LOADED',
              payload: response.data,
            });
          }

          return { response, error: undefined };
        } catch (e) {
          cancelRequest.current = undefined;

          if (!isMountedRef.current) {
            return { response: undefined, error: new Error('unmounted') };
          }

          if (axios.isCancel(e)) {
            if (hookConfigRef.current.dispatchOnCancel) {
              dispatch({
                type: 'ERROR',
                payload: {
                  code: -1,
                  message: 'canceled',
                  data: undefined,
                },
              });
            }

            return { response: undefined, error: new Error('canceled') };
          }
          dispatch({
            type: 'ERROR',
            payload: {
              code: parseErrorCode(e),
              message: parseErrorMessage(e),
              data: parseErrorData(e),
            },
          });

          return { response: undefined, error: e };
        }
      })();

      cancelRequest.current = cancelSource.cancel;

      return {
        request,
        cancel: tryCancelRequest,
      };
    },
    [fn, hookConfigRef, isMountedRef]
  );

  useEffect(
    () => () => {
      if (hookConfigRef.current.cleanUp) tryCancelRequest();
    },
    [hookConfigRef]
  );

  const initialStateRef = useMutable(_initialState);

  const reset = useCallback(
    (state: ApiState<Data> = initialStateRef.current) => {
      tryCancelRequest();
      dispatch({ type: 'RESET', payload: state });
    },
    [initialStateRef]
  );

  return [
    callApi,
    state,
    {
      reset,
      cancel: useCallback(tryCancelRequest, []),
      dispatch,
    },
  ] as const;
}
