export const iconSizes = {
  micro: 12,
  mini: 16,
  small: 20,
  medium: 24,
  large: 28,
  extraLarge: 36,
  miniModal: 160,
  modal: 240,
} as const;
