import * as React from 'react';
import { SVGProps } from 'react';

const SvgVerifyBonusIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={47}
      height={47}
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity={0.4} fill="#F97316">
        <path d="m41.34 23.13-8.898-1.293-3.978-8.065c-.109-.22-.288-.4-.508-.508a1.126 1.126 0 0 0-1.504.508l-3.978 8.065-8.899 1.293a1.12 1.12 0 0 0-.62 1.914l6.438 6.277-1.52 8.864a1.12 1.12 0 0 0 1.625 1.18l7.96-4.184 7.96 4.185a1.12 1.12 0 0 0 1.626-1.181l-1.521-8.864 6.438-6.277a1.12 1.12 0 0 0 .326-.642 1.118 1.118 0 0 0-.946-1.272Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.334 28.7a2.205 2.205 0 0 0-.628-.72 4.173 4.173 0 0 0-.957-.522c-.37-.147-.774-.287-1.211-.421a15.208 15.208 0 0 1-.598-.192 2.534 2.534 0 0 1-.43-.19.85.85 0 0 1-.26-.217.456.456 0 0 1-.086-.279c0-.106.026-.197.08-.271a.587.587 0 0 1 .216-.182 1.14 1.14 0 0 1 .317-.1c.12-.02.247-.031.383-.031.133 0 .262.018.39.054a2.356 2.356 0 0 1 .691.324c.102.069.193.136.276.2l.055.047c.058.046.11.084.159.114.047.03.1.046.158.046.07 0 .14-.032.21-.097l.947-.935a.903.903 0 0 0 .121-.143.249.249 0 0 0 .04-.138c0-.049-.027-.109-.083-.178a1.689 1.689 0 0 0-.226-.22 3.45 3.45 0 0 0-.331-.238 7.226 7.226 0 0 0-.4-.235 4.138 4.138 0 0 0-1.018-.375c-.105-.024-.213-.037-.32-.054v-.775a.212.212 0 0 0-.102-.184.355.355 0 0 0-.195-.068h-1.165c-.09 0-.158.025-.203.075a.252.252 0 0 0-.07.177v.804c-.128.024-.262.042-.383.076-.404.113-.75.273-1.038.48a2.171 2.171 0 0 0-.661.744c-.156.29-.233.613-.233.969 0 .332.062.636.186.911.123.276.309.527.557.754.248.228.558.435.929.62.372.185.805.358 1.3.518.112.035.244.078.397.13.153.051.3.113.44.186s.259.155.356.249c.097.094.146.2.146.318a.883.883 0 0 1-.053.304.62.62 0 0 1-.177.253.908.908 0 0 1-.338.174 1.806 1.806 0 0 1-.528.066c-.228 0-.445-.036-.655-.109a3.082 3.082 0 0 1-.621-.303 3.699 3.699 0 0 1-.375-.267c-.11-.09-.2-.161-.27-.215a1.16 1.16 0 0 0-.148-.103.246.246 0 0 0-.118-.035c-.054 0-.124.03-.211.086l-1.071.946c-.095.08-.142.153-.142.218 0 .061.047.137.142.23l.025.023.024.022.019.017c.198.203.427.392.684.568.258.176.533.33.824.462.29.132.595.235.91.309.028.007.056.009.084.015v.842c0 .169.091.252.272.252h1.165c.198 0 .297-.083.297-.252v-.824c.186-.032.37-.066.545-.119a3.68 3.68 0 0 0 1.143-.56 2.67 2.67 0 0 0 .765-.873c.185-.342.278-.724.278-1.143 0-.398-.075-.737-.226-1.016Z"
        />
      </g>
      <path
        d="m35.34 16.914-9.492-1.38-4.243-8.602a1.19 1.19 0 0 0-.543-.542 1.201 1.201 0 0 0-1.603.542l-4.243 8.602-9.492 1.38a1.194 1.194 0 0 0-.662 2.041l6.867 6.696-1.622 9.454a1.195 1.195 0 0 0 1.735 1.26l8.49-4.464 8.49 4.464a1.194 1.194 0 0 0 1.735-1.26l-1.623-9.454L36 18.955c.187-.183.31-.422.348-.684.101-.654-.355-1.26-1.01-1.357Z"
        fill="#F97316"
      />
      <path
        d="M25.012 21.53v1.987h-8.438V21.53h8.438Zm-3.146-3.39v8.962h-2.139V18.14h2.14Z"
        fill="#F8FAFC"
      />
    </svg>
  );
};
export default SvgVerifyBonusIcon;
