import { TwoFactorAction, TwoFactorMethod } from 'api/auth';
import { RequestWithRecaptcha, factory } from 'api/base';

import { snakify } from 'helpers/api';

import {
  ApiKeyStatus,
  VerificationLevel,
  VerificationPendingStatus,
  VerificationScheme,
} from '../helpers/constants';

export interface EditUserParams {
  firstName: string;
  lastName: string;
  middleName: string;
  phone: string;
  telegram: string;
  password: string;
  email: string;
  langCode: string;
  requisites: {
    [pmId: string]: {
      [fieldName: string]: string | null | undefined;
    };
  };
  defaultPair: {
    from: number;
    to: number;
  };
}

export interface CurrentUser {
  id: number;
  firstName: string | null;
  lastName: string | null;
  middleName: string | null;
  phone: string | null;
  telegram: string | null;
  requisites: {
    [pmId: string]: {
      [fieldName: string]: string;
    };
  };
  authActions: { [T in TwoFactorAction]: boolean };
  authMethod: TwoFactorMethod.GCODE;
  turnover: number;
  bonusBalance: number;
  bonusCrystalRate: number;
  verificationPendingStatus: VerificationPendingStatus[];
  verificationLevel: VerificationLevel;
  defaultPair?: {
    from: number;
    to: number;
  };
  isApiAvailable: boolean;
}

export const editUser = factory(
  (r) => (params: Partial<EditUserParams>) =>
    r<null>('put', '/user', snakify(params))
);

export const getCurrentUser = factory(
  (r) => () => r<CurrentUser>('get', '/user')
);

export interface ApiKey {
  id: number;
  name: string;
  status: ApiKeyStatus;
  created: string;
}

export const getApiKeys = factory(
  (r) => () => r<ApiKey[]>('get', '/user/apiKey')
);

export interface CreateApiKeyParams extends Pick<ApiKey, 'name'> {
  apiKey: string;
  gcode: string;
}

export enum ApiKeyError {
  INVALID_CODE = 4,
}

export const createApiKey = factory(
  (r) => (params: CreateApiKeyParams) => r<any>('post', '/user/apiKey', params)
);

export const editApiKey = factory(
  (r) =>
    (id: number, param: Pick<ApiKey, 'name' | 'status'> & { gcode: string }) =>
      r('put', `/user/apiKey/${id}`, param)
);

export const deleteApiKey = factory(
  (r) => (id: number, param: { gcode: string }) =>
    r('delete', `/user/apiKey/${id}`, snakify(param))
);
export interface FeedbackForm extends RequestWithRecaptcha {
  subject: string;
  body: string;
}

export const feedback = factory(
  (r) => (form: FeedbackForm) => r<null>('post', '/feedback', snakify(form))
);

interface GetVerificationUrlParams {
  scheme?: VerificationScheme;
}

export const getVerificationUrl = factory(
  (r) =>
    ({ scheme = VerificationScheme.FULL_CHECK }: GetVerificationUrlParams) =>
      r<{ redirectUrl: string }>(
        'post',
        '/user/verification',
        snakify({ scheme })
      )
);
