import { TypographyType } from 'themes/contracts';

export const formatTypographyToFont = ({
  weight,
  size,
  height,
  family,
}: TypographyType) => {
  return `${weight} ${size}/${height} ${family}`;
};
