import * as React from 'react';
import { SVGProps } from 'react';

const SvgCrossArrowsIconSecondary = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.468 8.953H6.542l4.106-4.39a1.4 1.4 0 0 0 0-1.886 1.19 1.19 0 0 0-1.763 0L2.65 9.343a1.4 1.4 0 0 0 0 1.886l6.234 6.667c.243.26.562.39.881.39.32 0 .638-.13.882-.39a1.4 1.4 0 0 0 0-1.886l-4.106-4.39h21.926c.688 0 1.246-.597 1.246-1.334 0-.736-.558-1.333-1.246-1.333Z"
        fill="#fff"
      />
      <path
        d="M23.115 14.104a1.19 1.19 0 0 0-1.763 0 1.4 1.4 0 0 0 0 1.886l4.106 4.39H3.532c-.688 0-1.246.598-1.246 1.334 0 .736.558 1.333 1.246 1.333h21.926l-4.106 4.39a1.4 1.4 0 0 0 0 1.886c.244.26.563.39.882.39.319 0 .638-.13.881-.39l6.234-6.666a1.4 1.4 0 0 0 0-1.886l-6.234-6.667Z"
        fill="#fff"
      />
    </svg>
  );
};
export default SvgCrossArrowsIconSecondary;
