export function hasRuDomain(url: string) {
  // Regular expression to match URLs with .ru domain
  const regex = /\.ru\b/;

  // Test the URL against the regular expression
  return regex.test(url);
}

export function hasComDomain(url: string) {
  // Regular expression to match URLs with .ru domain
  const regex = /\.com\b/;

  // Test the URL against the regular expression
  return regex.test(url);
}
