import * as React from 'react';
import { SVGProps } from 'react';

const SvgNotVerifyFactor3X = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle opacity={0.8} cx={12} cy={12.91} r={12} fill="#EAB308" />
      <path
        d="m8.616 12.518 1.248-1.92h1.848l-2.16 3.036c.48.6.916 1.184 1.308 1.752.392.568.704 1.076.936 1.524h-1.92a55.29 55.29 0 0 0-.24-.444 8.38 8.38 0 0 0-.324-.564c-.12-.2-.248-.396-.384-.588a6.31 6.31 0 0 0-.384-.54c-.12.152-.244.32-.372.504a13.3 13.3 0 0 0-.348.564l-.336.552c-.104.184-.196.356-.276.516H5.388c.096-.2.228-.436.396-.708a21.82 21.82 0 0 1 1.164-1.716c.216-.296.428-.572.636-.828-.36-.504-.72-1.012-1.08-1.524-.36-.52-.716-1.032-1.068-1.536h1.932l1.248 1.92Zm6.032 4.572c-.216 0-.444-.016-.684-.048a6.323 6.323 0 0 1-.696-.108 7.53 7.53 0 0 1-.612-.156 3.525 3.525 0 0 1-.432-.156l.348-1.488c.208.088.472.184.792.288.328.096.732.144 1.212.144.552 0 .956-.104 1.212-.312.256-.208.384-.488.384-.84a.953.953 0 0 0-.144-.54.948.948 0 0 0-.372-.36 1.53 1.53 0 0 0-.576-.192 3.823 3.823 0 0 0-.696-.06h-.696v-1.44h.792c.176 0 .344-.016.504-.048.168-.032.316-.084.444-.156a.862.862 0 0 0 .3-.312.98.98 0 0 0 .12-.504.785.785 0 0 0-.348-.672.925.925 0 0 0-.348-.156 1.32 1.32 0 0 0-.396-.06c-.344 0-.664.052-.96.156a4.037 4.037 0 0 0-.792.384l-.636-1.308c.128-.08.276-.164.444-.252.176-.088.368-.168.576-.24.208-.072.428-.132.66-.18.24-.048.492-.072.756-.072.488 0 .908.06 1.26.18.36.112.656.276.888.492.232.208.404.456.516.744.112.28.168.588.168.924 0 .328-.092.648-.276.96a1.832 1.832 0 0 1-.744.696c.432.176.764.44.996.792.24.344.36.76.36 1.248 0 .384-.064.74-.192 1.068a2.2 2.2 0 0 1-.6.84c-.272.232-.62.416-1.044.552-.416.128-.912.192-1.488.192Z"
        fill="#2D3344"
      />
    </svg>
  );
};
export default SvgNotVerifyFactor3X;
