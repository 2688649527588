import { useCallback, useEffect, useState } from 'react';

import debounce from 'lodash.debounce';

import { useTheme } from 'hooks/theme';

import { ResponsiveMediaType } from '../enum';

export const useCurrentMediaType = () => {
  const [mediaType, setMediaType] = useState<ResponsiveMediaType | undefined>(
    undefined
  );

  const { tabletSize, mobileSize } = useTheme();

  const handleResize = useCallback(() => {
    const width = window.innerWidth;

    if (width < parseInt(mobileSize)) {
      setMediaType(ResponsiveMediaType.MOBILE);
    } else if (width >= parseInt(mobileSize) && width < parseInt(tabletSize)) {
      setMediaType(ResponsiveMediaType.TABLET);
    } else {
      setMediaType(ResponsiveMediaType.DESKTOP);
    }
  }, [mobileSize, tabletSize]);

  useEffect(() => {
    handleResize();

    const debouncedResize = debounce(handleResize, 100);
    window.addEventListener('resize', debouncedResize);

    return () => window.removeEventListener('resize', debouncedResize);
  }, [handleResize]);

  return mediaType;
};
