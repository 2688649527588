import * as React from 'react';
import { SVGProps } from 'react';

const SvgTimeIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 2.5a7.5 7.5 0 1 0 7.5 7.5A7.508 7.508 0 0 0 10 2.5Zm0 13.75A6.25 6.25 0 1 1 16.25 10 6.257 6.257 0 0 1 10 16.25ZM4.818 2.317l-2.5 2.5a.625.625 0 1 1-.885-.884l2.5-2.5a.625.625 0 0 1 .885.884Zm13.75 2.5a.624.624 0 0 1-.885 0l-2.5-2.5a.625.625 0 1 1 .884-.884l2.5 2.5a.626.626 0 0 1 0 .884Zm-4.193 4.558a.625.625 0 1 1 0 1.25H10A.625.625 0 0 1 9.375 10V5.625a.625.625 0 0 1 1.25 0v3.75h3.75Z"
        fill="#64748B"
      />
    </svg>
  );
};
export default SvgTimeIcon;
