import * as React from 'react';
import { SVGProps } from 'react';

const SvgBankIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={36}
      height={36}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 30h30v3H3v-3Zm3-12h3v10.5H6V18Zm7.5 0h3v10.5h-3V18Zm6 0h3v10.5h-3V18Zm7.5 0h3v10.5h-3V18ZM3 10.5 18 3l15 7.5v6H3v-6ZM18 12a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
        fill="#CBD5E1"
      />
    </svg>
  );
};
export default SvgBankIcon;
