import * as React from 'react';
import { SVGProps } from 'react';

const SvgNetworkBnbIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z"
        fill="#F3BA2F"
      />
      <path
        d="M9.087 10.803 12 7.89l2.915 2.915L16.61 9.11 12 4.5 7.392 9.108l1.695 1.695ZM4.5 12l1.695-1.695L7.89 12l-1.695 1.695L4.5 12Zm4.587 1.197L12 16.11l2.915-2.915 1.695 1.695L12 19.5l-4.608-4.608-.002-.002 1.697-1.693ZM16.11 12l1.695-1.695L19.5 12l-1.695 1.695L16.11 12Zm-2.391-.002h.002V12L12 13.72l-1.718-1.717-.003-.003.003-.002.3-.302.147-.146L12 10.28 13.72 12l-.001-.002Z"
        fill="#fff"
      />
    </svg>
  );
};
export default SvgNetworkBnbIcon;
