import * as React from 'react';
import { SVGProps } from 'react';

const SvgCryptoImage = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={453}
      height={256}
      viewBox="0 0 453 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <rect y={32} width={453} height={224} rx={20} />
        <path d="m128 191 190.526 142.5H-62.526L128 191Z" fill="#F1F5F9" />
        <path d="m333 207 190.526 142.5H142.474L333 207Z" fill="#E2E8F0" />
        <path d="m227 181 190.526 142.5H36.474L227 181Z" fill="#CBD5E1" />
        <path d="m227 181 190.526 142.5H36.474L227 181Z" fill="url(#b)" />
      </g>
      <path
        d="M232.375 32v135.827h-10.917c-28.631 0-51.844-30.407-51.844-67.913 0-37.507 23.213-67.914 51.844-67.914h10.917Z"
        fill="#F97316"
      />
      <path
        d="M173.311 123.561a79.362 79.362 0 0 0 1.554 4.851h57.979v-4.851h-59.533ZM178.451 136.904a70.883 70.883 0 0 0 2.647 4.851h51.746v-4.851h-54.393ZM170.67 110.222c.19 1.639.44 3.253.719 4.851h61.455v-4.851H170.67ZM187.113 150.243a57.954 57.954 0 0 0 4.604 4.851h41.128v-4.851h-45.732ZM232.844 165.402v-4.851h-34.243a44.823 44.823 0 0 0 9.61 4.851h24.633ZM171.602 83.543a83.801 83.801 0 0 0-.767 4.848h62.009v-4.848h-61.242ZM170.134 96.883a95.057 95.057 0 0 0-.057 3.031c0 .609.022 1.211.035 1.82h62.732v-4.851h-62.71ZM212.19 33.211a43.276 43.276 0 0 0-11.687 4.852h32.341V33.21H212.19ZM193.027 43.523a55.872 55.872 0 0 0-4.845 4.851h44.662v-4.851h-39.817ZM181.828 56.862a70.032 70.032 0 0 0-2.772 4.851h53.788v-4.851h-51.016ZM175.296 70.201a79.156 79.156 0 0 0-1.617 4.851h59.165v-4.85h-57.548Z"
        fill="#FDBA74"
      />
      <path
        d="M232.676 167.827c28.633 0 51.845-30.406 51.845-67.913 0-37.508-23.212-67.914-51.845-67.914s-51.844 30.406-51.844 67.914c0 37.507 23.211 67.913 51.844 67.913Z"
        fill="#F97316"
      />
      <path
        d="M232.676 156.306c22.271 0 40.326-25.248 40.326-56.392 0-31.144-18.055-56.391-40.326-56.391s-40.325 25.247-40.325 56.391 18.054 56.392 40.325 56.392Z"
        fill="#FDBA74"
      />
      <path
        d="M253.575 110.705c.187-6.041-2.577-10.041-6.764-11.77 3.107-1.831 5.259-5.284 5.401-10.062.181-5.937-2.994-10.251-9.213-11.855l.229-7.331-6.77-.216-.222 6.815-3.845-.12.222-6.816-6.764-.215-.221 6.815-6.165-.19-4.295-.133-.216 6.77 4.295.133-1 32.933-4.744-.152-.215 6.77 4.744.146 6.165.19-.24 7.925 6.764.215.247-7.924 3.844.121-.246 7.924 6.77.216.247-8.186c7.67-.827 11.775-5.122 11.992-12.003ZM227.841 82.81l9.163.281c5.083.156 7.9 2.37 7.771 6.685-.129 4.314-3.077 6.278-8.17 6.147l-10.427-.326.39-12.806 1.273.02Zm9.612 33.243-11.079-.344-.782-.022.414-13.406 11.874.368c5.533.168 8.469 2.319 8.325 6.959-.145 4.639-3.193 6.616-8.752 6.445Z"
        fill="#F97316"
      />
      <defs>
        <linearGradient
          id="b"
          x1={227}
          y1={181}
          x2={227}
          y2={258}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity={0.57} />
          <stop offset={1} stopColor="#CBD5E1" />
        </linearGradient>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h453v256H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default SvgCryptoImage;
