import { useEffect, useState } from 'react';

import { loadNamespaces } from 'helpers/i18n';

const DEFAULT_TRANSLATION_NAMESPACES = ['common'];
const DEFAULT_TRANSLATION_LOADING_TIMEOUT = 15e3;

function useTranslationPreLoading() {
  const [loadingState, setLoadingState] = useState({
    isLoading: true,
    isInError: false,
  });

  useEffect(() => {
    let unmounted = false;

    (async () => {
      try {
        await new Promise((resolve, reject) => {
          loadNamespaces(DEFAULT_TRANSLATION_NAMESPACES, resolve);

          setTimeout(reject, DEFAULT_TRANSLATION_LOADING_TIMEOUT);
        });

        if (unmounted) return;

        setLoadingState({ isLoading: false, isInError: false });
      } catch (e) {
        if (unmounted) return;

        setLoadingState({ isLoading: false, isInError: true });
      }
    })();

    return () => void (unmounted = true);
  }, []);

  return loadingState;
}

export default useTranslationPreLoading;
